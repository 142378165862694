import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { WebStorage } from './web.storage';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
let responseData: any;

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  // Inject Router so we can hand off the user to the Login Page
  constructor(
      private router: Router,
      private storage: WebStorage,
      private httpClient: HttpClient,
      private toaster: ToastrService
      ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

        const tokenTemp = this.storage.get('webAuthToken');
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json',
            Authorization: tokenTemp ? 'bearer ' + tokenTemp : 'bearer my-auth-token'
          })
        };
        console.log("state path",state)
        return this.httpClient.get(environment.apiUrl + '/api/checkAuth', httpOptions).pipe(
            map(res => {
              responseData = res;
              if (responseData.status === 203 || responseData.status === 201) {
                // log.debug('Not authenticated, redirecting and adding redirect url...');
                // console.log('Auth Error!');
                console.log(state.url)
                this.toaster.warning('Please login!');
                this.storage.clear('webAuthToken');
                this.storage.clear('webUserPermissions');
                this.storage.clear('webUserData');
                this.storage.localStore('redirectUrl', state.url);
                this.router.navigate(['/auth/login']);
              } else if (responseData.status === 200) {
                this.storage.localStore('webAuthToken', responseData.data.token);
                this.storage.localStore('webUserPermissions', responseData.data.permissions);
                this.storage.localStore('webUserData', responseData.data.data);
                // console.log(responseData.data);
                // Token from the LogIn is avaiable, so the user can pass to the route
                // console.log(state.url);
                return true;
              }
            }),
            catchError(err => {
              return of(false);
            })
          );
  }
}
