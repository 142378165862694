import { Injectable } from '@angular/core';
import { HttpClientGlobal } from '../core/httpclient';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment'; // Import the environment variable

@Injectable({
  providedIn: 'root'
})
export class MastersService {

  constructor(private httpClient: HttpClientGlobal, private http: HttpClient) { }
  getMasterData(data: any): Observable<Response>{
    return this.httpClient.post("/getMasterList", data);
  }
  postFormData(data:any){
    return this.httpClient.post("/profileCreationAndUpdation", data);
  }
  getTicketCount(data:any){
    return this.httpClient.post("/api/ticket/getTicketCountAndData", data);
  }
  getStaffList(data:any){
    return this.httpClient.post("/api/ticket/getStaffList",data);
  }
  downloadSpreadsheet(fromDate: string, toDate: string ): Observable<any> {
    return this.http.get(`${environment.apiUrl}/api/ticket/downloadSpreadSheet/${fromDate}/${toDate}`, { responseType: 'blob' });
  }

  public changeRoleMasterStatus(modelName: any, is_active: any, id: any) {
    return this.httpClient.post("/api/isActiveStatusChange/" + id, {
      modelName: modelName,
      is_active: is_active,
    });
  }
  getMethodAPI(url: any): Observable<Response> {
    return this.httpClient.get(url, {});
  }
}

