import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { LoginService } from 'src/app/services/login.service';
import { finalize } from 'rxjs/operators';
import { WebStorage } from 'src/app/core/web.storage';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reset-pass',
  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.scss'],
  providers: [LoginService, WebStorage, ToastrService]
})
export class ResetPassComponent implements OnInit {
  // Decorator wires up blockUI instance
  @BlockUI() blockUI: NgBlockUI;

  public loginForm: FormGroup;
  public registerForm: FormGroup;
  public forgotForm: FormGroup;
  public data: any;
  public id: any;
  public passwordLinkValid = false;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private router: Router,
    private storage: WebStorage,
    private route: ActivatedRoute,
    private toastr: ToastrService
    ) {
    this.createLoginForm();
    this.createForgotForm();
    // this.createRegisterForm();
  }

  owlcarousel = [
    {
      title: 'Welcome to Mangalam Dashboard',
      desc: '',
    }
  ];

  owlcarouselOptions = {
    loop: true,
    items: 1,
    dots: true
  };

  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      username: [''],
      password: [''],
    });
  }
  createForgotForm() {
    this.forgotForm = this.formBuilder.group({
      password: [''],
      conf_password: [''],
      key: ['']
    });
  }


  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.blockUI.start('Validating Link...'); // Start blocking
    const self = this;
    const data = {
      key: this.id
    };
    self.loginService
      .checkpassresetkey(data)
      .pipe(
        finalize(() => {
          setTimeout(() => {
            this.blockUI.stop(); // Stop blocking
          }, 200);
        })
      )
      .subscribe(httpresponse => {
        if (httpresponse.status === 200) {
          this.toastr.success('Please Update your password!');
          // alert('Please Update your password!');
          this.passwordLinkValid = true;
        } else {
          this.toastr.warning('Password Link Invalid!');
          // alert('Password Link Invalid!');
          this.router.navigate(['/auth/login']);
        }
      });
  }

  public resetPass() {
    if (this.isValidated()) {
      if (this.forgotForm.value.password === this.forgotForm.value.conf_password) {
        this.forgotForm.controls['key'].patchValue(this.id);
        this.blockUI.start('Updating New Password...'); // Start blocking
        const self = this;
        self.loginService
          .updatePassword(this.forgotForm.value)
          .pipe(
            finalize(() => {
              setTimeout(() => {
                this.blockUI.stop(); // Stop blocking
              }, 200);
            })
          )
          .subscribe(httpresponse => {
            if (httpresponse.status === 200) {
              this.toastr.success(httpresponse.message);
              alert(httpresponse.message);
              this.router.navigate(['/auth/login']);
            } else {
              this.toastr.warning(httpresponse.message);
              alert(httpresponse.message);
            }
          });
      } else {
        this.toastr.warning('Password Dosen\'t Match');
        alert('Password Dosen\'t Match');
      }
    } else {
      this.toastr.warning('Password Dosen\'t Match');
      alert('Password Link Invalid!');
      this.router.navigate(['/auth/login']);
    }
  }

  public isValidated() {
    if (this.passwordLinkValid) {
      return true;
    } else {
      return false;
    }
  }

}
