import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TicketlistComponent } from './physical/ticket-list/ticketlist.component';
import { ViewTicketComponent } from './physical/view-ticket/view-ticket.component';
import { StaffWiseTicketComponent } from './physical/staff-wise-ticket/staff-wise-ticket.component';
const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list',
        component: TicketlistComponent,
        data: {
          title: 'Ticket List',
          breadcrumb: 'Ticket List'
        }
      },
      // {
      //   path: 'add',
      //   component: AddEventComponent,
      //   data: {
      //     title: 'Add Event',
      //     breadcrumb: 'Add Event'
      //   }
      // },
      {
        path: 'view/:id',
        component: ViewTicketComponent,
        data: {
          title: 'View Ticket',
          breadcrumb: 'View Ticket'
        }
      },
      {
        path: 'view/:id/:type',
        component: ViewTicketComponent,
        data: {
          title: 'View Ticket',
          breadcrumb: 'View Ticket'
        }
      },
      // {
      //   path: 'staffWise/:id',
      //   component: StaffWiseTicketComponent,
      //   data: {
      //     title: 'Staff Wise Ticket Listing',
      //     breadcrumb: 'Staff Wise Ticket Listing'
      //   }
      // }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TicketsRoutingModule { }
