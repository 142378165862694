import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddLocationsComponent } from './physical/add-location/add-locations.component';
import { LocationsListComponent } from './physical/location-list/locations.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list',
        component: LocationsListComponent,
        data: {
          title: 'Locations List',
          breadcrumb: 'Locations List'
        }
      },
      {
        path: 'add',
        component: AddLocationsComponent,
        data: {
          title: 'Add Locations',
          breadcrumb: 'Add Locations'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LocationsRoutingModule { }
