import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {NgbDate, NgbCalendar} from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { MastersService } from 'src/app/services/masters.service';


@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {
  hoveredDate: NgbDate;
  alldate:String;
  fromDate: any;
  toDate: any;
  maxDate: NgbDate;
  isSubmitting: boolean = false;

   list: NgbDate[] = [];
   reportForm: FormGroup;  
   constructor(private formBuilder: FormBuilder,calendar: NgbCalendar, private masterservice: MastersService,     private toastr: ToastrService
   ) {
    this.fromDate = calendar.getToday();
    this.toDate = calendar.getToday();
    this.maxDate = calendar.getToday();  // Set maxDate to today

    this.reportForm = this.formBuilder.group({
      daterange: [null, Validators.required],
    });
  }
  ngOnInit() {
    
  }
  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (this.fromDate && !this.toDate && date.after(this.fromDate)) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }
  }

  isHovered(date: NgbDate) {
    return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
  }

  isInside(date: NgbDate) {
    return date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
  }

  onSubmit(form: FormGroup): void {
    if (this.fromDate && this.toDate) {
      const fromDateString = `${this.fromDate.year}-${this.fromDate.month}-${this.fromDate.day}`;
      const toDateString = `${this.toDate.year}-${this.toDate.month}-${this.toDate.day}`;

      console.log({ fromDate: fromDateString, toDate: toDateString });
      this.isSubmitting = true;  // Disable the submit button

      this.masterservice.downloadSpreadsheet(fromDateString, toDateString).subscribe({
        next: (blob: Blob) => {
          const filename = `ticket_report_${fromDateString}_to_${toDateString}.xlsx`;
          this.handleFileDownload(blob, filename);
          this.isSubmitting = false;  // Re-enable the submit button
          this.toastr.success("Report Downloaded");
          this.resetForm(form);  // Reset the form
        },
        error: (error) => {
          this.toastr.error('An error occurred while downloading the file');
          console.error('Download error:', error);
          this.isSubmitting = false;  // Re-enable the submit button

        }
      });
    } else {
      if(this.fromDate == null || this.toDate == null){
        console.error('Both fromDate and toDate must be selected');
        this.toastr.error('Both fromDate and toDate must be selected');
      }else{
        this.toastr.error('Future dates are not allowed.');
      }
    }
  }
  private handleFileDownload(blob: Blob, filename: string) {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
  resetForm(form: FormGroup): void {
    form.reset();  // Reset the form group
    this.fromDate = null;
    this.toDate = null;
  }
  
}
