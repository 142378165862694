import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subject } from "rxjs";
import { MastersService } from 'src/app/services/masters.service';

@Component({
  selector: 'app-staff-wise-ticket',
  templateUrl: './staff-wise-ticket.component.html',
  styleUrls: ['./staff-wise-ticket.component.scss']
})
export class StaffWiseTicketComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  @BlockUI() blockUI: NgBlockUI;

  id:any;
  type:any;
  userType:any;
  userID:any;
  data:any;
  datatableDataPresent:boolean = true;
  pageTitle:any='';  
  priorityList:any;

  constructor(private route: ActivatedRoute,
    private router: Router,
    private masterservice: MastersService,
  ) { }

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get("id");
    if(this.id == 1){
      this.pageTitle = 'All Tickets'
    }else if(this.id == 2){
      this.pageTitle = 'Pending/WIP Tickets'
    }else if(this.id == 3){
      this.pageTitle = 'Completed/ Closed/ Rejected Tickets'
    }else if(this.id == 4){
      this.pageTitle = 'Hold Tickets'
    }else if(this.id == 5){
      this.pageTitle = 'Reopen Tickets'
    }
    this.dtOptions = {
      paging: true,
      retrieve: true,
      searching: true,
      responsive: true,
      pageLength: 5,
      lengthMenu: [5, 10, 25, 50, 75, 100],
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search in table",
      },
      order: [[0, "desc"]],
      columnDefs: [
        { targets: [6, 7], orderable: false } // 5th and 6th column (0-based index)
      ]
    };
    var webUserData = localStorage.getItem("webUserData");
    var userData = JSON.parse(webUserData);
    console.log(userData);
    this.userType = userData.value.admin_type;
    this.userID = userData.value.id;

    console.log("User Type:", this.userType);
    this.getDataForType(this.id);
    // this.getTicketData();
    this.getPriorityList();

  }
  getDataForType(idString:any) {
    // debugger;
    const id = parseInt(idString, 10);
    console.log(id);
    switch (id) {
        case 1:
            this.type = ['Pending', 'WIP', 'Closed', 'Completed', 'Hold', 'Reopen', 'Reject'];
            this.getTicketData(this.type);
            break;
        case 2:
            this.type = ['Pending', 'WIP'];
            this.getTicketData(this.type);
            break;
        case 3:
            this.type = ['Closed', 'Completed', 'Reject'];
            this.getTicketData(this.type);
            break;
        case 4:
            this.type = ['Hold'];
            this.getTicketData(this.type);
            break;
        case 5:
            this.type = ['Reopen'];
            this.getTicketData(this.type);
            break;
        default:
            console.log('Invalid id');
            break;
    }
}


  getTicketData(type:any){
    // debugger;
    const postObj={
      "is_admin": (this.userType==null)?2:this.userType,
      "status_array":type,
    };
    if (this.userType === 2) {
      // Assuming staff_id is stored in a variable named staffId
      postObj["staff_id"] = this.userID; // Add staff_id to postObj
    }
    console.log(postObj);
    this.masterservice.getTicketCount(postObj).subscribe((response:any)=>{
      if(!response){
        console.log("No Response")
      }else{
        // this.pendingCount  = response;
        // console.log("response", this.pendingCount);
        this.data =  response.ticketData;
        console.log(this.data);
        this.dtTrigger.next();
      }
    })
  }
  getPriorityList(){
    const postObj={
      "module": "ticket_priorities",
      "condition": {
          "is_active": 1
      }
    }
    this.masterservice.getMasterData(postObj).subscribe((response:any)=>{
      if(response.code == 0 || response.status == 500){
        console.log("Error", response.message);
      }
      else{
        this.priorityList=response.data;
      }
    })
  }
  getPriorityName(priorityId: number | null): string {
    if (priorityId === null) {
      return '-';
    }
    if (!this.priorityList || this.priorityList.length === 0) {
      return 'Loading...'; // Placeholder text while the priority list is being fetched
    }
    const priority = this.priorityList.find(p => p.id === priorityId);
    return priority ? priority.name : '-';
  }

}
