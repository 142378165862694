import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageComponent } from './components/image.component';
import { BlockUIModule } from 'ng-block-ui';
// Image Cropping Lib
import { NgxUploaderModule } from 'ngx-uploader';
// Image Slider Lib
import { NgImageSliderModule } from 'ng-image-slider';

// Import your library
import { OwlModule } from 'ngx-owl-carousel';
// import { NgxDropzoneModule } from 'ngx-dropzone';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgxUploaderModule,
        BlockUIModule,
        NgImageSliderModule,
        OwlModule
    ],
    declarations: [
        ImageComponent
    ],
    exports: [
        ImageComponent
    ],
    providers: []
})
export class ImageUploadModule { }
