import { Injectable } from '@angular/core';
import { HttpClientGlobal } from '../core/httpclient';
import { Observable } from 'rxjs';
import { HttpResponse } from '../core/http.model';

@Injectable()
export class LocationCategoriesService {
  constructor(private httpClient: HttpClientGlobal) {}

  saveLocCat(data: any): Observable<HttpResponse> {
    return this.httpClient.post('/api/location_categories/add', data);
  }

  getLocCatList(): Observable<HttpResponse> {
    return this.httpClient.get('/api/location_categories/list', {});
  }

  updateLocCatStatus(data: any): Observable<HttpResponse> {
    return this.httpClient.post('/api/location_categories/updatestatus', data);
  }

  editLocationCat(data: any): Observable<HttpResponse> {
    return this.httpClient.post('/api/location_categories/edit', data);
  }

  deleteLocationCat(data: any): Observable<HttpResponse> {
    return this.httpClient.post('/api/location_categories/delete', data);
  }

  getAppLocCatList(): Observable<HttpResponse> {
    return this.httpClient.get('/api/location_categories/getAppList', {});
  }

}