import { Component, OnInit } from "@angular/core";
import * as chartData from "../../shared/data/chart";
import { doughnutData, pieData } from "../../shared/data/chart";
import { finalize } from "rxjs/operators";
import { DashboardService } from "src/app/services/dashboard.service";
import { ChartOptions } from "ng-chartist";
import { Label } from "ng2-charts";
import { ChartType, ChartDataSets } from "chart.js";
import { WebStorage } from "src/app/core/web.storage";
import { MastersService } from "src/app/services/masters.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
  providers: [DashboardService],
})
export class DashboardComponent implements OnInit {
  public doughnutData = doughnutData;
  public pieData = pieData;
  public eventCount = 0;
  public broadcastCount = 0;
  public userCount = 0;
  public upcomingEvents = 0;
  public appUsersPendingCount = 0;
  public pendingComments = 0;
  public totalMngtUsers = 0;
  public pendingEvents = 0;
  public pendingFiles = 0;
  public greetMessage = "Good Morning";
  public userData: any;
  public showGreetingBanner: false;
  userType: any;
  userID: any;
  admin_type: any;
  totalCount: any;
  pendingCount: any;
  completedCount: any;
  holdCount: any;
  reopenCount: any;
  public barChartOptions: ChartOptions = {
    // No direct support for plugins in ng-chartist ChartOptions
  };
  // public barChartOptions: ChartOptions = {
  //   responsive: true,
  //   // We use these empty structures as placeholders for dynamic theming.
  //   scales: { xAxes: [{}], yAxes: [{}] },
  //   plugins: {
  //     datalabels: {
  //       anchor: 'end',
  //       align: 'end',
  //     }
  //   }
  // };
  public barChartLabels: Label[] = [
    "2006",
    "2007",
    "2008",
    "2009",
    "2010",
    "2011",
    "2012",
  ];
  public barChartType: ChartType = "bar";
  public barChartLegend = true;
  // public barChartPlugins = [pluginDataLabels];

  public barChartData: ChartDataSets[] = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: "Series A" },
    { data: [28, 48, 40, 19, 86, 27, 90], label: "Series B" },
  ];

  data: any;
  constructor(
    private dashboardService: DashboardService,
    private webStor: WebStorage,
    private masterservice: MastersService,
    private router: Router
  ) {
    Object.assign(this, { doughnutData, pieData });
    const currentTime = new Date();
    // getHour() function will retrieve the hour from current time
    if (currentTime.getHours() < 12) {
      this.greetMessage = "Good Morning!!";
    } else if (currentTime.getHours() < 17) {
      this.greetMessage = "Good Afternoon!!";
    } else {
      this.greetMessage = "Good Evening!!";
    }
    this.userData = this.webStor.get("webUserData");
    this.showGreetingBanner = this.webStor.get("pageInitiallyLoaded");
    this.webStor.localStore("pageInitiallyLoaded", false);
    var webUserData = localStorage.getItem("webUserData");
    var userData = JSON.parse(webUserData);
    console.log(userData);
    this.userType = userData.value.admin_type;
    this.userID = userData.value.id;
    this.admin_type = userData.value.admin_type;

    console.log("User Type:", this.userType);
  }

  // doughnut 2
  public view = chartData.view;
  public doughnutChartColorScheme = chartData.doughnutChartcolorScheme;
  public doughnutChartShowLabels = chartData.doughnutChartShowLabels;
  public doughnutChartGradient = chartData.doughnutChartGradient;
  public doughnutChartTooltip = chartData.doughnutChartTooltip;

  public chart5 = chartData.chart5;

  // lineChart
  public lineChartData = chartData.lineChartData;
  public lineChartLabels = chartData.lineChartLabels;
  public lineChartOptions = chartData.lineChartOptions;
  public lineChartColors = chartData.lineChartColors;
  public lineChartLegend = chartData.lineChartLegend;
  public lineChartType = chartData.lineChartType;

  // lineChart
  public smallLineChartData = chartData.smallLineChartData;
  public smallLineChartLabels = chartData.smallLineChartLabels;
  public smallLineChartOptions = chartData.smallLineChartOptions;
  public smallLineChartColors = chartData.smallLineChartColors;
  public smallLineChartLegend = chartData.smallLineChartLegend;
  public smallLineChartType = chartData.smallLineChartType;

  // lineChart
  public smallLine2ChartData = chartData.smallLine2ChartData;
  public smallLine2ChartLabels = chartData.smallLine2ChartLabels;
  public smallLine2ChartOptions = chartData.smallLine2ChartOptions;
  public smallLine2ChartColors = chartData.smallLine2ChartColors;
  public smallLine2ChartLegend = chartData.smallLine2ChartLegend;
  public smallLine2ChartType = chartData.smallLine2ChartType;

  // lineChart
  public smallLine3ChartData = chartData.smallLine3ChartData;
  public smallLine3ChartLabels = chartData.smallLine3ChartLabels;
  public smallLine3ChartOptions = chartData.smallLine3ChartOptions;
  public smallLine3ChartColors = chartData.smallLine3ChartColors;
  public smallLine3ChartLegend = chartData.smallLine3ChartLegend;
  public smallLine3ChartType = chartData.smallLine3ChartType;

  // lineChart
  public smallLine4ChartData = chartData.smallLine4ChartData;
  public smallLine4ChartLabels = chartData.smallLine4ChartLabels;
  public smallLine4ChartOptions = chartData.smallLine4ChartOptions;
  public smallLine4ChartColors = chartData.smallLine4ChartColors;
  public smallLine4ChartLegend = chartData.smallLine4ChartLegend;
  public smallLine4ChartType = chartData.smallLine4ChartType;

  public chart3 = chartData.chart3;

  // events
  public chartClicked(e: any): void {}
  public chartHovered(e: any): void {}

  ngOnInit() {
    //  const month = date.toLocaleString("default", { month: "short" });
    //  console.log("webUserData--", localStorage.getItem("webUserData"));
    //  var webUserData = localStorage.getItem("webUserData");
    //  // console.log("we");

    //  var userData = JSON.parse(webUserData);
    //  this.userid = userData.value.id;
    //  this.admin_type = userData.value.admin_type;

    this.configureChart();
    this.getAll();
    this.getTotalCountForUser();
    this.getPendingCountForUser();
    this.getCompletedCountForUser();
    this.getHoldCountForUser();
    this.getReopenCountForUser();
  }
  configureChart() {
    // Configure chart options with the datalabels plugin manually
    const optionsWithPlugins = this.barChartOptions as any; // Cast to any
    optionsWithPlugins.plugins = {
      // Assume you're using datalabels plugin
      datalabels: {
        anchor: "end",
        align: "end",
      },
    };
    this.barChartOptions = optionsWithPlugins;
  }
  public getAll() {
    // this.blockUI.start('Loading. . .'); // Start blocking
    const self = this;
    self.dashboardService
      .getCounts()
      .pipe(
        finalize(() => {
          // setTimeout(() => {
          //   self.blockUI.stop(); // Stop blocking
          // }, 1000);
        })
      )
      .subscribe((httpresponse) => {
        if (httpresponse.status === 200) {
          this.data = httpresponse.data;
          this.eventCount = this.data.eventCount;
          this.broadcastCount = this.data.broadcastCount;
          this.userCount = this.data.appUsersCount;
          this.upcomingEvents = this.data.upcomingEvents;
          this.appUsersPendingCount = this.data.appUsersPendingCount;
          this.pendingComments = this.data.pendingComments;
          this.totalMngtUsers = this.data.totalMngtUsers;
          this.pendingEvents = this.data.pendingEvents;
          this.pendingFiles = this.data.pendingFiles;
        }
      });
  }
  getTotalCountForUser() {
    const postObj = {
      is_admin: this.userType,
      status_array: [
        "Pending",
        "WIP",
        "Closed",
        "Completed",
        "Hold",
        "Reopen",
        "Reject",
      ],
    };
    if (this.userType === 2) {
      // Assuming staff_id is stored in a variable named staffId
      postObj["staff_id"] = this.userID; // Add staff_id to postObj
    }
    // console.log(postObj);
    this.masterservice.getTicketCount(postObj).subscribe((response: any) => {
      if (!response) {
        console.log("No Response");
      } else {
        this.totalCount = response;
        // console.log("response", this.totalCount);
      }
    });
  }
  getPendingCountForUser() {
    const postObj = {
      is_admin: this.userType,
      status_array: ["Pending", "WIP"],
    };
    if (this.userType === 2) {
      // Assuming staff_id is stored in a variable named staffId
      postObj["staff_id"] = this.userID; // Add staff_id to postObj
    }
    // console.log(postObj);
    this.masterservice.getTicketCount(postObj).subscribe((response: any) => {
      if (!response) {
        console.log("No Response");
      } else {
        this.pendingCount = response;
        // console.log("response", this.pendingCount);
      }
    });
  }
  getCompletedCountForUser() {
    const postObj = {
      is_admin: this.userType,
      status_array: ["Closed", "Completed", "Reject"],
    };
    if (this.userType === 2) {
      // Assuming staff_id is stored in a variable named staffId
      postObj["staff_id"] = this.userID; // Add staff_id to postObj
    }
    // console.log(postObj);
    this.masterservice.getTicketCount(postObj).subscribe((response: any) => {
      if (!response) {
        console.log("No Response");
      } else {
        this.completedCount = response;
        // console.log("response", this.completedCount);
      }
    });
  }
  getHoldCountForUser() {
    const postObj = {
      is_admin: this.userType,
      status_array: ["Hold"],
    };
    if (this.userType === 2) {
      // Assuming staff_id is stored in a variable named staffId
      postObj["staff_id"] = this.userID; // Add staff_id to postObj
    }
    // console.log(postObj);
    this.masterservice.getTicketCount(postObj).subscribe((response: any) => {
      if (!response) {
        console.log("No Response");
      } else {
        this.holdCount = response;
        // console.log("response", this.holdCount);
      }
    });
  }
  getReopenCountForUser() {
    const postObj = {
      is_admin: this.userType,
      status_array: ["Reopen"],
    };
    if (this.userType === 2) {
      // Assuming staff_id is stored in a variable named staffId
      postObj["staff_id"] = this.userID; // Add staff_id to postObj
    }
    // console.log(postObj);
    this.masterservice.getTicketCount(postObj).subscribe((response: any) => {
      if (!response) {
        console.log("No Response");
      } else {
        this.reopenCount = response;
        // console.log("response", this.reopenCount);
      }
    });
  }
  redirect(id: any) {
    this.router.navigate(["dashboard/staffWise/", +id]);
  }
}
