import { Injectable } from '@angular/core';
import { HttpClientGlobal } from '../core/httpclient';
import { Observable } from 'rxjs';
import { HttpResponse } from '../core/http.model';

@Injectable()
export class ImageService {
  constructor(private httpClient: HttpClientGlobal) {}


  getData(user: any, foldername: any): Observable<any> {
    return this.httpClient.post('http://134.209.144.113:3000/api/image/getallimages', { user: user, folder_name: foldername}, true);
  }

  remove(foldername: any, filename: any): Observable<any> {
    return this.httpClient.post('http://134.209.144.113:3000/api/image/remove', { filename: filename, folder_name: foldername}, true);
  }

  getTempImageFolder(user: any, data: any): Observable<any>{
    return this.httpClient.post('http://134.209.144.113:3000/api/image/tempimagedir', {}, true);
  }

  getImagesWithFolder(foldername: any): Observable<any> {
    return this.httpClient.post('/api/get/imageWithFolder', { foldername }, false);
  }

  updateImageStatus(data: any): Observable<HttpResponse> {
    return this.httpClient.post('/api/update/imagestatus', data);
  }

  updateFilesData(data: any): Observable<HttpResponse> {
    return this.httpClient.post('/api/update/filesdata', data);
  }

  deleteImage(data: any): Observable<HttpResponse> {
    return this.httpClient.post('/api/delete/image', data);
  }

  updateImageContent(data: any): Observable<HttpResponse> {
    return this.httpClient.post('/api/update/imagecontent', data);
  }

}
