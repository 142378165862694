import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { WingsMasterComponent } from './physical/wings-master/wings-master.component';
import { ProjectMasterComponent } from './physical/project-master/project-master.component';
import { FloorMasterComponent } from './physical/floor-master/floor-master.component';
import { FlatMasterComponent } from './physical/flat-master/flat-master.component';
import { DesignationMasterComponent } from './physical/designation-master/designation-master.component';
import { TicketTypeMasterComponent } from './physical/ticket-type-master/ticket-type-master.component';
import { TicketCategoryMasterComponent } from './physical/ticket-category-master/ticket-category-master.component';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'project_master',
        component: ProjectMasterComponent,
        data: {
          title: 'Projects List',
          breadcrumb: 'Projects List'
        }
      },
    ]
  },
  {
    path: '',
    children: [
      {
        path: 'wings_master',
        component: WingsMasterComponent,
        data: {
          title: 'Wings List',
          breadcrumb: 'Wings List'
        }
      },
    ]
  },
  {
    path: '',
    children: [
      {
        path: 'floor_master',
        component: FloorMasterComponent,
        data: {
          title: 'Floors List',
          breadcrumb: 'Floors List'
        }
      },
    ]
  },
  {
    path: '',
    children: [
      {
        path: 'flat_master',
        component: FlatMasterComponent,
        data: {
          title: 'Flats List',
          breadcrumb: 'Flats List'
        }
      },
    ]
  },
  {
    path: '',
    children: [
      {
        path: 'designation_master',
        component: DesignationMasterComponent,
        data: {
          title: 'Designations List',
          breadcrumb: 'Designations List'
        }
      },
    ]
  },
  {
    path: '',
    children: [
      {
        path: 'ticket_type_master',
        component: TicketTypeMasterComponent,
        data: {
          title: 'Ticket Type List',
          breadcrumb: 'Ticket Type List'
        }
      },
    ]
  },
  {
    path: '',
    children: [
      {
        path: 'ticket_category_master',
        component: TicketCategoryMasterComponent,
        data: {
          title: 'Ticket Category List',
          breadcrumb: 'Ticket Category List'
        }
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MastersRoutingModule { }
