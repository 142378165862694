import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard.component';
import { StaffWiseTicketComponent } from '../tickets/physical/staff-wise-ticket/staff-wise-ticket.component';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'home',
        component: DashboardComponent,
        data: {
          title: "Dashboard",
          breadcrumb: "Dashboard"
        }
      },
      {
        path: 'staffWise/:id',
        component: StaffWiseTicketComponent,
        // data: {
        //   title: 'Staff Wise Ticket Listing',
        //   breadcrumb: 'Staff Wise Ticket Listing'
        // }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
