import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MastersRoutingModule } from './masters-routing.module';
import { BlockUIModule } from 'ng-block-ui';
import { DataTablesModule } from 'angular-datatables';
import { WingsMasterComponent } from './physical/wings-master/wings-master.component';
import { ProjectMasterComponent } from './physical/project-master/project-master.component';
import { FloorMasterComponent } from './physical/floor-master/floor-master.component';
import { FlatMasterComponent } from './physical/flat-master/flat-master.component';
import { DesignationMasterComponent } from './physical/designation-master/designation-master.component';
import { TicketTypeMasterComponent } from './physical/ticket-type-master/ticket-type-master.component';
import { TicketCategoryMasterComponent } from './physical/ticket-category-master/ticket-category-master.component';


@NgModule({
  declarations: [WingsMasterComponent, ProjectMasterComponent, FloorMasterComponent, FlatMasterComponent, DesignationMasterComponent, TicketTypeMasterComponent, TicketCategoryMasterComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MastersRoutingModule,
    BlockUIModule,
    DataTablesModule
  ]
})
export class MastersModule { }
