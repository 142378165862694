import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgBlockUI, BlockUI } from 'ng-block-ui';
import { LoginService } from 'src/app/services/login.service';
import { finalize } from 'rxjs/operators';
import { WebStorage } from 'src/app/core/web.storage';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [LoginService, WebStorage, ToastrService]
})
export class LoginComponent implements OnInit {
  // Decorator wires up blockUI instance
  @BlockUI() blockUI: NgBlockUI;

  public loginForm: FormGroup;
  public registerForm: FormGroup;
  public forgotForm: FormGroup;
  public data: any;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private router: Router,
    private storage: WebStorage,
    private toastr: ToastrService
    ) {
    this.createLoginForm();
    this.createForgotForm();
    // this.createRegisterForm();
  }

  owlcarousel = [
    {
      title: 'Welcome',
      desc: 'Please login to use dashboard',
    }
  ];

  owlcarouselOptions = {
    loop: true,
    items: 1,
    dots: true
  };

  createLoginForm() {
    this.loginForm = this.formBuilder.group({
      username: [''],
      password: [''],
    });
  }
  createForgotForm() {
    this.forgotForm = this.formBuilder.group({
      email: ['']
    });
  }


  ngOnInit() {
  }

  public onSubmit() {
    // console.log(this.loginForm.value);
    this.blockUI.start('Loging In...'); // Start blocking
    const self = this;
    self.loginService
      .login(self.loginForm.value)
      .pipe(
        finalize(() => {
          // Final Execuation
        })
      )
      .subscribe(httpresponse => {
        if (httpresponse.status === 200) {
          self.toastr.success(httpresponse.message);
          self.data = httpresponse.data;
          // Code for storing Session Variables
          //
          self.storage.localStore('webAuthToken', self.data.token);
          self.storage.localStore('webUserPermissions', self.data.permissions);
          self.storage.localStore('webUserData', self.data.data);
          self.storage.localStore('pageInitiallyLoaded', true);
          // Check for account verification
          self.router.navigate(['/dashboard/home']);
          } else {
            self.toastr.warning(httpresponse.message);
            // alert('Auth Failed');
          }
        setTimeout(() => {
            self.blockUI.stop(); // Stop blocking
          }, 200);
      });
  }

  public resetPass() {
    this.blockUI.start('Resetting Password...'); // Start blocking
    const self = this;
    self.loginService
      .resetPass(self.forgotForm.value)
      .pipe(
        finalize(() => {
          // Final Execuation
        })
      )
      .subscribe(httpresponse => {
        if (httpresponse.status === 200) {
            self.toastr.success(httpresponse.message);
          } else {
            this.toastr.warning(httpresponse.message);
          }
        setTimeout(() => {
            self.blockUI.stop(); // Stop blocking
          }, 200);
      });
  }

}
