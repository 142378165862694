import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MastersService } from 'src/app/services/masters.service';
// import { LoginService } from 'src/app/services/login.service';
// import { SectorService } from 'src/app/services/sector.service';

@Component({
  selector: 'app-designation-master',
  templateUrl: './designation-master.component.html',
  styleUrls: ['./designation-master.component.scss']
})
export class DesignationMasterComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;

  onKeyPress(event: KeyboardEvent) {
    // Get the key code of the pressed key
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    const keyCode = event.keyCode;

    // If the input is empty and the key pressed is a space, prevent it
    if (inputValue === '' && keyCode === 32) {
      event.preventDefault();
    }

    // Check if the key is a special symbol and prevent it
    if ((keyCode >= 33 && keyCode <= 47) || // ! to /
        (keyCode >= 58 && keyCode <= 64) || // : to @
        (keyCode >= 91 && keyCode <= 96) || // [ to `
        (keyCode >= 123 && keyCode <= 126)) { // { to ~
      event.preventDefault();
    }
  }
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  designationForm: any;
  // categories: any;
  constructor(
    // private notificationsService: NotificationsService,
    private modalService: NgbModal,
    // private loginService: LoginService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private masterservice: MastersService,

    // private sectorService: SectorService
    ) {
      // this.categories = categoryDB.category;
      this.designationForm = this.formBuilder.group({
        id:'',
        designation_name : ['', [Validators.required]],
    }
  );
  }
  public closeResult: string;
  public sectorData: any;
  matchUserDetails: any;
  userData: any;
  data: any;
  singleEvent: any;
  deleteId: any;
  dataPresent = false;
  datatableDataPresent = true;
  designationList:any;
  isUpdate:boolean= false;
  isSubmitting: boolean = false;
  userType:any;
  userID:any;
  ngOnInit() {
    this.dtOptions = {
      paging: true,
      retrieve: true,
      searching: true,
      responsive: true,
      pageLength: 5,
      lengthMenu: [5, 10, 25, 50, 75, 100],
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search in table'
      },
      order: [[
        0, 'asc'
      ]]
    };
    this.getDesignationList();
    var webUserData = localStorage.getItem("webUserData");
    var userData = JSON.parse(webUserData);
    console.log(userData);
    this.userType = userData.value.admin_type;
    this.userID = userData.value.id;

    console.log("User Type:", this.userType);
  }
  getDesignationList(){
    this.blockUI.start('Retrieving Designation List...'); // Start blocking
    const postObj={
      "module": "designation_masters"
    }
    this.masterservice.getMasterData(postObj)
      .pipe(finalize(() => { this.blockUI.stop(); }))
      .subscribe((response:any) => {
        if (response) {
          this.data = response.data;
          // console.log(this.data);
          // this.toastr.success('Designation Data Loaded.');
          if (this.dtElement && this.dtElement.dtInstance) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.dtTrigger.next();
            });
          } else {
            this.dtTrigger.next();
          }
        } else {
          console.log("No response");
        }
      })
  }
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
    private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.resetForm();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.resetForm();
      return 'by clicking on a backdrop';
    } else {
      this.resetForm();
      return `with: ${reason}`;
    }
  }
  submitForm(){
    this.isSubmitting = true; // Add this line

    if(this.designationForm.valid){
       const postObj={
        "modelName": "designation_masters",
        "inputData": {
            "designation_name": this.designationForm.value.designation_name,
            "is_active": 1
        }
    }
    console.log(postObj);
    this.masterservice.postFormData(postObj).subscribe((response:any)=>{
      if(response.code == 0 || response.status == 500){
        // console.log("No Response", response.message.errors[0].message);
        this.toastr.error(response.message);
        this.isSubmitting = false; // Add this line

      }else{
        console.log("Designation Created");
        this.toastr.success("Designation Created Successfully");
        this.modalService.dismissAll();
        this.getDesignationList();
        this.resetForm();
        this.isSubmitting = false; // Add this line
      }
    })
    }else{
      this.designationForm.markAllAsTouched();
      this.isSubmitting = false; // Add this line

    }
  }
  updateForm(){
    this.isSubmitting = true;
    if(this.designationForm.valid){
      const postObj={
        "modelName": "designation_masters",
        "id": this.designationForm.value.id,   
        "inputData": {
            "designation_name": this.designationForm.value.designation_name,
            "isActive": 1,
        }
      }
      // console.log(postObj);
      // return
      this.masterservice.postFormData(postObj).subscribe((response:any)=>{
        if(response.code == 0 || response.status == 500){
          console.log("No Response", response);
          this.toastr.error(response.message);
          this.isSubmitting = false;
        }
        else{
          console.log("Designation Updated Succesfully");
          this.toastr.success("Designation Update Successfully");
          this.modalService.dismissAll();
          this.getDesignationList();
          this.resetForm();
          this.isUpdate = false; 
          this.isSubmitting = false;
        }
      }) 
    }else{
      this.designationForm.markAllAsTouched();
      this.isSubmitting = false;
    }
    
  }
  
  resetForm(){
    this.designationForm.reset();
    this.modalService.dismissAll();
    this.isUpdate= false;
  }

  viewUserDetails(data:any){
    // console.log(data);
    this.isUpdate = true;
    this.designationForm.patchValue({
      id: data.id,
      designation_name: data.designation_name,
      });
  } 
}
