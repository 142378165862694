import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddEventComponent } from './physical/add-event/add-event.component';
import { EventListComponent } from './physical/event-list/eventlist.component';
import { EditEventComponent } from './physical/edit-event/edit-event.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list',
        component: EventListComponent,
        data: {
          title: 'Event List',
          breadcrumb: 'Event List'
        }
      },
      {
        path: 'add',
        component: AddEventComponent,
        data: {
          title: 'Add Event',
          breadcrumb: 'Add Event'
        }
      },
      {
        path: 'edit/:id',
        component: EditEventComponent,
        data: {
          title: 'Edit Event',
          breadcrumb: 'Edit Event'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EventsRoutingModule { }
