import { Component, OnInit } from "@angular/core";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { finalize } from "rxjs/operators";
// Import BlockUI decorator & optional NgBlockUI type
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { TicketService } from "src/app/services/ticket.service";
import { Subject } from "rxjs";
import "rxjs/add/operator/map";
import { ToastrService } from "ngx-toastr";
import { MastersService } from "src/app/services/masters.service";
@Component({
  selector: "app-ticketlist",
  templateUrl: "./ticketlist.component.html",
  styleUrls: ["./ticketlist.component.scss"],
  providers: [TicketService],
})
export class TicketlistComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  constructor(
    private modalService: NgbModal,
    private eventService: TicketService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private masterservice: MastersService,

  ) {
    // this.categories = categoryDB.category;
    this.categoryForm = this.formBuilder.group({
      category_name: ["", [Validators.required]],
    });
  }
  // @BlockUI() blockUI: NgBlockUI;
  public closeResult: string;
  public categories = [];
  categoryForm: FormGroup;
  data: any;
  datatableDataPresent = false;
  singleEvent: any;
  dataPresent = false;
  broadcastIndex: any;
  showModal = false;
  userType: any;
  userid: any;
  priorityList:any;

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnInit() {
    this.dtOptions = {
      paging: true,
      retrieve: true,
      searching: true,
      responsive: true,
      pageLength: 5,
      lengthMenu: [5, 10, 25, 50, 75, 100],
      language: {
        search: "_INPUT_",
        searchPlaceholder: "Search in table",
      },
      order: [[0, "desc"]],
      columnDefs: [
        { targets: [6, 7], orderable: false } // 5th and 6th column (0-based index)
      ]
    };
    var webUserData = localStorage.getItem("webUserData");
    var userData = JSON.parse(webUserData);
    this.userid = userData.value.id;
    console.log("User ID:", this.userid);
    console.log(userData.value.admin_type);
    this.userType = userData.value.admin_type;
    if(this.userType == 1){
      this.getAll();
    }else{
      const type = ['Pending', 'WIP', 'Closed', 'Completed', 'Hold', 'Reopen', 'Reject'];
      this.getTicketData(type);
    }
    this.getPriorityList();
  }
  public getAll() {
    this.blockUI.start("Loading. . ."); // Start blocking
    const self = this;
    self.eventService
      .getEventList()
      .pipe(
        finalize(() => {
          setTimeout(() => {
            self.blockUI.stop(); // Stop blocking
          }, 1000);
        })
      )
      .subscribe((httpresponse) => {
        this.data = httpresponse.data;
        // Calling the DT trigger to manually render the table
        this.datatableDataPresent = true;
        setTimeout(() => {
          this.dtTrigger.next();
        }, 1000);
      });
  }

  public view(id: any, index: any) {
    this.singleEvent = this.data[index];
    this.broadcastIndex = index;
    this.showModal = true;
  }

  public updateStatus(eventid: any, index: any) {
    this.blockUI.start("Updating Status. . ."); // Start blocking
    const eventData = {
      id: eventid,
      status: this.data[index].is_approved === 1 ? 0 : 1,
    };
    const self = this;
    self.eventService
      .updateEventStatus(eventData)
      .pipe(
        finalize(() => {
          setTimeout(() => {
            self.blockUI.stop(); // Stop blocking
          }, 1000);
        })
      )
      .subscribe((httpresponse) => {
        if (httpresponse.status === 200) {
          if (httpresponse.status === 200) {
            this.data[index].is_approved = httpresponse.data;
            this.dtTrigger.next();
            this.toastr.success("Event Status Updated Successfully!");
          } else {
            this.toastr.warning("Something Went Wrong");
          }
          // this.getAll();
        } else {
          console.log("Error");
        }
      });
  }

  public validateForm() {
    return true;
  }

  public jsonparse(data: any) {
    return JSON.parse(data);
  }

  getstatus(id: any) {
    // return "New";
    switch (id) {
      case 1:
        return "New";
      case 2:
        return "In Progress";
      case 3:
        return "Closed";
      case 4:
        return "Resolved";
      case 78:
        return "In Progress";
      case 1005:
        return "In Progress";
      case 1006:
        return "Pending with Customer";
      case 1007:
        return "In Progress";
      case 1008:
        return "Reopen";
      case 1009:
        return "Rejected";

      case 1010:
        return "In Progress";

      case 1011:
        return "Pending for Painting";

      default:
        return "-";
    }
  }
  getTicketData(type:any){
    // debugger;
    const postObj={
      "is_admin": (this.userType==null)?2:this.userType,
      "status_array":type,
    };
    if (this.userType === 2) {
      // Assuming staff_id is stored in a variable named staffId
      postObj["staff_id"] = this.userid; // Add staff_id to postObj
    }
    console.log(postObj);
    this.masterservice.getTicketCount(postObj).subscribe((response:any)=>{
      if(!response){
        console.log("No Response")
      }else{
        // this.pendingCount  = response;
        // console.log("response", this.pendingCount);
        this.datatableDataPresent = true;
        this.data =  response.ticketData;
        console.log(this.data);
        setTimeout(() => {
          this.dtTrigger.next();
        }, 1000);
      }
    })
  }
  getPriorityList(){
    const postObj={
      "module": "ticket_priorities",
      "condition": {
          "is_active": 1
      }
    }
    this.masterservice.getMasterData(postObj).subscribe((response:any)=>{
      if(response.code == 0 || response.status == 500){
        console.log("Error", response.message);
      }
      else{
        this.priorityList=response.data;
      }
    })
  }
  getPriorityName(priorityId: number | null): string {
    if (priorityId === null) {
      return '-';
    }
    if (!this.priorityList || this.priorityList.length === 0) {
      return 'Loading...'; // Placeholder text while the priority list is being fetched
    }
    const priority = this.priorityList.find(p => p.id === priorityId);
    return priority ? priority.name : '-';
  }
}
