import { Component, OnInit } from '@angular/core';
import { categoryDB } from '../../../../shared/tables/category';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CategoryService } from '../../../../services/category.service';
import { finalize } from 'rxjs/operators';
// Import BlockUI decorator & optional NgBlockUI type
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventService } from 'src/app/services/event.service';
import { YessFoundationService } from 'src/app/services/yess.service';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-yessevent',
  templateUrl: './eventlist.component.html',
  styleUrls: ['./eventlist.component.scss'],
  providers: [YessFoundationService]
})
export class EventListComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  public imageId = '';
  public imageStatus = '';
  constructor(
    private modalService: NgbModal,
    private yesseventService: YessFoundationService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
    ) {
      // this.categories = categoryDB.category;
      this.categoryForm = this.formBuilder.group({
        category_name : ['', [Validators.required]],
  });
  }
  // @BlockUI() blockUI: NgBlockUI;
  public closeResult: string;
  public categories = [];
  categoryForm: FormGroup;
  data: any;
  singleEvent: any;
  dataPresent = false;
  datatableDataPresent = false;
  broadcastIndex: any;
  showModal = false;

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    this.dtOptions = {
      paging: true,
      retrieve: true,
      searching: true,
      responsive: true,
      pageLength: 5,
      lengthMenu: [5, 10, 25, 50, 75, 100],
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search in table'
      },
      order: [[
        0, 'desc'
      ]]
    };
    this.getAll();
  }

  public getAll() {
    this.blockUI.start('Loading. . .'); // Start blocking
    const self = this;
    self.yesseventService
      .getYessFoundationList()
      .pipe(
        finalize(() => {
          setTimeout(() => {
            self.blockUI.stop(); // Stop blocking
          }, 1000);
        })
      )
      .subscribe(httpresponse => {
        this.data = httpresponse.data;
        this.datatableDataPresent = true;
        setTimeout(() => {
          this.dtTrigger.next();
        }, 1000);
      });
  }

  public view(id: any, index: any) {
    this.singleEvent = this.data[index];
    this.broadcastIndex = index;
    this.showModal = true;
  }

  public updateStatus(eventid: any, index: any) {
    this.blockUI.start('Updating Status. . .'); // Start blocking
    const eventData = {
      id: eventid,
      status: this.data[index].is_approved === 1 ? 0 : 1
    };
    const self = this;
    self.yesseventService
    .updateYessFoundationStatus(eventData)
    .pipe(
      finalize(() => {
        setTimeout(() => {
          self.blockUI.stop(); // Stop blocking
        }, 1000);
      })
    )
    .subscribe(httpresponse => {
      if (httpresponse.status === 200) {
        if (httpresponse.status === 200) {
          this.data[index].is_approved = httpresponse.data;
          this.toastr.success('Yess Activity Status Updated Successfully!');
        } else {
          this.toastr.warning('Something Went Wrong');
        }
        // this.getAll();
      } else {
        console.log('Error');
      }
    });
  }

  // onSubmit(): void {
  //   if (this.validateForm()) {
  //     const self = this;
  //     self.categoryService
  //     .saveCategory(this.categoryForm.value)
  //     .pipe(
  //       finalize(() => {
  //         // Final Execuation
  //       })
  //     )
  //     .subscribe(httpresponse => {
  //       if (httpresponse.status === 200) {
  //         this.modalService.dismissAll('From Component');
  //         this.getAll();
  //       } else {
  //         console.log('Error');
  //       }
  //     });
  //   }
  // }

  public validateForm() {
    return true;
  }

  public jsonparse(data: any) {
    // console.log(data);
    return JSON.parse(data);
  }

  public tempDelete(id: any, status: any) {
    this.imageId = id;
    this.imageStatus = status;
  }

  public deleteYessActivity(eventid: any, index: any) {
    this.blockUI.start('Deleting. . .'); // Start blocking
    const eventData = {
      id: eventid
    };
    const self = this;
    self.yesseventService
    .deleteYessEvent(eventData)
    .pipe(
      finalize(() => {
        setTimeout(() => {
          self.blockUI.stop(); // Stop blocking
        }, 1000);
      })
    )
    .subscribe(httpresponse => {
      if (httpresponse.status === 200) {
        if (httpresponse.status === 200) {
          this.imageId = '';
          this.imageStatus = '';
          this.modalService.dismissAll();
          this.getAll();
        } else {
          alert('Something Went Wrong!');
        }
        // this.getAll();
      } else {
        console.log('Error');
      }
    });
  }

}
