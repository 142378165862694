import { MastersService } from 'src/app/services/masters.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { categoryDB } from '../../../../shared/tables/category';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CategoryService } from '../../../../services/category.service';
import { finalize } from 'rxjs/operators';
// Import BlockUI decorator & optional NgBlockUI type
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LoginService } from 'src/app/services/login.service';
import { Subject } from 'rxjs';
import { NotificationsService } from 'src/app/services/notifications.service';
import { ToastrService } from 'ngx-toastr';
import { SectorService } from 'src/app/services/sector.service';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-category',
  templateUrl: './userlist.component.html',
  styleUrls: ['./userlist.component.scss'],
  providers: [LoginService, NotificationsService, SectorService]
})
export class UserListComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  flatDetails:any;
  constructor(
    private notificationsService: NotificationsService,
    private modalService: NgbModal,
    private loginService: LoginService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService,
    private sectorService: SectorService,
    private masterservice: MastersService,

    ) {
      // this.categories = categoryDB.category;
      this.categoryForm = this.formBuilder.group({
        category_name : ['', [Validators.required]],
  });
  }
  // @BlockUI() blockUI: NgBlockUI;
  public closeResult: string;
  public categories = [];
  datatableDataPresent = false;
  userDataPresent = false;
  public sectorData: any;
  public userSector: any;
  matchUserDetails: any;
  userData: any;
  categoryForm: FormGroup;
  data: any;
  singleEvent: any;
  deleteId: any;
  dataPresent = false;
  userid:any;
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  deleteModal(content, id) {
    this.deleteId = id;
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'sm' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    this.dtOptions = {
      paging: true,
      retrieve: true,
      searching: true,
      responsive: true,
      pageLength: 5,
      lengthMenu: [5, 10, 25, 50, 75, 100],
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search in table'
      },
      order: [[
        0, 'asc'
      ]]
    };
    this.getSectorsList();
    var webUserData = localStorage.getItem("webUserData");
    var userData = JSON.parse(webUserData);
    this.userid = userData.value.userid;
    console.log("User ID:", this.userid)
  }

  public getSectorsList() {
    this.blockUI.start('Getting Sectors. . .'); // Start blocking
    const self = this;
    self.sectorService
      .getSectors()
      .pipe(
        finalize(() => {
          setTimeout(() => {
            self.blockUI.stop(); // Stop blocking
          }, 1000);
        })
      )
      .subscribe(httpresponse => {
        this.sectorData = httpresponse.data;
        // if (this.dtElement && this.dtElement.dtInstance) {
        //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        //     dtInstance.destroy();
        //     this.dtTrigger.next();
            this.getAll();
        //   // });
        // } else {
        //   this.dtTrigger.next();
        // }
        this.dtTrigger.next();
        this.getAll();
      });
  }

  public getAll() {
    this.blockUI.start('Getting Users. . .'); // Start blocking
    const self = this;
    
    self.loginService
      .getAppUsers()
      .pipe(
        finalize(() => {
            self.blockUI.stop(); // Stop blocking
        })
      )
      .subscribe((httpresponse:any) => {
        if (httpresponse) {
          this.data = httpresponse.data;
          this.datatableDataPresent = true;
          // setTimeout(() => {
            if (this.dtElement && this.dtElement.dtInstance) {
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.destroy();
                this.dtTrigger.next();
              });
            } else {
              this.dtTrigger.next();
            }
            this.dtTrigger.next();
          // }, 1000);
        }else{
          console.log("No response");
        }
      });
  }

  public view(id: any, index: any) {
    this.singleEvent = this.data[index];
  }

  public updateStatus(userid: any, index: any) {
    this.blockUI.start('Updating Status. . .'); // Start blocking
    const userData = {
      id: userid,
      status: this.data[index].is_active === 1 ? 0 : 1,
      new: this.data[index].is_new,
      mobile: this.data[index].mobile,
      email: this.data[index].email
    };
    const self = this;
    self.loginService
    .updateAppUserStatus(userData)
    .pipe(
      finalize(() => {
        setTimeout(() => {
          self.blockUI.stop(); // Stop blocking
        }, 1000);
      })
    )
    .subscribe(httpresponse => {
        if (httpresponse.status === 200) {
          this.data[index].is_active = httpresponse.data;
          this.data[index].is_approved = httpresponse.data;
          this.data[index].is_new = 0;
          this.toastr.success(httpresponse.message);
          if (this.dtElement && this.dtElement.dtInstance) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.data[index].is_active = httpresponse.data;
              this.data[index].is_approved = httpresponse.data;
              this.dtTrigger.next();
              this.getAll();
            });
          } else {
            this.dtTrigger.next();
          }
          this.getAll();
          this.dtTrigger.next();
        } else {
          this.toastr.warning('Something Went Wrong!');
        }
    });
  }

  public viewUserDetails(id: any, i_index: any) {
    this.blockUI.start('Getting User Details. . .'); // Start blocking
    this.userData = '';
    this.userDataPresent = false;
    this.matchUserDetails = '';
    this.matchUserDetails = this.data[i_index];
    this.userSector = '';
    // for (let i = 0; i < this.sectorData.length; i++) {
    //   const element = this.sectorData[i];
    //   if (element.SectorID == this.matchUserDetails.sectorid || element.SectorID === this.matchUserDetails.sectorid) {
    //     this.userSector = element.DESCRIPTION;
    //     break;
    //   }
    // }
    const self = this;
    self.notificationsService
      .fetchUserDetails(id)
      .pipe(
        finalize(() => {
          setTimeout(() => {
            self.blockUI.stop(); // Stop blocking
          }, 1000);
        })
      )
      .subscribe(httpresponse => {
        if (httpresponse.status === 200) {
          this.userData = httpresponse.data;
          // console.log(this.userData);
          this.userDataPresent = true;
        }
      });
  }

  // onSubmit(): void {
  //   if (this.validateForm()) {
  //     const self = this;
  //     self.categoryService
  //     .saveCategory(this.categoryForm.value)
  //     .pipe(
  //       finalize(() => {
  //         // Final Execuation
  //       })
  //     )
  //     .subscribe(httpresponse => {
  //       if (httpresponse.status === 200) {
  //         this.modalService.dismissAll('From Component');
  //         this.getAll();
  //       } else {
  //         console.log('Error');
  //       }
  //     });
  //   }
  // }

  public validateForm() {
    return true;
  }

  public jsonparse(data: any) {
    return JSON.parse(data);
  }

  public deleteUser() {
    console.log(this.deleteId)
    this.blockUI.start('Deleting User. . .'); // Start blocking
    const self = this;
    self.loginService
      .deleteAppUser(this.deleteId)
      .pipe(
        finalize(() => {
          setTimeout(() => {
            self.blockUI.stop(); // Stop blocking
          }, 1000);
        })
      )
      .subscribe(httpresponse => {
        if (httpresponse.status === 200) {
          this.toastr.success('User Deleted Successfully')
          this.modalService.dismissAll();
          if (this.dtElement && this.dtElement.dtInstance) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.dtTrigger.next();
            });
          } else {
            this.dtTrigger.next();
          }
          this.getAll();
          this.dtTrigger.next();
        } else {
          this.toastr.warning('Please try again')
          this.modalService.dismissAll();
        }
      });
  }

  getUserFlatList(id:any){
    const postObj=    {
      "module": "app_users_details",
      "condition": {
        "app_user_id": id
      },
      "relation": [
         {
             "module": "project_masters"
         },
                     {
             "module": "wings_masters"
         },
                     {
             "module": "floor_masters"
         },
                     {
             "module": "flat_masters"
         }
     ]
   }
   this.masterservice.getMasterData(postObj).subscribe((response:any)=>{
    if(!response){
      console.log("No Response");
    }else{
      // console.log(response.data);
      this.flatDetails = response.data;
    }
   })
  }

  approvedMethod(id: any, user_id: any) {
    // Ask for confirmation
    const confirmed = window.confirm("Are you sure you want to approve this Flat?");
    
    // If user confirms, proceed
    if (confirmed) {
        const postObj = {
            "modelName": "app_users_details",
            "id": id,
            "inputData": {
                "is_active": 1,
                "is_approved": 1,
                "approved_by": this.userid
            },
            "relation": [{
                "subModelName": "app_users",
                "subModelInputData": [{
                    "id": user_id,
                    "is_approved": 1
                }]
            }]
        };

        console.log(postObj);

        this.masterservice.postFormData(postObj).subscribe((response: any) => {
            if (response.code == 0 || response.status == 500) {
                console.log("Error");
                this.toastr.error(response.message);
            } else {
                // console.log("Success", response);
                this.toastr.success("Approved");
                this.getUserFlatList(user_id);
            }
        });
    } else {
        // If user cancels, do nothing
        this.toastr.info("Operation Cancel")
    }
}


  enableMethod(id:any, user_id:any){
    const confirmed = window.confirm("Are you sure you want to Enable this Flat?");
    if(confirmed){
      const postObj={
        "modelName": "app_users_details",
        "id": id, //id
        "inputData": {
            "is_active": 1
        },
        "relation":[
          {
              "subModelName": "app_users",
              "subModelInputData": [
                  {
                      "id": user_id, //app_user_id
                      "is_active": 1
                  }
              ]
          }
        ] 
    }
    console.log(postObj);
    this.masterservice.postFormData(postObj).subscribe((response:any)=>{
      if(response.code == 0 || response.status == 500){
        console.log("Error");
        this.toastr.error(response.message);
      }else{
        // console.log("Success",response);
        this.toastr.success("Flat Enabled.");
        this.getUserFlatList(user_id);
      }
    })
    }else{
      this.toastr.info("Operation Cancel")

    }
    
  }
  disableMethod(id:any, user_id:any){
    const confirmed = window.confirm("Are you sure you want to Disable this Flat?");
    if(confirmed){
      const postObj={
        "modelName": "app_users_details",
        "id": id, //id
        "inputData": {
            "is_active": 0
        },
        "relation":[
          {
              "subModelName": "app_users",
              "subModelInputData": [
                  {
                      "id": user_id, //app_user_id
                      "is_active": 1
                  }
              ]
          }
        ] 
    }
    console.log(postObj);
    this.masterservice.postFormData(postObj).subscribe((response:any)=>{
      if(response.code == 0 || response.status == 500){
        console.log("Error");
        this.toastr.error(response.message);
      }else{
        // console.log("Success",response);
        this.toastr.success("Flat Disabled.");
        this.getUserFlatList(user_id);
      }
    })
    }else{
      this.toastr.info("Operation Cancel")
    }
  }
  rejectMethod(id:any, user_id:any){
    const confirmed = window.confirm("Are you sure you want to Reject this Flat?");
    if(confirmed){
      const postObj={
        "modelName": "app_users_details",
        "id": id, //id
        "inputData": {
            "is_active": 0,
            "is_approved": 2
        },
        // "relation":[
        //   {
        //       "subModelName": "app_users",
        //       "subModelInputData": [
        //           {
        //               "id": user_id, //app_user_id
        //               "is_active": 0,
        //               // "is_approved": 2
        //           }
        //       ]
        //   }
        // ] 
    }
    console.log(postObj);
    // return;
    this.masterservice.postFormData(postObj).subscribe((response:any)=>{
      if(response.code == 0 || response.status == 500){
        console.log("Error");
        this.toastr.error(response.message);
      }else{
        console.log("Success",response);
        this.toastr.success("Flat Rejected.");
        this.getUserFlatList(user_id);
      }
    })
    }else{
      this.toastr.info("Operation Cancel")
    }
  }
  approvedUserMethod(id: any, user_id: any) {
    // Ask for confirmation
    const confirmed = window.confirm("Are you sure you want to approve this?");
    
    // If user confirms, proceed
    if (confirmed) {
        const postObj = {
            "modelName": "app_users",
            "id": id,
            "inputData": {
                "is_active": 1,
                "is_approved": 1,
                "approved_by": this.userid
            },
           
        };

        console.log(postObj);
        this.masterservice.postFormData(postObj).subscribe((response: any) => {
            if (response.code == 0 || response.status == 500) {
                console.log("Error");
                this.toastr.error(response.message);
            } else {
                // if (this.dtElement && this.dtElement.dtInstance) {
                //   this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                //     dtInstance.destroy();
                //     this.dtTrigger.next();
                    // console.log("Success", response);
                    this.toastr.success("Approved");
                    this.getAll();
              //     });
              //   } else {
              //     this.dtTrigger.next();
              //     this.getAll();
              //   }
              }
              this.dtTrigger.next();
              this.getAll();
        });
    } else {
        // If user cancels, do nothing
        this.toastr.info("Operation Cancel")
    }
}
}
