import { Injectable } from '@angular/core';
import { HttpClientGlobal } from '../core/httpclient';
import { Observable } from 'rxjs';
import { HttpResponse } from '../core/http.model';

@Injectable()
export class OrderService {
  constructor(private httpClient: HttpClientGlobal) {}

  getOrders(): Observable<HttpResponse> {
    return this.httpClient.get('/api/get/adminorders', {});
  }

}
