import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddBannerImageComponent } from './physical/add-event/add-images.component';
import { BannerImagesListComponent } from './physical/event-list/bannerimages.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list',
        component: BannerImagesListComponent,
        data: {
          title: 'Videos List',
          breadcrumb: 'Videos List'
        }
      },
      {
        path: 'add',
        component: AddBannerImageComponent,
        data: {
          title: 'Add Videos',
          breadcrumb: 'Add Videos'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BannerImagesRoutingModule { }
