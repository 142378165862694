import { Injectable } from "@angular/core";
import { HttpClientGlobal } from "../core/httpclient";
import { Observable } from "rxjs";
import { HttpResponse } from "../core/http.model";
@Injectable()
export class TicketService {
  constructor(private httpClient: HttpClientGlobal) {}
  getEventList(): Observable<HttpResponse> {
    return this.httpClient.post("/api/ticket/getEventsList", {});
  }
  editData(id: any): Observable<HttpResponse> {
    return this.httpClient.post("/api/ticket/viewEvents", { eventId: id });
  }
  updateEvent(data: any): Observable<HttpResponse> {
    return this.httpClient.post("/api/ticket/updateEvents", data);
  }
  updateEventStatus(data: any): Observable<HttpResponse> {
    return this.httpClient.post("/api/event/updatestatus", data);
  }
}
