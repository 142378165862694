import { Injectable, HostListener, Inject } from '@angular/core';
import { BehaviorSubject, Observable, Subscriber } from 'rxjs';
import { WINDOW } from './windows.service';
// Menu
export interface Menu {
	path?: string;
	title?: string;
	module?: string;
	icon?: string;
	type?: string;
	badgeType?: string;
	badgeValue?: string;
	active?: boolean;
	bookmark?: boolean;
	children?: Menu[];
}

@Injectable({
	providedIn: 'root'
})

export class NavService {

	constructor(@Inject(WINDOW) private window) {
		this.onResize();
		if (this.screenWidth < 991) {
			this.collapseSidebar = true;
		}
	}

	public screenWidth: any;
	public collapseSidebar = false;

	MENUITEMS: Menu[] = [
		{
			path: '/dashboard/home', module: 'dashboard', title: 'Dashboard', icon: 'home', type: 'link', badgeType: 'primary', active: false
		},
		
		{
			title: 'Masters', module: 'app_users', icon: 'users', type: 'sub', active: false, children: [

				{ path: '/masters/project_master', module: 'masters', title: 'Project Master', type: 'link' },
				{ path: '/masters/wings_master', module: 'masters', title: 'Wing Master', type: 'link' },
				{ path: '/masters/floor_master', module: 'masters', title: 'Floor Master', type: 'link' },
				{ path: '/masters/flat_master', module: 'masters', title: 'Flat Master', type: 'link' },
				{ path: '/masters/designation_master', module: 'masters', title: 'Designation Master', type: 'link' },
				{ path: '/masters/ticket_type_master', module: 'masters', title: 'Ticket Type Master', type: 'link' },
				// { path: '/masters/ticket_category_master', module: 'masters', title: 'Ticket Category Master', type: 'link' }
			],
		},
		{
			title: 'Staff', module: 'user_management', icon: 'user', type: 'sub', active: false, children: [

				{ path: '/user/list', module: 'user_management', title: 'Staff List', type: 'link' },
				// { path: '/user/add', module: 'user_management', title: 'Add Staff', type: 'link' },
				{ path: '/user/staff_listing', module: 'user_management', title: 'Staff Listing', type: 'link' },

			],
		},
		{
			title: 'App Users', module: 'app_users', icon: 'users', type: 'sub', active: false, children: [

				{ path: '/appusers/list', module: 'app_users', title: 'Users List', type: 'link' }

			],
		},
		{
			title: 'Banner Images', module: 'banner_images', icon: 'image', type: 'sub', active: false, children: [

				{ path: '/bannerimages/list', module: 'banner_images', title: 'Image List', type: 'link' },
				// { path: '/bannerimages/add', module: 'banner_images', title: 'Add Image', type: 'link' },

			],
		},
		{
			title: 'Broadcast', module: 'broadcast', icon: 'speaker', type: 'sub', active: false, children: [

				{ path: '/broadcast/list', module: 'broadcast', title: 'broadcast List', type: 'link' },
				// { path: '/broadcast/add', module: 'broadcast', title: 'Add broadcast', type: 'link' },

			],
		},
		{
			title: 'Comments', module: 'comments', icon: 'message-circle', type: 'sub', active: false, children: [

				{ path: '/comments/list', module: 'comments', title: 'Comments List', type: 'link' }

			],
		},
		{
			title: 'Events', module: 'events', icon: 'award', type: 'sub', active: false, children: [

				{ path: '/events/list', module: 'events', title: 'Events List', type: 'link' },
				// { path: '/events/add', module: 'events', title: 'Add Event', type: 'link' },

			],
		},
		// {
		// 	title: 'Mangalam Fern', module: 'fern', icon: 'award', type: 'sub', active: false, children: [

		// 		{ path: '/fern/bookings-list', module: 'fern', title: 'Bookings', type: 'link' },
		// 		{ path: '/fern/membership-list', module: 'fern', title: 'Membership', type: 'link' },
		// 		{ path: '/fern/orders-list', module: 'fern', title: 'Orders', type: 'link' },
		// 		{ path: '/fern/products-list', module: 'fern', title: 'Products', type: 'link' },
		// 		// { path: '/events/add', module: 'events', title: 'Add Event', type: 'link' },

		// 	],
		// },
		{
			title: 'Enquiries', module: 'events', icon: 'award', type: 'sub', active: false, children: [

				{ path: '/enquiries/sales-enquiry-list', module: 'events', title: 'Sales Enquiries', type: 'link' },
				{ path: '/enquiries/rental-enquiry-list', module: 'events', title: 'Rental Enquiries', type: 'link' },
				// { path: '/events/add', module: 'events', title: 'Add Event', type: 'link' },

			],
		},
		{
			title: 'Images & Videos', module: 'videos&images', icon: 'image', type: 'sub', active: false, children: [
				{ path: '/videos/list', module: 'videos&images', title: 'Videos List', type: 'link' },
				// { path: '/videos/add', module: 'videos&images', title: 'Add Video', type: 'link' },
				{ path: '/images/list', module: 'videos&images', title: 'Images List', type: 'link' },
				// { path: '/images/add', module: 'videos&images', title: 'Add Image', type: 'link' },

			],
		},
		{
			title: 'Locations', module: 'locations', icon: 'map-pin', type: 'sub', active: false, children: [

				{ path: '/locations/list', module: 'locations', title: 'Locations List', type: 'link' },
				// { path: '/locations/add', module: 'locations', title: 'Add Location', type: 'link' },

			],
		},
		// {
		// 	title: 'Videos', icon: 'dollar-sign', type: 'sub', active: false, children: [
		// 		{ path: '/sales/orders', title: 'Videos', type: 'link' },
		// 		{ path: '/sales/transactions', title: 'Add Video', type: 'link' },
		// 	]
		// },
		{
			title: 'Smart Pay', module: 'smartpay', icon: 'credit-card', type: 'sub', active: false, children: [

				{ path: '/smartpay/list', module: 'smartpay', title: 'SmartPay List', type: 'link' },
				// { path: '/user/add', module: 'user_management', title: 'Add Staff', type: 'link' },

			],
		},
		
		// {
		// 	title: 'Yess Foundation', module: 'yess_foundation', icon: 'bookmark', type: 'sub', active: false, children: [

		// 		{ path: '/yessfoundation/list', module: 'yess_foundation', title: 'Activity List', type: 'link' },
		// 		// { path: '/yessfoundation/add', module: 'yess_foundation', title: 'Add Event', type: 'link' },

		// 	],
		// },
		{
			title: 'App Admin Mngt', module: 'adminlogin', icon: 'settings', type: 'sub', active: false, children: [

				{ path: '/adminlogin/list', module: 'adminlogin', title: 'Admin List', type: 'link' },
				// { path: '/user/add', module: 'user_management', title: 'Add Staff', type: 'link' },

			],
		},
		{
			title: 'Notifications Pushed', module: 'notifications', icon: 'bell', type: 'sub', active: false, children: [

				{ path: '/notifications/list', module: 'notifications', title: 'Notifications List', type: 'link' },
				// { path: '/user/add', module: 'user_management', title: 'Add Staff', type: 'link' },

			],
		},
		// {
		// 	title: 'Maintenance', module: 'maintenance', icon: 'box', type: 'sub', active: false, children: [

		// 		{ path: '/maintenance/list', module: 'maintenance', title: 'Maintenance List', type: 'link' },
		// 		// { path: '/user/add', module: 'user_management', title: 'Add Staff', type: 'link' },

		// 	],
		// },
		{
			title: 'Configs', module: 'maintenance', icon: 'settings', type: 'sub', active: false, children: [

				{ path: '/config/list', module: 'maintenance', title: 'Config For App', type: 'link' },
				// { path: '/user/add', module: 'user_management', title: 'Add Staff', type: 'link' },

			],
		},
		{
			title: 'Tickets', module: 'tickets', icon: 'award', type: 'sub', active: false, children: [

				{ path: '/tickets/list', module: 'tickets', title: 'Tickets List', type: 'link' },
			],
		},
		{
			title: 'Reports', module: 'app_users', icon: 'award', type: 'sub', active: false, children: [

				{ path: '/d-reports/report_master', module: 'app_users', title: 'Report Master', type: 'link' },
			],
		},
	];
	// Array
	items = new BehaviorSubject<Menu[]>(this.MENUITEMS);

	// Windows width
	@HostListener('window:resize', ['$event'])
	onResize(event?) {
		this.screenWidth = window.innerWidth;
	}


}
