import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddUserComponent } from './components/add-user/add-user.component';
import { UserListComponent } from './components/user-list/userlist.component';
import { StaffListingComponent } from './components/staff-listing/staff-listing.component';
import { StaffRegistrationComponent } from './components/staff-registration/staff-registration.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'list',
        component: UserListComponent,
        data: {
          title: 'Staff List',
          breadcrumb: 'Staff List'
        }
      },
      {
        path: 'add',
        component: AddUserComponent,
        data: {
          title: 'Add Staff',
          breadcrumb: 'Add Staff'
        }
      },
      {
        path: 'staff_listing',
        component: StaffListingComponent,
        data: {
          title: 'Staff Listing',
          breadcrumb: 'Staff Listing'
        }
      },
      {
        path: 'staff_regi',
        component: StaffRegistrationComponent,
        data: {
          title: 'Staff Registration',
          breadcrumb: 'Staff Registration'
        }
      },
      {
        path: 'edit/:id',
        component: StaffRegistrationComponent,
        data: {
          title: 'Edit Staff',
          breadcrumb: 'Edit Staff'
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
