import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { finalize } from 'rxjs/operators';
import { SectorService } from 'src/app/services/sector.service';
import { EventService } from 'src/app/services/event.service';
import { LoginService } from 'src/app/services/login.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.scss'],
  providers: [SectorService, EventService, LoginService]
})
export class AddUserComponent implements OnInit {

  public title: string = 'AGM project';
  public latitude: number;
  public longitude: number;
  public zoom:number;

  @BlockUI() blockUI: NgBlockUI;
  public productForm: FormGroup;
  public counter = 1;
  public modules = [];
  public products = [];
  public categories = [];
  public userlist = [];
  public userSelected = false;
  public modulesPresent = false;
  public sectorsPresent = false;
  public userDataPresent = false;
  private prodData: any;
  private tempDataForLoading: any;
  private selectedTagArray: any;
  retrievedTags: any;
  private tagList: any;
  data: any;
  public url = [
    {
      img: 'assets/images/user.png'
    },
    {
      img: 'assets/images/user.png'
    },
    {
      img: 'assets/images/user.png'
    },
    {
      img: 'assets/images/user.png'
    },
    {
      img: 'assets/images/user.png'
    }
  ];

  public modulesConfig = {
    displayKey: 'module_name',
    search: true,
    height: 'auto',
    placeholder: 'Select Module',
    noResultsFound: 'No results found!',
    searchPlaceholder: 'Enter Module Name',
    searchOnKey: 'module_name'
  };

  public sectorConfig = {
    displayKey: 'DESCRIPTION',
    search: true,
    height: 'auto',
    placeholder: 'Select Sector',
    noResultsFound: 'No results found!',
    searchPlaceholder: 'Enter Sector Name',
    searchOnKey: 'DESCRIPTION'
  };

  public userConfig = {
    displayKey: 'name',
    search: false,
    height: 'auto',
    placeholder: 'Select User',
    noResultsFound: 'No results found!',
    searchPlaceholder: 'Enter User Name',
    searchOnKey: 'name'
  };


  constructor(
    private fb: FormBuilder,
    private eventService: EventService,
    private sectorService: SectorService,
    private userService: LoginService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.productForm = this.fb.group({
      username_search: ['', [Validators.required]],
      user_type: ['', [Validators.required]],
      user_id: ['', [Validators.required]],
      user_data: ['', [Validators.required]],
      username: ['', [Validators.required]],
      sectors: ['', Validators.required],
      email: ['', Validators.required],
      mobile: ['', Validators.required],
      module: ['', Validators.required]
    });
  }

  increment() {
    this.counter += 1;
  }

  decrement() {
    this.counter -= 1;
  }

  // FileUpload
  readUrl(event: any, i) {
    if (event.target.files.length === 0) {
      return;
    }
    // Image upload validation
    const mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = _event => {
      this.url[i].img = reader.result.toString();
    };
  }

  ngOnInit() {
    // this.getAll();
    this.getSectorList();
    this.getModulesList();
  }
   // Get Current Location Coordinates
   private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 15;
      });
    }
  }
  public getSectorList() {
    this.blockUI.start('Loading. . .'); // Start blocking
    const self = this;
    self.sectorService
      .getSectors()
      .pipe(
        finalize(() => {
          setTimeout(() => {
            self.blockUI.stop(); // Stop blocking
          }, 1000);
        })
      )
      .subscribe(httpresponse => {
        if (httpresponse.status === 200) {
          this.categories = [];
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < httpresponse.data.length; i++) {
            this.data = httpresponse.data[i];
            this.categories.push({
              SectorID: this.data.SectorID,
              CODE: this.data.CODE,
              DESCRIPTION: this.data.DESCRIPTION,
              all: JSON.stringify(this.data)
            });
          }
          this.sectorsPresent = true;
        }
      });
  }

  public getModulesList() {
    this.blockUI.start('Loading. . .'); // Start blocking
    const self = this;
    self.sectorService
      .getModules()
      .pipe(
        finalize(() => {
          setTimeout(() => {
            self.blockUI.stop(); // Stop blocking
          }, 1000);
        })
      )
      .subscribe(httpresponse => {
        if (httpresponse.status === 200) {
          this.modules = [];
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < httpresponse.data.length; i++) {
            this.data = httpresponse.data[i];
            this.modules.push({
              module: this.data.id,
              module_name: this.data.module_name
            });
          }
          this.modulesPresent = true;
        }
      });
  }

  onSubmit(): void {
    this.blockUI.start('Saving...'); // Start blocking
    if (this.validateForm()) {
      const self = this;
      self.userService
        .saveLogin(this.productForm.value)
        .pipe(
          finalize(() => {
            setTimeout(() => {
              self.blockUI.stop(); // Stop blocking
            }, 1000);
          })
        )
        .subscribe(httpresponse => {
          if (httpresponse.status === 200) {
            this.router.navigate(['/user/list']);
            this.toastr.success('User Added Successfully!');
          } else {
            this.toastr.warning('Something Went Wrong');
          }
        });
    }
  }

  public searchUsername(event) {
    if (event.target.value.length > 3) {
      this.userDataPresent = false;
      this.userSelected = false;
      const data = {
        loginid: event.target.value
      };
      const self = this;
      self.userService
        .searchUsername(data)
        .pipe(
          finalize(() => {

          })
        )
        .subscribe(httpresponse => {
          if (httpresponse.status === 200) {
            this.userlist = [];
            // tslint:disable-next-line: prefer-for-of
            for (let i = 0; i < httpresponse.data.length; i++) {
              this.data = httpresponse.data[i];
              this.userlist.push({
                name: this.data.LOGINID + '-' + this.data.Email + '-' + this.data.UGRPDESC,
                USERID: this.data.USERID,
                LOGINID: this.data.LOGINID,
                Email: this.data.Email,
                MobileNo: this.data.MobileNo,
                all: JSON.stringify(this.data)
              });
            }
            this.userDataPresent = true;
          } else {
            console.log('Error');
          }
        });
    } else {
      this.userlist = [];
      this.userDataPresent = false;
    }
  }

  public selectModule(event: any) {
    this.productForm.controls.module.patchValue(JSON.stringify(event.value));
  }

  public selectSector(event: any) {
    this.productForm.controls.sectors.patchValue(JSON.stringify(event.value));
  }

  public selectUser(event: any) {
    console.log(event);
    if (event.value === undefined) {
      this.productForm.controls.user_id.patchValue('');
      this.productForm.controls.email.patchValue('');
      this.productForm.controls.username.patchValue('');
      this.productForm.controls.mobile.patchValue('');
      this.productForm.controls.user_data.patchValue('');
      this.userSelected = false;
    } else {
      this.productForm.controls.user_id.patchValue(event.value.USERID);
      this.productForm.controls.username.patchValue(event.value.LOGINID);
      this.productForm.controls.email.patchValue(event.value.Email);
      this.productForm.controls.mobile.patchValue(event.value.MobileNo);
      this.productForm.controls.user_data.patchValue(JSON.stringify(event.value));
      this.userSelected = true;
    }
  }

  public validateForm() {
    return true;
  }

  public imagesReceived(data: any) {
    this.productForm.controls.pictures.patchValue(data);
  }

  public getImageFolderName() {
    return 'bannerimages';
  }

}
