import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MastersService } from 'src/app/services/masters.service';

@Component({
  selector: 'app-staff-listing',
  templateUrl: './staff-listing.component.html',
  styleUrls: ['./staff-listing.component.scss']
})
export class StaffListingComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;

  @BlockUI() blockUI: NgBlockUI;
  tableName: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  data: any;
  isEdit:boolean = false;
  constructor(
    private masterservice: MastersService,
    private toastr: ToastrService
    
  ) {

   }
  dataPresent = false;
  datatableDataPresent = true;
  ngOnInit() {
    this.dtOptions = {
      paging: true,
      retrieve: true,
      searching: true,
      responsive: true,
      pageLength: 5,
      lengthMenu: [5, 10, 25, 50, 75, 100],
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search in table'
      },
      order: [[
        0, 'asc'
      ]]
    };
    this.getStaffList();
  }
  getStaffList(){
    this.blockUI.start('Retrieving Staff List...'); // Start blocking
    const postObj={
        "module": "users",
        "relation": [
            {
                "module": "users_associated_projects"
            },
            {
                "module": "users_attachments"
            },
            {
              "module": "roles"
            }
        ]
    }
    const self = this;
    self.masterservice
      .getMasterData(postObj)
      .pipe(
        finalize(() => {
          setTimeout(() => {
            self.blockUI.stop(); // Stop blocking
          }, 1000);
        })
      ).subscribe((response:any)=>{
        if(!response){
          console.log("no response");
        }else{
          this.data = response.data;
          // this.toastr.success('Wings Data Loaded.');
          if (this.dtElement && this.dtElement.dtInstance) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.dtTrigger.next();
            });
          } else {
            this.dtTrigger.next();
          }
        }
    })
  }
  StatusChanged(isActive: any, id: any) {
    if (isActive == 1) {
      isActive = 0;
    } else {
      isActive = 1;
    }
 
    this.tableName = "users";
    this.masterservice
      .changeRoleMasterStatus(this.tableName, isActive, id)
      .subscribe((response: any) => {
        if (response.code == 1) {
          this.toastr.success("Success ! Status Changed Successfully");
          // location.reload();
          return true;
        } else if (response.code == 0) {
          this.toastr.error(response.message, "Error!");
          return false;
        } else {
          this.toastr.error("Record not created!", "Error!");
          return false;
        }
      });
  }
}


