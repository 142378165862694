import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { WebStorage } from 'src/app/core/web.storage';
import { NavService } from '../../service/nav.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  providers: [WebStorage, ToastrService]
})
export class HeaderComponent implements OnInit {
  public right_sidebar = false;
  public open = false;
  public openNav = false;
  public isOpenMobile: boolean;

  @Output() rightSidebarEvent = new EventEmitter<boolean>();

  constructor(
    public navServices: NavService,
    private toastrService: ToastrService,
    private storage: WebStorage,
    private router: Router
    ) { }

  collapseSidebar() {
    this.open = !this.open;
    this.navServices.collapseSidebar = !this.navServices.collapseSidebar;
  }
  right_side_bar() {
    this.right_sidebar = !this.right_sidebar;
    this.rightSidebarEvent.emit(this.right_sidebar);
  }

  openMobileNav() {
    this.openNav = !this.openNav;
  }


  ngOnInit() {  }

  public logout() {
    this.storage.clear('webAuthToken');
    this.storage.clear('webUserData');
    this.toastrService.success('Logout Successfull!');
    this.router.navigate(['/auth/login']);
  }

  public changePass() {
    this.router.navigate(['/password/change']);
  }

}
