import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { finalize } from 'rxjs/operators';
import { YessFoundationService } from 'src/app/services/yess.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-yessevent',
  templateUrl: './edit-event.component.html',
  styleUrls: ['./edit-event.component.scss'],
  providers: [YessFoundationService]
})
export class EditEventComponent implements OnInit {

  public title = 'AGM project';
  public latitude: number;
  public longitude: number;
  public ckeConfig = { height: 400, language: "en", allowedContent: true, removePlugins: "elementspath", resize_enabled: false };
  public zoom: number;

  @BlockUI() blockUI: NgBlockUI;
  public productForm: FormGroup;
  public towerList = [];
  public showEditor = false;
  public attachmentArr: any;
  public imagesReady = false;
  public individualList = [];
  public id = '';
  private folderNameTimestamp: any;
  public broadcastType = 1;
  public broadcastName = '';
  retrievedTags: any;
  data: any;
  public url = [
    {
      img: 'assets/images/user.png'
    },
    {
      img: 'assets/images/user.png'
    },
    {
      img: 'assets/images/user.png'
    },
    {
      img: 'assets/images/user.png'
    },
    {
      img: 'assets/images/user.png'
    }
  ];


  constructor(
    private fb: FormBuilder,
    private yessService: YessFoundationService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.attachmentArr = [];
    this.productForm = this.fb.group({
      id: ['', [Validators.required]],
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      attachment: ['', Validators.required],
      location: ['', Validators.required],
      date: ['', Validators.required]
    });

    const date = new Date();  // 2009-11-10
    const month = date.toLocaleString('default', { month: 'short' });
    const dateNo = date.getDate();
    const RandNo = Math.floor(Date.now() / 1000);
    this.folderNameTimestamp = month + '-' + dateNo + '-' + RandNo;
  }

  ngOnInit() {
    // this.getAll();
    // this.getSectorList();
    this.setCurrentLocation();  this.id = this.route.snapshot.paramMap.get('id')
    this.blockUI.start('Loading. . .'); // Start blocking
    const self = this;
    self.yessService
      .editData(this.id)
      .pipe(
        finalize(() => {
          setTimeout(() => {
            self.blockUI.stop(); // Stop blocking
          }, 1000);
        })
      )
      .subscribe(httpresponse => {
        if (httpresponse.status === 200) {
          this.data = httpresponse.data;
          // console.log(this.data);
          this.broadcastType = this.data.broadcast_type;
          this.broadcastName = this.checkBroadType(this.broadcastType);
          this.productForm.controls['id'].patchValue(this.id);
          this.productForm.controls['title'].patchValue(this.data.title);
          this.productForm.controls['description'].patchValue(this.data.description);
          this.productForm.controls['attachment'].patchValue(this.data.attachment);
          this.productForm.controls['location'].patchValue(this.data.location);
          const location = JSON.parse(this.data.location);
          this.latitude = location.latitude;
          this.longitude = location.longitude;
          this.productForm.controls['date'].patchValue(this.data.date);
          const tempArr = JSON.parse(this.data.attachment);
          tempArr.forEach(element => {
            this.attachmentArr.push(element);
          });
          this.imagesReady = true;
          this.showEditor = true;
          // this.attachmentArr = JSON.parse(this.data.attachment);
        }
      });
  }
   // Get Current Location Coordinates
   private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 15;
      });
    }
  }

  public checkBroadType(id) {
    switch (id) {
      case 1 || '1':
          return 'Mangalam';
          case 2 || '2':
              return 'Sector';
              case 3 || '3':
                  return 'Tower';
                  case 4 || '4':
                      return 'Individual';
      default:
        break;
    }
  }

  markerDragEnd(event: MouseEvent) {
    // console.log(event);
    // this.latitude = event.coords.lat;
    // this.longitude = event.coords.lng;
  }


  onSubmit(): void {
    const locationCo = {
      latitude: this.latitude ? this.latitude : 18.51670171432715,
      longitude: this.longitude ? this.longitude : 73.94193872596429,
    };
    this.productForm.controls.location.patchValue(JSON.stringify(locationCo));
    // console.log(this.productForm.value);
    this.blockUI.start('Saving...'); // Start blocking
    if (this.validateForm()) {
      const self = this;
      self.yessService
        .updateEvent(this.productForm.value)
        .pipe(
          finalize(() => {
            setTimeout(() => {
              self.blockUI.stop(); // Stop blocking
            }, 1000);
          })
        )
        .subscribe(httpresponse => {
          if (httpresponse.status === 200) {
            this.router.navigate(['/yessfoundation/list']);
            this.toastr.success('Yess Activity Edited Successfully!');
          } else {
            this.toastr.warning('Something Went Wrong');
          }
        });
    }
  }

  public validateForm() {
    return true;
  }

  public imagesReceived(data: any) {
    this.productForm.controls.attachment.patchValue(data.imagesData);
  }

  public getFolderName() {
    // console.log(this.folderNameTimestamp);
    return this.folderNameTimestamp;
  }

  public getFileType() {
    return 'Images';
  }

}
