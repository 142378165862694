import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard.component';
import { CountToModule } from 'angular-count-to';
import { ChartsModule } from 'ng2-charts';
import { Ng2GoogleChartsModule } from 'ng2-google-charts';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartistModule } from 'ng-chartist';
import { SharedModule } from '../../shared/shared.module';

//for staffwiseticket

import 'hammerjs';
import 'mousetrap';
import { BlockUIModule } from 'ng-block-ui';

import { DataTablesModule } from 'angular-datatables';
import { StaffWiseTicketComponent } from '../tickets/physical/staff-wise-ticket/staff-wise-ticket.component';
@NgModule({
  declarations: [DashboardComponent,StaffWiseTicketComponent],
  imports: [
    CommonModule,
    DashboardRoutingModule,
    CountToModule,
    SharedModule,
    ChartsModule,
    Ng2GoogleChartsModule,
    NgxChartsModule,
    ChartistModule,
    //staffwise
    BlockUIModule,
    DataTablesModule
    //end
  ]
})
export class DashboardModule { }
