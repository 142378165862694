import { Location, LocationStrategy, PathLocationStrategy, HashLocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BlockUIModule } from 'ng-block-ui';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { LightboxModule } from 'ngx-lightbox';
import { ToastrModule } from 'ngx-toastr';
import { AppRoutingModule } from './app-routing.module';
import { DataTablesModule } from 'angular-datatables';
import { AppComponent } from './app.component';
import { AuthModule } from './components/auth/auth.module';
import { CouponsModule } from './components/coupons/coupons.module';
import { DashboardModule } from './components/dashboard/dashboard.module';
import { InvoiceModule } from './components/invoice/invoice.module';
import { LocalizationModule } from './components/localization/localization.module';
import { MediaModule } from './components/media/media.module';
import { MenusModule } from './components/menus/menus.module';
import { PagesModule } from './components/pages/pages.module';
import { EventsModule } from './components/events/events.module';
import { UserModule } from './components/user/user.module';
import { ReportsModule } from './components/reports/reports.module';
import { SalesModule } from './components/sales/sales.module';
import { SettingModule } from './components/setting/setting.module';
import { UsersModule } from './components/users/users.module';
import { VendorsModule } from './components/vendors/vendors.module';
import { AuthGuard } from './core/auth.guard';
import { HttpClientGlobal } from './core/httpclient';
import { WebStorage } from './core/web.storage';
import { ImageUploadModule } from './shared/imageupload/imageupload.module';
import { SharedModule } from './shared/shared.module';
import { AgmCoreModule } from '@agm/core';
import { UiSwitchModule } from 'ngx-toggle-switch';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { MomentModule } from 'angular2-moment';
import { LocationsModule } from './components/locations/locations.module';
import { YessEventsModule } from './components/yessfoundation/yessevents.module';
import { AppUserModule } from './components/app_users/user.module';
import { VideosModule } from './components/videos/videos.module';
import { ImagesModule } from './components/images/images.module';
import { EmbedVideo } from 'ngx-embed-video';
import { TicketsModule } from './components/tickets/tickets.module';
import { MastersModule } from './components/masters/masters.module';
import { DReportsModule } from './components/d-reports/d-reports.module';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserAnimationsModule,
    LightboxModule,
    MomentModule,
    OwlNativeDateTimeModule,
    VideosModule,
    ImagesModule,
    AppUserModule,
    OwlDateTimeModule,
    DataTablesModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    UiSwitchModule,
    YessEventsModule,
    BlockUIModule.forRoot(),
    ToastrModule.forRoot(),
    DashboardModule,
    FormsModule,
    ReactiveFormsModule,
    SelectDropDownModule,
    InvoiceModule,
    SettingModule,
    ReportsModule,
    ImageUploadModule,
    AuthModule,
    SharedModule,
    LocalizationModule,
    EventsModule,
    UserModule,
    SalesModule,
    EmbedVideo.forRoot(),
    VendorsModule,
    LocationsModule,
    CouponsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA3CS7S2HJgMtBSiEzgR1ZKmtvz6T3KuGs',
      libraries: ['places']
    }),
    PagesModule,
    MediaModule,
    MenusModule,
    UsersModule,
    MastersModule,
    TicketsModule,
    DReportsModule,
    NgbDatepickerModule
  ],
  // providers: [HttpClientGlobal, AuthGuard, WebStorage, Location, {provide: LocationStrategy, useClass: PathLocationStrategy}],
  providers: [HttpClientGlobal, AuthGuard, WebStorage, Location, {provide: LocationStrategy, useClass:  HashLocationStrategy}],

  bootstrap: [AppComponent]
})
export class AppModule { }
