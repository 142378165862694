import { Injectable } from '@angular/core';
import { HttpClientGlobal } from '../core/httpclient';
import { Observable } from 'rxjs';
import { HttpResponse } from '../core/http.model';

@Injectable()
export class LocationService {
  constructor(private httpClient: HttpClientGlobal) {}

  getLocations(): Observable<HttpResponse> {
    return this.httpClient.get('/api/location/get', {});
  }

  saveLocation(data: any): Observable<HttpResponse> {
    return this.httpClient.post('/api/location/save', data);
  }

  updateLocationStatus(data: any): Observable<HttpResponse> {
    return this.httpClient.post('/api/location/updatestatus', data);
  }

  deleteLocation(data: any): Observable<HttpResponse> {
    return this.httpClient.post('/api/location/delete', data);
  }


}
