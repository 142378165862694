import { Injectable } from '@angular/core';
import { WebStorage } from './web.storage';
// import { WebStorage } from './web.storage';

@Injectable({
  providedIn: 'root'
})
export class Utills {
  constructor(private storage: WebStorage) {}

  isExists(data: any, key: any) {
    if (this.notEmpty(data)) {
      if (this.notEmpty(data[key])) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  removeEmpty(data: any) {
    let y: any;
    for (const x in data) {
      y = data[x];
      if (
        y === 'null' ||
        y === null ||
        y === '' ||
        typeof y === 'undefined' ||
        (y instanceof Object && Object.keys(y).length == 0)
      ) {
        delete data[x];
      }
      if (y instanceof Object) {
        y = this.removeEmpty(y);
      }
    }
    return data;
  }

  notEmpty(data: any) {
    let res = true;
    const dataType = typeof data;
    switch (dataType) {
      case 'object':
        if (data == null || data.length < 1) {
          res = false;
        }
        break;

      case 'undefined':
        res = false;
        break;

      case 'number':
        if (data == '') {
          res = false;
        }
        break;
      case 'string':
        if (data.trim() == '') {
          res = false;
        }
        break;
    }

    return res;
  }

  parseJson(data: any) {
    return JSON.parse(data);
  }

  checkPermission(key: any) {
    // console.log(key);
    const permissions = JSON.parse(this.storage.get('webUserPermissions'));
    // console.log(permissions);
    if (this.notEmpty(permissions)) {
      if (permissions.length > 0) {
        const found = permissions.find((item: any) => item.module_key === key);
        if (typeof found !== 'undefined') {
          return true;
        } else {
          return false;
        }
      }
    }
  }
}
