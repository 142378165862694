import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MastersService } from 'src/app/services/masters.service';
// import { LoginService } from 'src/app/services/login.service';
// import { SectorService } from 'src/app/services/sector.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';

@Component({
  selector: 'app-project-master',
  templateUrl: './project-master.component.html',
  styleUrls: ['./project-master.component.scss']
})
export class ProjectMasterComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
  onKeyPress(event: KeyboardEvent) {
    // Get the key code of the pressed key
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    const keyCode = event.keyCode;

    // If the input is empty and the key pressed is a space, prevent it
    if (inputValue === '' && keyCode === 32) {
      event.preventDefault();
    }

    // Check if the key is a special symbol and prevent it
    if ((keyCode >= 33 && keyCode <= 47) || // ! to /
        (keyCode >= 58 && keyCode <= 64) || // : to @
        (keyCode >= 91 && keyCode <= 96) || // [ to `
        (keyCode >= 123 && keyCode <= 126)) { // { to ~
      event.preventDefault();
    }
  }
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  wingsForm: any;
  projectForm: any;
  // categories: any;
  constructor(
    // private notificationsService: NotificationsService,
    private modalService: NgbModal,
    // private loginService: LoginService,
    private formBuilder: FormBuilder,
    // private toastr: ToastrService,
    // private sectorService: SectorService
    private masterservice: MastersService,
    private toastr: ToastrService
    ) {
      // this.categories = categoryDB.category;
      this.projectForm = this.formBuilder.group({
        id:'',
        project_name : ['', [Validators.required]],
    }
  );
  }
  public closeResult: string;
  public sectorData: any;
  matchUserDetails: any;
  userData: any;
  data: any;
  singleEvent: any;
  deleteId: any;
  dataPresent = false;
  datatableDataPresent = true;
  isUpdate:boolean=false;
  isSubmitting: boolean = false;

  ngOnInit() {
    this.dtOptions = {
      paging: true,
      retrieve: true,
      searching: true,
      responsive: true,
      pageLength: 5,
      lengthMenu: [5, 10, 25, 50, 75, 100],
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search in table'
      },
      order: [[
        0, 'asc'
      ]],
    };  
    // this.getSectorsList();
    this.getProjectList();
  }
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
    private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.resetForm();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.resetForm();
      return 'by clicking on a backdrop';
    } else {
      this.resetForm();
      return `with: ${reason}`;
    }
  }
  // getProjectList(){
  //   console.log("Get project List");
    
  //   this.blockUI.start('Retrieving Project List...'); // Start blocking
  //   const postObj={
  //     module:"project_masters"
  //   }
  //   const self = this;
  //   self.masterservice
  //     .getMasterData(postObj)
  //     .pipe(
  //       finalize(() => {
  //         setTimeout(() => {
  //           self.blockUI.stop(); // Stop blocking
  //         }, 1000);
  //       })
  //     )  .subscribe((response:any)=>{
  //       if(!response){
  //         console.log("no response");
  //       }else{
  //         this.data= response.data;
  //         self.toastr.success('Project Data Loaded.');
  //         console.log("response", this.data);
  //         setTimeout(() => {
  //           this.dtTrigger.next();

  //         }, 1000);
  //       }
  //   })
    
  // }
  getProjectList() {
    this.blockUI.start('Retrieving Project List...');
    const postObj = { module: "project_masters" };
    this.masterservice.getMasterData(postObj)
      .pipe(finalize(() => { this.blockUI.stop(); }))
      .subscribe((response:any) => {
        if (response) {
          this.data = response.data;
          // this.toastr.success('Project Data Loaded.');
          if (this.dtElement && this.dtElement.dtInstance) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.dtTrigger.next();
            });
          } else {
            this.dtTrigger.next();
          }
        } else {
          console.log("No response");
        }
      });
  }
  
  submitForm(){
    this.isSubmitting = true; // Add this line

    if (this.projectForm.valid) {
      const postObj={
        "modelName": "project_masters",
        "inputData": {
            "projectName": this.projectForm.value.project_name,
            "isActive": 1
        }
    }
    // console.log(postObj);
    // return;
    this.masterservice.postFormData(postObj).subscribe((response:any)=>{
      if(response.code == 0 || response.status == 500){
        // console.log("No Response", response.message.errors[0].message);
        this.toastr.error(response.message);
        this.isSubmitting = false; // Add this line

      }
      else{
        console.log("Record Created Succesfully");
        this.toastr.success("Record Created Succesfully");
        this.modalService.dismissAll();
        this.getProjectList();
        this.dtTrigger.next();
        this.resetForm();
        this.isSubmitting = false; // Add this line

      }
    })
      // console.log("Project Form Values", this.projectForm.value);    
    }else{
      this.projectForm.markAllAsTouched();
      this.isSubmitting = false; // Add this line

    }
  }
  updateForm(){
    this.isSubmitting = true; // Add this line

    if(this.projectForm.valid){
      const postObj={
        "modelName": "project_masters",
        "id": this.projectForm.value.id,   
        "inputData": {
            "projectName": this.projectForm.value.project_name,
            "isActive": 1,
        }
      }
      // console.log(postObj);
      this.masterservice.postFormData(postObj).subscribe((response:any)=>{
        if(response.code == 0 || response.status == 500){
          console.log("No Response", response);
          this.toastr.error(response.message);
          this.isSubmitting = false; // Add this line

        }
        else{
          console.log("Record Updated Succesfully");
          this.toastr.success("Record Update Successfully");
          this.modalService.dismissAll();
          this.getProjectList();
          this.dtTrigger.next();
          this.resetForm();
          this.isUpdate = false; 
          this.isSubmitting = false; // Add this line
        }
      }) 
    }
    else{
      this.projectForm.markAllAsTouched();
      this.isSubmitting = false; // Add this line
    }
  }
  resetForm(){
    this.projectForm.reset();
    this.isUpdate = false;
    this.modalService.dismissAll()
  }
  viewUserDetails(data:any){
    // console.log(data);
    this.isUpdate = true;
    this.projectForm.patchValue({
      id: data.id,
      project_name: data.projectName,
      });
  } 
}
