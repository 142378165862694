import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DReportsRoutingModule } from './d-reports-routing.module';
import { ReportsComponent } from './physical/reports/reports.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';


@NgModule({
  declarations: [ReportsComponent],
  imports: [
    CommonModule,
    DReportsRoutingModule,
    ReactiveFormsModule,
    NgbDatepickerModule
    
  ]
})
export class DReportsModule { }
