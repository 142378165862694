import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import 'hammerjs';
import { LightboxModule } from 'ngx-lightbox';
import 'mousetrap';
import { BlockUIModule } from 'ng-block-ui';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { CKEditorModule } from 'ngx-ckeditor';
import { DropzoneConfigInterface, DropzoneModule, DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { ImageUploadModule } from 'src/app/shared/imageupload/imageupload.module';
import { AddBannerImageComponent } from './physical/add-event/add-images.component';
import { BannerImagesListComponent } from './physical/event-list/bannerimages.component';
// import { NgModule } from '@angular/core';
import { BannerImagesRoutingModule } from './bannerimages-routing.module';
import { MomentModule } from 'angular2-moment';
import { DataTablesModule } from 'angular-datatables';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  maxFilesize: 50,
  url: 'https://httpbin.org/post',
};

@NgModule({
  declarations: [AddBannerImageComponent, BannerImagesListComponent],
  imports: [
    CommonModule,
    SelectDropDownModule,
    LightboxModule,
    BlockUIModule,
    FormsModule,
    ReactiveFormsModule,
    MomentModule,
    CKEditorModule,
    BannerImagesRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA3CS7S2HJgMtBSiEzgR1ZKmtvz6T3KuGs',
      libraries: ['places']
    }),
    Ng2SmartTableModule,
    NgbModule,
    DataTablesModule,
    DropzoneModule,
    GalleryModule.forRoot(),
    ImageUploadModule
  ],
  providers: [
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    },
    NgbActiveModal
  ]
})
export class ImagesModule { }
