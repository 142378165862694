import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { BlockUIModule } from 'ng-block-ui';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { OrderDetailsComponent } from './orderdetails/orderdetails.component';
import { OrdersComponent } from './orders/orders.component';
import { SalesRoutingModule } from './sales-routing.module';
import { TransactionsComponent } from './transactions/transactions.component';



@NgModule({
  declarations: [OrdersComponent, TransactionsComponent, OrderDetailsComponent],
  imports: [
    CommonModule,
    SalesRoutingModule,
    Ng2SmartTableModule,
    NgxDatatableModule,
    BlockUIModule
  ]
})
export class SalesModule { }
