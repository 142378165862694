import { Component, OnInit, ViewChild } from '@angular/core';
import { DatatableComponent } from '@swimlane/ngx-datatable/release';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { finalize } from 'rxjs/operators';
import { OrderService } from 'src/app/services/orders.service';
import { orderDB } from '../../../shared/tables/order-list';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss'],
  providers: [OrderService]
})
export class OrdersComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  public order = [];
  public temp = [];
  public orderslist: any;
  public perOrderslist: any;
  public data: any;
  pipe = new DatePipe('en-IN');

  @ViewChild(DatatableComponent, { static: false }) table: DatatableComponent;
  constructor(
    private ordersService: OrderService
  ) {
    this.order = orderDB.list_order;
  }

  updateFilter(event) {
    const val = event.target.value.toLowerCase();

    // filter our data
    const temp = this.perOrderslist.filter(function(d) {
      return d.id.toLowerCase().indexOf(val) !== -1 || !val;
    });

    if (val === '') {
      this.orderslist = this.perOrderslist;
    } else {
      // update the rows
      this.orderslist = temp;
    }
    // Whenever the filter changes, always go back to the first page
    this.table.offset = 0;
  }

  ngOnInit() {
    this.getAllOrders();
  }

  public getAllOrders() {
    this.blockUI.start('Loading Orders...'); // Start blocking
    const self = this;
    self.ordersService
      .getOrders()
      .pipe(
        finalize(() => {
          // Final Execuation
        })
      )
      .subscribe(httpresponse => {
        this.orderslist = [];
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < httpresponse.data.length; i++) {
          this.data = httpresponse.data[i];
          const tempProdData = JSON.parse(this.data.products);
          let imageArray = '';
          let paidStatus = '';
          let shippingStatus = '';
          // tslint:disable-next-line: prefer-for-of
          for (let j = 0; j < tempProdData.length; j++) {
            const element1 = tempProdData[j];
            imageArray += `<img src='` + element1.product.pictures[0] + `' class='img-30 mr-2'>`;
          }
          if (this.data.is_paid === 1) {
            paidStatus = `<span class='badge badge-success'>Paid</span>`;
          } else {
            paidStatus = `<span class='badge badge-warning'>Unpaid</span>`;
          }
          if (this.data.is_completed === 1) {
            shippingStatus = `<span class='badge badge-primary'>Shipped</span>`;
          } else {
            shippingStatus = `<span class='badge badge-warning'>Processing</span>`;
          }
          const action = `<button  class='btn btn-primary'`;
          this.orderslist.push({
            'id': this.data.temp_id,
            'payment id': this.data.transaction_id,
            'product': imageArray,
            'status': paidStatus,
            'payment': 'Online',
            'order status': shippingStatus,
            'date': this.pipe.transform(this.data.created_at, 'short'),
            'total': this.data.amount + ' Rs'
          });
        }
        this.perOrderslist = this.orderslist;
        setTimeout(() => {
          self.blockUI.stop(); // Stop blocking
        }, 1000);

      });
  }

  public viewOrderDetails(id: any) {
    console.log(id);
  }

}
