import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { finalize } from 'rxjs/operators';
import { YessFoundationService } from 'src/app/services/yess.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-yessevent',
  templateUrl: './add-event.component.html',
  styleUrls: ['./add-event.component.scss'],
  providers: [YessFoundationService]
})
export class AddEventComponent implements OnInit {

  public title = 'AGM project';
  public latitude: number;
  public longitude: number;
  public ckeConfig = { height: 400, language: "en", allowedContent: true, removePlugins: "elementspath", resize_enabled: false };
  public zoom: number;

  @BlockUI() blockUI: NgBlockUI;
  public productForm: FormGroup;
  public towerList = [];
  public individualList = [];
  private folderNameTimestamp: any;
  retrievedTags: any;
  data: any;
  public url = [
    {
      img: 'assets/images/user.png'
    },
    {
      img: 'assets/images/user.png'
    },
    {
      img: 'assets/images/user.png'
    },
    {
      img: 'assets/images/user.png'
    },
    {
      img: 'assets/images/user.png'
    }
  ];


  constructor(
    private fb: FormBuilder,
    private yessService: YessFoundationService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.productForm = this.fb.group({
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      attachment: ['', Validators.required],
      location: ['', Validators.required],
      date: ['', Validators.required]
    });

    const date = new Date();  // 2009-11-10
    const month = date.toLocaleString('default', { month: 'short' });
    const dateNo = date.getDate();
    const RandNo = Math.floor(Date.now() / 1000);
    this.folderNameTimestamp = month + '-' + dateNo + '-' + RandNo;
  }

  ngOnInit() {
    // this.getAll();
    // this.getSectorList();
    this.setCurrentLocation();
  }
   // Get Current Location Coordinates
   private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 15;
      });
    }
  }

  markerDragEnd(event: MouseEvent) {
    // console.log(event);
    // this.latitude = event.coords.lat;
    // this.longitude = event.coords.lng;
  }

  onSubmit(): void {
    const locationCo = {
      latitude: this.latitude ? this.latitude : 18.51670171432715,
      longitude: this.longitude ? this.longitude : 73.94193872596429,
    };
    this.productForm.controls.location.patchValue(JSON.stringify(locationCo));
    // console.log(this.productForm.value);
    if (this.validateForm()) {
      this.blockUI.start('Saving...'); // Start blocking
      const self = this;
      self.yessService
        .saveYessFoundation(this.productForm.value)
        .pipe(
          finalize(() => {
            setTimeout(() => {
              self.blockUI.stop(); // Stop blocking
            }, 1000);
          })
        )
        .subscribe(httpresponse => {
          if (httpresponse.status === 200) {
            this.router.navigate(['/yessfoundation/list']);
            this.toastr.success('Yess Activity Added Successfully!');
          } else {
            this.toastr.warning('Something Went Wrong');
          }
        });
    } else {
      this.toastr.warning('Please fill all the details')
    }
  }

  public validateForm() { 
    if (this.productForm.invalid) {
      return false;
    } else {
      return true;
    }
  }

  public imagesReceived(data: any) {
    this.productForm.controls.attachment.patchValue(data.imagesData);
  }

  public getFolderName() {
    // console.log(this.folderNameTimestamp);
    return this.folderNameTimestamp;
  }

  public getFileType() {
    return 'Images';
  }

}
