import { Component, OnInit } from '@angular/core';
import { categoryDB } from '../../../../shared/tables/category';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CategoryService } from '../../../../services/category.service';
import { finalize } from 'rxjs/operators';
// Import BlockUI decorator & optional NgBlockUI type
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventService } from 'src/app/services/event.service';
import { ImageService } from 'src/app/services/image.service';
import { Lightbox } from 'ngx-lightbox';
import { EmbedVideoService } from 'ngx-embed-video';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-banner-images',
  templateUrl: './bannerimages.component.html',
  styleUrls: ['./bannerimages.component.scss'],
  providers: [ImageService]
})
export class BannerImagesListComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  public productForm: FormGroup;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();

  constructor(
    private fb: FormBuilder,
    private modalService: NgbModal,
    private _lightbox: Lightbox,
    private imageService: ImageService,
    private formBuilder: FormBuilder,
    private embedService: EmbedVideoService,
    public sanitizer: DomSanitizer,
    private toastr: ToastrService
    ) {
        // this.categories = categoryDB.category;
        this.categoryForm = this.formBuilder.group({
          category_name : ['', [Validators.required]],
        });
        this.album = [];
        this.productForm = this.fb.group({
          id: ['', [Validators.required]],
          filename: ['', [Validators.required]],
          path: ['', [Validators.required]],
          content: ['', [Validators.required]],
          contact: ['']
        });
    }
      // @BlockUI() blockUI: NgBlockUI;
  public closeResult: string;
  public categories = [];
  public album = [];
  categoryForm: FormGroup;
  data: any;
  singleEvent: any;
  dataPresent = false;
  datatableDataPresent = false;
  public imageId = '';
  public imageStatus = '';
  public fileUploaded = false;
  public ckeConfig = { height: 400, language: "en", allowedContent: true, removePlugins: "elementspath", resize_enabled: false };
  public fileUrl = '';
  public fileName = '';
  broadcastIndex: any;
  showModal = false;

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl'  }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      // console.log(this.closeResult)
      this.fileUrl = '';
      this.fileUploaded = false;
      this.productForm.reset();
    });
  }

  photoURL(data) {
    console.log(data)
    return this.sanitizer.bypassSecurityTrustUrl(data);
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    this.data = [];
    this.dtOptions = {
      paging: true,
      retrieve: true,
      searching: true,
      responsive: true,
      pageLength: 5,
      lengthMenu: [5, 10, 25, 50, 75, 100],
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search in table'
      },
      order: [[
        0, 'desc'
      ]]
    };
    this.getAll();
  }

  public getAll() {
    this.blockUI.start('Loading. . .'); // Start blocking
    const self = this;
    self.imageService
      .getImagesWithFolder('videos')
      .pipe(
        finalize(() => {
          setTimeout(() => {
            self.blockUI.stop(); // Stop blocking
          }, 1000);
        })
      )
      .subscribe(httpresponse => {
        this.data = httpresponse.data;
        this.datatableDataPresent = true;
        console.log(this.data);
        this.data.forEach(element => {
          this.album.push({
            src: element.file_url,
            caption: element.folder_name,
            thumb: element.file_url
          });
        });
        setTimeout(() => {
          this.dtTrigger.next();
        }, 1000);
      });
  }

  public view(id: any, index: any) {
    this.singleEvent = this.data[index];
    this.broadcastIndex = index;
    this.productForm.controls.content.patchValue(this.singleEvent.content);
    this.productForm.controls.contact.patchValue(this.singleEvent.contact);
    this.showModal = true;
    // console.log(this.singleEvent)
  }
  public updateStatus(eventid: any, index: any) {
    this.blockUI.start('Updating Status. . .'); // Start blocking
    const eventData = {
      id: eventid,
      status: this.data[index].is_approved === 1 ? 0 : 1,
      content: this.productForm.value.content,
      contact: this.productForm.value.contact
    };
    const self = this;
    self.imageService
    .updateImageStatus(eventData)
    .pipe(
      finalize(() => {
        setTimeout(() => {
          self.blockUI.stop(); // Stop blocking
        }, 1000);
      })
    )
    .subscribe(httpresponse => {
      if (httpresponse.status === 200) {
        if (httpresponse.status === 200) {
          this.data[index].is_approved = httpresponse.data;
          this.productForm.controls.content.patchValue('');
          this.modalService.dismissAll();
          this.getAll();
          this.toastr.success('Video Status Updated Successfully!');
        } else {
          this.toastr.warning('Something Went Wrong');
        }
        // this.getAll();
      } else {
        console.log('Error');
      }
    });
  }


  public updateData(eventid: any, index: any) {
    this.blockUI.start('Updating Data. . .'); // Start blocking
    const eventData = {
      id: eventid,
      status: this.data[index].is_approved,
      content: this.productForm.value.content,
      contact: this.productForm.value.contact
    };
    const self = this;
    self.imageService
    .updateFilesData(eventData)
    .pipe(
      finalize(() => {
        setTimeout(() => {
          self.blockUI.stop(); // Stop blocking
        }, 1000);
      })
    )
    .subscribe(httpresponse => {
      if (httpresponse.status === 200) {
        if (httpresponse.status === 200) {
          // this.data[index].is_approved = httpresponse.data;
          // this.productForm.controls.content.patchValue('');
          this.modalService.dismissAll();
          this.getAll();
          this.toastr.success('Video Data Updated Successfully!');
        } else {
          this.toastr.warning('Something Went Wrong');
        }
        // this.getAll();
      } else {
        console.log('Error');
      }
    });
  }

  public deleteImage(eventid: any, index: any) {
    this.blockUI.start('Updating Status. . .'); // Start blocking
    const eventData = {
      id: eventid,
      status: this.data[index].is_deleted === 1 ? 0 : 1
    };
    const self = this;
    self.imageService
    .deleteImage(eventData)
    .pipe(
      finalize(() => {
        setTimeout(() => {
          self.blockUI.stop(); // Stop blocking
        }, 1000);
      })
    )
    .subscribe(httpresponse => {
      if (httpresponse.status === 200) {
        if (httpresponse.status === 200) {
          this.imageId = '';
          this.imageStatus = '';
          this.modalService.dismissAll();
          this.getAll();
          this.toastr.success('Video Deleted Successfully!');
        } else {
          this.toastr.warning('Something Went Wrong');
        }
        // this.getAll();
      } else {
        console.log('Error');
      }
    });
  }

  public open1(index: number): void {
    // open lightbox
    this._lightbox.open(this.album, index);
  }

  public close(): void {
    // close lightbox programmatically
    this._lightbox.close();
  }

  public imagesReceived(data: any) {
    // console.log(data);
    if (data.type == 2 || data.type === 2) {
      this.modalService.dismissAll();
      this.getAll();
    } else {
      if (data !== undefined) {
        if (data.ref !== undefined) {
          this.productForm.controls['id'].patchValue(data.ref.ref.id);
          this.productForm.controls['filename'].patchValue(data.ref.name);
          this.productForm.controls['path'].patchValue(data.ref.path);
          this.fileUploaded = true;
          this.fileUrl = data.ref.path;
          this.fileName = data.ref.name;
        }
      }
    }
    // console.log(this.productForm.value);
  }


  public updateContent() {
    this.blockUI.start('Saving. . .'); // Start blocking
    const self = this;
    self.imageService
    .updateImageContent(this.productForm.value)
    .pipe(
      finalize(() => {
        setTimeout(() => {
          self.blockUI.stop(); // Stop blocking
        }, 1000);
      })
    )
    .subscribe(httpresponse => {
      if (httpresponse.status === 200) {
        if (httpresponse.status === 200) {
          this.modalService.dismissAll();
          this.getAll();
        } else {
          alert('Something Went Wrong!');
        }
        // this.getAll();
      } else {
        console.log('Error');
      }
    });
  }

  public getImageFolderName() {
    return 'videos';
  }

  public getImageFileType() {
    return 'Videos';
  }

  public tempDelete(id: any, status: any) {
    this.imageId = id;
    this.imageStatus = status;
  }

}
