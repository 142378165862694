import { Injectable } from '@angular/core';
import { HttpClientGlobal } from '../core/httpclient';
import { Observable } from 'rxjs';
import { HttpResponse } from '../core/http.model';

@Injectable()
export class YessFoundationService {
  constructor(private httpClient: HttpClientGlobal) {}

  saveYessFoundation(data: any): Observable<HttpResponse> {
    return this.httpClient.post('/api/yessfoundation/save', data);
  }

  getYessFoundationList(): Observable<HttpResponse> {
    return this.httpClient.get('/api/yessfoundation/get', {});
  }

  updateYessFoundationStatus(data: any): Observable<HttpResponse> {
    return this.httpClient.post('/api/yessfoundation/updatestatus', data);
  }

  editData(id: any): Observable<HttpResponse> {
    return this.httpClient.post('/api/yessfoundation/edit', {id});
  }

  updateEvent(data: any): Observable<HttpResponse> {
    return this.httpClient.post('/api/yessfoundation/update', data);
  }

  deleteYessEvent(data: any): Observable<HttpResponse> {
    return this.httpClient.post('/api/yessfoundation/delete', data);
  }

}