import { Injectable } from '@angular/core';
import { HttpClientGlobal } from '../core/httpclient';
import { Observable } from 'rxjs';
import { HttpResponse } from '../core/http.model';

@Injectable()
export class SectorService {
  constructor(private httpClient: HttpClientGlobal) {}

  getSectors(): Observable<HttpResponse> {
    return this.httpClient.get('/api/sector/getsectors', {});
  }

  getModules(): Observable<HttpResponse> {
    return this.httpClient.get('/api/modules/list', {});
  }

  getTowersSectorWise(data): Observable<HttpResponse> {
    return this.httpClient.post('/api/sector/getTowersSectorWise', {data});
  }

  getIndividualTowerWise(data): Observable<HttpResponse> {
    return this.httpClient.post('/api/sector/getIndividualTowerWise', {data});
  }
}
