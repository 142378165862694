import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MastersService } from 'src/app/services/masters.service';
import { NavService } from 'src/app/shared/service/nav.service';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment'; // Import the environment variable


@Component({
  selector: 'app-staff-registration',
  templateUrl: './staff-registration.component.html',
  styleUrls: ['./staff-registration.component.scss']
})

export class StaffRegistrationComponent implements OnInit {
  designationList:any;
  proList:any;
  public id = '';
  isEdit:boolean= false;
  fetchedFormData:any;
  categories: any;
  projectNames :any;
  isSubmitting: boolean = false;
  fileInvalid: boolean = false;

  alphaCharOnly(e:any) {
    // Accept only alpha numerics, not special characters
     var regex = new RegExp("^[a-zA-Z]+$");
     var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
     if (regex.test(str)) {
         return true;
     }
     e.preventDefault();
     return false;
   }  
   numberOnly(event: any) {
    // Accept only numbers
    const regex = new RegExp('^[0-9]+$');
    const str = String.fromCharCode(!event.charCode ? event.which : event.charCode);
  
    // Check if the input is empty and the character is a space, prevent it
    if (event.target.value.trim() === '' && str === ' ') {
      event.preventDefault();
      return false;
    }
    
    if (regex.test(str)) {
      return true;
    }
  
    event.preventDefault();
    return false;
  }

  emailKeyPress(event: any) {
    // Check if the key pressed is a space and if the email field is empty
    if (event.key === ' ' && this.staffForm.get('email').value.trim() === '') {
      event.preventDefault(); // Prevent the default action (typing)
    }
  }

   public sectorConfig = {
    displayKey: 'projectName',
    search: true,
    height: 'auto',
    placeholder: 'Select Project',
    noResultsFound: 'No results found!',
    searchPlaceholder: 'Enter Project Name',
    searchOnKey: 'projectName',
  };

  public modConfig = {
    displayKey: 'module_name',
    search: true,
    height: 'auto',
    placeholder: 'Select Module',
    noResultsFound: 'No results found!',
    searchPlaceholder: 'Enter Module Name',
    searchOnKey: 'module_name',
  };
  userProjects:any;
  staffForm: any;
  moduleItems:any;
  constructor(
    private formBuilder:FormBuilder,
    private masterService: MastersService,
    public navServices: NavService,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private httpClient: HttpClient
  ) { }

  ngOnInit() {
    this.staffForm = this.formBuilder.group({
      id: '',
      firstName: ['',[Validators.required, Validators.minLength(1), Validators.pattern("[a-zA-Z ]*")]],
      lastName: ['',[Validators.required, Validators.minLength(1), Validators.pattern("[a-zA-Z ]*")]],
      mobileNo: ['', [Validators.required, Validators.minLength(9),Validators.pattern("^[0-9]{10}$"), Validators.maxLength(10)]],
      designation:['',Validators.required],
      email: ['',  [Validators.required, Validators.email, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,}$")]],
      project_name:['',Validators.required],
      module:['',Validators.required],
      password:['',Validators.required],
      files: this.formBuilder.array([])
    });
    this.getDesignationList();
    this.getProjectList();
    this.getModuleListForStaff();
    this.route.params.subscribe(params => {
      this.isEdit = params['isEdit'] === 'true'; // Convert string to boolean
      this.id = params['id']; // Assuming your parameter name for id is 'id'
    });
    if(this.route.snapshot.paramMap.get('id')){
      // this.getDataForUpdate()
      var url = "/api/user/usersAssociatedProjects/" + this.id;
      this.masterService.getMethodAPI(url).subscribe((response: any) => {
        if (response.code == 1) {
          // console.log("response data list", response.data);
          this.userProjects = response.data;
          this.getDataForUpdate();
        } else {
        }
      });
    } 
  }
  getDataForUpdate(){
   const postObj={
      "module": "users",
       id: this.route.snapshot.paramMap.get('id'), 
      "relation": [
          {
              "module": "users_associated_projects"
          },
          {
              "module": "users_attachments"
          },
          {
            "module": "roles"
          }
      ]
  }
    
    this.masterService.getMasterData(postObj).subscribe((response:any)=>{
      if(!response){
        console.log("No data Found");
      }
      else{
        // console.log("response-->",response.data);
        const respData = response.data[0];
        const user_project = this.userProjects;
        this.fetchedFormData = response.data[0];
        this.getProjectList();
        this.getDesignationList();
        this.getModuleListForStaff();
        // return;
        this.staffForm.patchValue({
          id: respData.id,
          firstName: respData.fname,
          lastName: respData.lname,
          mobileNo: respData.mobile,
          designation: respData.designation_id,
          email: respData.email,
          project_name: user_project ? user_project.map(project => ({
            id: project.id,
            project_id: project.project_id,
            // projectName: this.projectNames[project.project_id - 1] ||  `${project.project_id}`
            projectName: project.projectName  
            // Adding project_id
            // Other project properties you may have
          })) : [], // Check if users_associated_projects is defined
          module: respData.roles ? respData.roles.map(role => {
            const parsedRole = JSON.parse(role.role);
            const moduleid = role.id
            return parsedRole.map(mod => ({
              module: mod.module,
              module_name: mod.module_name,
              id : moduleid 
              // Include role_id
            }));
          }).flat().concat(this.staffForm.value.module && Array.isArray(this.staffForm.value.module) ? this.staffForm.value.module.filter(mod => !respData.roles.some(role => JSON.parse(role.role).some((r: any) => r.module === mod.id))) : []) : [],
        
  
          password: [''], // Password is sensitive data, it's better not to pre-fill it
          // You can handle files separately if needed
        });
        // console.log(this.staffForm.value);
        const filesArray = this.staffForm.get('files') as FormArray;
        respData.users_attachments.filter(file => file.is_active === 1).forEach(file => {
          const imageUrl = environment.apiUrl + file.file_name; // Construct complete image URL

          filesArray.push(
            this.formBuilder.group({
              id: [file.id],
              fileName: [file.name, [Validators.required, Validators.minLength(3)]],
              file: [null],
              imageUrl: [imageUrl] // Assuming this is the URL to display the image
            })
          );
        });
      }

    })
  }
  getDesignationList(){
    const postObj={
      "module": "designation_masters"
    }
    this.masterService.getMasterData(postObj).subscribe((response:any)=>{
      if(!response){
        console.log("No data found");
      }
      else{
        this.designationList = response.data;
        // console.log(this.designationList);
        
      }
    })
  }

  getModuleListForStaff(){
      const postObj={
        "module": "modules"
      }
      this.masterService.getMasterData(postObj).subscribe((response:any)=>{
        if(!response){
          console.log("Error")
        }else{
          this.moduleItems = response.data;
          // console.log("response for module", this.moduleItems);
        }
      })
  }
 
  getProjectList(){
    const postObj={
      "module": "project_masters"
    }
    this.masterService.getMasterData(postObj).subscribe((response:any)=>{
      if(!response){
        console.log("No data found");
      }
      else{
        this.proList = response.data;
        // console.log(this.proList);
        this.proList.sort((a, b) => a.id - b.id);

        // Map the sorted project names to projectNames
        this.projectNames = this.proList.map(project => project.projectName); 
        // console.log("projects", this.projectNames);       
        // console.log("projects", this.proList);       
      }
    })
  }


  onSubmit(){
    this.isSubmitting = true;
    if(this.staffForm.valid){
      const formData = this.staffForm.value;
      const rolesArray = formData.module.map(role => ({
        "module": role.id,
        "module_name": role.module_name
      }));
      const rolesArrayStringified = JSON.stringify(rolesArray); // Stringify the rolesArray
       var admin_type = 0;
      if(formData.designation == 1){
         admin_type = 1 ;
       }else{
          admin_type = 2;
        // return false
       }
       const postObj={
           "modelName": "users",
           "inputData": {
               "fname": formData.firstName,
               "lname": formData.lastName,
               "email": formData.email,
               "admin_type":admin_type,
               "username": formData.email,
               "mobile": formData.mobileNo,
               "designation_id": formData.designation,
               "password": formData.password,
               "is_active": 1,
              //  "username": formData.email,
               "users_associated_projects": formData.project_name.map(project => ({
                "project_id": project.id,
                "is_active": 1
            })),
              "users_attachments": formData.files.map(
                (attachment: any) => ({
                  name: attachment.fileName,
                  file_name: attachment.imageUrl,
                  is_active: 1
                })
              ),
              "roles": [
                {
                  "role": rolesArrayStringified,
                  "status": 1
                }
              ],
           },
           "relation": [
               {
                   "modelName": "users_associated_projects"
               },
               {
                   "modelName": "users_attachments"
               },
               {
                   "modelName": "roles"
               }
           ]
       }
       
      //  console.log(JSON.stringify(postObj, null, 2))       
      //  return;
       this.masterService.postFormData(postObj).subscribe((response:any)=>{
        if(response.code == 0 || response.status == 500){
          // console.log("No Response", response.message.errors[0].message);
          this.toastr.error(response.message);
          this.isSubmitting  = false;
        }else{
          console.log("Staff Registred Successfully");
          this.toastr.success("Staff Registred Successfully");
          this.resetForm();
          this.router.navigate(['/user/staff_listing']);
          this.isSubmitting = false;
        }
       })
    }else {
      this.staffForm.markAllAsTouched();
      this.isSubmitting = false;

    }
  }


  updateRecord() {
    this.isSubmitting = true;
    this.saveDraft();
    if (this.staffForm.valid) {
      const formData = this.staffForm.value;
      // console.log(formData);
     
      // const rolesData = [{
      //   id: formData.module[0].id, // Assuming this is the roles tables id
      //   role: formData.module.map(role => ({
      //     module: role.module || role.id,
      //     module_name: role.module_name
      //   }))
      // }];
      // const stringRoleFormat =  JSON.stringify(rolesData);
      const rolesData = [{
        id: formData.module[0].id, // Assuming this is the roles tables id
        role: JSON.stringify(formData.module.map(role => ({
            module: role.module || role.id,
            module_name: role.module_name
        })))
    }];
    
    // const rolesDataJSONString = JSON.stringify(rolesData);
      // this.isSubmitting = false;
      // console.log(rolesData);
      // return;
      const projectData = formData.project_name.map(project => {
        if (project.project_id) {
            // If id exists, include it in the data
            return {
                id: project.id,
                project_id: project.project_id,
                is_active: 1
            };
        } else {
            // If id doesn't exist, exclude it and include project_id only
            return {
                project_id: project.id,
                is_active: 1
            };
        }
    });

    const attachmentsData = formData.files.map((attachment: any, index: number) => {
      if (attachment.id ) {
          // If id exists and it's a data URL, update the existing attachment
          const base64String = attachment.imageUrl; // Extract base64 string from the file
          console.log("base64String", base64String);
          
          if (attachment.imageUrl){

            return {
                "id": attachment.id,
                "name": attachment.fileName,
                "is_active": 1
            };
          }else{
            return {
              "id": attachment.id,
              "name": attachment.fileName,
              "file_name": base64String, // Send only the base64 string
              "is_active": 1
          };
          }
      } else if (!attachment.id && attachment.imageUrl.startsWith('data:image')) {
          // If id doesn't exist but it's a data URL, it's a new attachment
          const base64String = attachment.imageUrl; // Extract base64 string from the file
          return {
              "name": attachment.fileName,
              "file_name": base64String, // Send only the base64 string
              "is_active": 1
          };
      } else {
          // Ignore files with paths or those without IDs and non-data URL images
          return null;
      }
  }).filter(Boolean);
  var admin_type = 0;
  if(formData.designation == 1){
     admin_type = 1 ;
   }else{
      admin_type = 2;
    // return false
   }
      // console.log(rolesData);
      // return;
      const inputData = {
        "fname": formData.firstName,
        "lname": formData.lastName,
        "email": formData.email,
        "username": formData.email,
        "mobile": formData.mobileNo,
        "designation_id": formData.designation,
        "admin_type": admin_type,
        "is_active": 1          
      };
     
  
      if (formData.password && typeof formData.password === 'string' && formData.password.trim() !== '') {
        // Add password to inputData
        inputData["password"] = formData.password;
      }
      // console.log(inputData);
      // return;
      const postObj = {
        "modelName": "users",
        "id": this.id,
        "inputData":  inputData,
        "relation": [
          {
            "subModelName": "users_associated_projects",
            "subModelForeignKey": "user_id", // static
            "subModelInputData": projectData
          },
          {
            "subModelName": "users_attachments",
            "subModelForeignKey": "user_id",
            "subModelInputData": attachmentsData
            // "subModelInputData": formData.files.map((attachment: any, index: number) => {
            //     if (attachment.imageUrl.startsWith('data:image')) {
            //         // Extract base64 string from the file
            //         const base64String = attachment.imageUrl;
            //         return {
            //             "name": attachment.fileName,
            //             "file_name": base64String, // Send only the base64 string
            //             "is_active": 1
            //         }
            //     } else {
            //         // Ignore files with paths
            //         return null;
            //     }
            // }).filter(Boolean)
        }
        
        ,
          {
            "subModelName": "roles",
            "subModelForeignKey": "user_id",
            "subModelInputData": rolesData
        }
        ]
      };
  
      // console.log(JSON.stringify(postObj, null, 2))       
      // return;
      this.masterService.postFormData(postObj).subscribe((response: any) => {
        if (response.code == 0  || response.status == 500) {
          console.log("Error");
          this.toastr.error(response.message);
          this.isSubmitting = false;

        } else {
          console.log("Staff Updated Successfully");
          this.toastr.success("Staff Updated Successfully");
          this.resetForm();
          this.router.navigate(['/user/staff_listing']);
          this.isSubmitting = false;

        }
      })
    } else {
      this.staffForm.markAllAsTouched();
      this.isSubmitting = false;

    }
  }
  


  addFileField(){
  const filesArray = this.staffForm.get('files') as FormArray;
  filesArray.push(
    this.formBuilder.group({
      id:'',
      fileName: ['', [Validators.required, Validators.minLength(3)]],
      file: [null],
      imageUrl: ['']
    })
  );
  }

  onFileSelected(event: Event, index: number) {
    const fileInput = event.target as HTMLInputElement;
    if (fileInput.files && fileInput.files.length > 0) {
      const selectedFile = fileInput.files[0];
      const filesArray = this.staffForm.get('files') as FormArray;
      const fileGroup = filesArray.at(index) as FormGroup;
      const reader = new FileReader();
      reader.onload = () => {
        const base64String = reader.result as string;
        fileGroup.patchValue({
          file: selectedFile,
          imageUrl: base64String
        });
      };
      reader.readAsDataURL(selectedFile);
    }
  }
  
  getFilesControls(): AbstractControl[] {
    const filesArray = this.staffForm.get('files') as FormArray;
    return filesArray.controls;
  }
  
  removeFile(index: number) {
    const filesArray = this.staffForm.get('files') as FormArray;
  
    // Display confirmation dialog
    const confirmed = window.confirm("Are you sure you want to remove this file?");
    
    if (confirmed) {
      const postObj = {
        "modelName": "users_attachments",
        "id": filesArray.at(index).value.id,
        "inputData": {
          "is_active": 0
        }
      };
  
      // console.log(postObj);
  
      this.masterService.postFormData(postObj).subscribe((response: any) => {
        if (!response) {
          console.log("Error");
        } else {
          console.log("Success", response);
        }
      });
  
      filesArray.removeAt(index);
    } else {
      console.log("Action canceled by user.");
      // Do nothing or add any additional handling you need
    }
  }
  
  
  getSelectedImage(index: number) {
    const filesArray = this.staffForm.get('files') as FormArray;
    const fileGroup = filesArray.at(index) as FormGroup;
    return fileGroup.get('imageUrl') ? fileGroup.get('imageUrl').value : null;
  } 
  resetForm(){
    this.staffForm.reset();
  }
  
  backToListing(){
    this.resetForm();
    this.router.navigate(["/user/staff_listing"]);
  }

  isImage(url: string): boolean {
    // debugger;
    return /^data:image\/(jpeg|jpg|png|gif);base64,|| jpeg|jpg|png|gif /.test(url);
  }

  isDocument(url: string): boolean {
    // debugger;
    return /^data:application\/(pdf|doc|docx|txt);base64, || pdf|doc|docx|txt /.test(url);
  }
  selectProject(event:any){
      this.staffForm.controls.project_name.patchValue(event.value);
  }
  selectModule(event:any){
    this.staffForm.controls.module.patchValue(event.value);
  }
  public saveDraft(): void {
    this.staffForm.get('password').clearValidators();
    this.staffForm.get('password').updateValueAndValidity();
 }
}
