import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { finalize } from 'rxjs/operators';
import { SectorService } from 'src/app/services/sector.service';
import { EventService } from 'src/app/services/event.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-add-event',
  templateUrl: './edit-event.component.html',
  styleUrls: ['./edit-event.component.scss'],
  providers: [SectorService, EventService]
})
export class EditEventComponent implements OnInit {

  public title: string = 'AGM project';
  public latitude: number;
  public longitude: number;
  public zoom:number;

  @BlockUI() blockUI: NgBlockUI;
  public productForm: FormGroup;
  public counter = 1;
  public categories = [];
  public products = [];
  private tagsPresent = false;
  public towerList = [];
  public individualList = [];
  private towerPresent = false;
  public showSector = false;
  public showTower = false;
  public showIndividual = false;
  public retrieveTowers = false;
  public retrieveIndividuals = false;
  public selectTowerData = false;
  public selectIndividualData = false;
  private prodData: any;
  private folderNameTimestamp: any;
  public ckeConfig = { height: 400, language: "en", allowedContent: true, removePlugins: "elementspath", resize_enabled: false };
  private tempDataForLoading: any;
  private selectedTagArray: any;
  public showEditor = false;
  public attachmentArr: any;
  public imagesReady = false;
  public id = '';
  retrievedTags: any;
  private tagList: any;
  data: any;
  public eventTypeSelection = true;
  public url = [
    {
      img: 'assets/images/user.png'
    },
    {
      img: 'assets/images/user.png'
    },
    {
      img: 'assets/images/user.png'
    },
    {
      img: 'assets/images/user.png'
    },
    {
      img: 'assets/images/user.png'
    }
  ];

  public radioButtonData = [
    {
        name: 'Entire Mangalam',
        value: '1'
    },
    {
        name: 'Sector',
        value: '2'
    },
    {
        name: 'Tower',
        value: '3'
    }
  ];
  public broadcastType = 1;
  public broadcastName = '';

  public broadcastTo = '';
  public tempData: any;
  public sectorConfig = {
    displayKey: 'DESCRIPTION',
    search: true,
    height: 'auto',
    placeholder: 'Select Sector',
    noResultsFound: 'No results found!',
    searchPlaceholder: 'Enter Sector Name',
    searchOnKey: 'DESCRIPTION'
  };

  public towerConfig = {
    displayKey: 'SectorTower',
    search: true,
    height: 'auto',
    placeholder: 'Select Tower',
    noResultsFound: 'No results found!',
    searchPlaceholder: 'Enter Tower Name',
    searchOnKey: 'SectorTower'
  };

  public individualConfig = {
    displayKey: 'individualTower',
    search: true,
    height: 'auto',
    placeholder: 'Select Individual',
    noResultsFound: 'No results found!',
    searchPlaceholder: 'Enter Individual Name',
    searchOnKey: 'individualTower'
  };



  constructor(
    private fb: FormBuilder,
    private eventService: EventService,
    private sectorService: SectorService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.attachmentArr = [];
    this.productForm = this.fb.group({
      broadcast_type: ['1', [Validators.required]],
      sectors: [''],
      tower: [''],
      id: [''],
      individual: [''],
      event_name: ['', [Validators.required]],
      event_description: ['', [Validators.required]],
      attachment: ['', Validators.required],
      location: ['', Validators.required],
      webpageurl: [''],
      date: ['', Validators.required],
      for_guest: [false],
    });

    const date = new Date();  // 2009-11-10
    const month = date.toLocaleString('default', { month: 'short' });
    const dateNo = date.getDate();
    const RandNo = Math.floor(Date.now() / 1000);
    this.folderNameTimestamp = month + '-' + dateNo + '-' + RandNo;
  }

  increment() {
    this.counter += 1;
  }

  decrement() {
    this.counter -= 1;
  }

  // FileUpload
  readUrl(event: any, i) {
    if (event.target.files.length === 0) {
      return;
    }
    // Image upload validation
    const mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = _event => {
      this.url[i].img = reader.result.toString();
    };
  }

  public checkBroadType(id) {
    switch (id) {
      case 1 || '1':
          return 'Mangalam';
          case 2 || '2':
            this.eventTypeSelection = false;
              return 'Sector';
              case 3 || '3':
                this.eventTypeSelection = false;
                  return 'Tower';
                  case 4 || '4':
                    this.eventTypeSelection = false;
                      return 'Individual';
      default:
        break;
    }
  }
  
  public checkToName(id: any, data: any) {
    let tempArr = '';
    switch (id) {
      case 1 || '1':
          return 'All Mangalam';
      case 2 || '2':
          this.tempData = JSON.parse(data.sectors);
          for (let i = 0; i < this.tempData.length; i++) {
            const element = this.tempData[i];
            tempArr += element.DESCRIPTION;
            if (i+1 !== this.tempData.length) {
              tempArr += ', ';
            }
          }
          return tempArr;
      case 3 || '3':
        this.tempData = JSON.parse(data.towers);
        for (let i = 0; i < this.tempData.length; i++) {
          const element = this.tempData[i];
          tempArr += element.DESCRIPTION;
          if (i+1 !== this.tempData.length) {
            tempArr += ', ';
          }
        }
        return tempArr;
      case 4 || '4':
        this.tempData = JSON.parse(data.individuals);
        for (let i = 0; i < this.tempData.length; i++) {
          const element = this.tempData[i];
          tempArr += element.LOGINID;
          if (i+1 !== this.tempData.length) {
            tempArr += ', ';
          }
        }
        return tempArr;
      default:
        break;
    }
  }

  ngOnInit() {
// this.getAll();
    // this.setCurrentLocation();
    this.id = this.route.snapshot.paramMap.get('id')
    this.blockUI.start('Loading. . .'); // Start blocking
    const self = this;
    self.eventService
      .editData(this.id)
      .pipe(
        finalize(() => {
          setTimeout(() => {
            self.blockUI.stop(); // Stop blocking
          }, 1000);
        })
      )
      .subscribe(httpresponse => {
        if (httpresponse.status === 200) {
          this.data = httpresponse.data;
          // console.log(this.data);
          this.broadcastType = this.data.broadcast_type;
          this.broadcastName = this.checkBroadType(this.broadcastType);
          this.productForm.controls['id'].patchValue(this.id);
          this.productForm.controls['broadcast_type'].patchValue(this.data.broadcast_type.toString());
          this.productForm.controls['sectors'].patchValue(this.data.sectors);
          this.productForm.controls['tower'].patchValue(this.data.tower);
          this.productForm.controls['event_name'].patchValue(this.data.event_name);
          this.productForm.controls['event_description'].patchValue(this.data.event_description);
          this.productForm.controls['attachment'].patchValue(this.data.attachment);
          this.productForm.controls['location'].patchValue(this.data.location);
          const location = JSON.parse(this.data.location);
          this.latitude = location.latitude;
          this.longitude = location.longitude;
          this.productForm.controls['webpageurl'].patchValue(this.data.webpageurl);
          this.productForm.controls['date'].patchValue(this.data.date);
          this.productForm.controls['for_guest'].patchValue(this.data.for_guest);
          const tempArr = JSON.parse(this.data.attachment);
          tempArr.forEach(element => {
            this.attachmentArr.push(element);
          });
          this.imagesReady = true;
          this.showEditor = true;
          // this.attachmentArr = JSON.parse(this.data.attachment);
        }
      });
  }
   // Get Current Location Coordinates
   private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = 18.51670171432715;
        this.longitude = 73.94193872596429;
        this.zoom = 15;
      });
    }
  }
  public getSectorList() {
    this.blockUI.start('Loading. . .'); // Start blocking
    const self = this;
    self.sectorService
      .getSectors()
      .pipe(
        finalize(() => {
          setTimeout(() => {
            self.blockUI.stop(); // Stop blocking
          }, 1000);
        })
      )
      .subscribe(httpresponse => {
        if (httpresponse.status === 200) {
          this.categories = [];
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < httpresponse.data.length; i++) {
            this.data = httpresponse.data[i];
            this.categories.push({
              SectorID: this.data.SectorID,
              CODE: this.data.CODE,
              DESCRIPTION: this.data.DESCRIPTION
            });
          }
        }
      });
  }

  markerDragEnd(event: any) {
    console.log(event);
    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;
  }

  public selectSector(event: any) {
    this.productForm.controls.sectors.patchValue(JSON.stringify(event.value));
    if (this.retrieveTowers) {
      if (event.value.length > 0) {
        this.blockUI.start('Loading Towers. . .'); // Start blocking
        const self = this;
        this.selectTowerData = false;
        this.towerList = [];
        self.sectorService
          .getTowersSectorWise(event.value)
          .pipe(
            finalize(() => {
              setTimeout(() => {
                self.blockUI.stop(); // Stop blocking
              }, 1000);
            })
          )
          .subscribe(httpresponse => {
            if (httpresponse.status === 200) {
              // tslint:disable-next-line: prefer-for-of
              // console.log(httpresponse.data);
              // console.log(httpresponse.data.length);
              // tslint:disable-next-line: prefer-for-of
              for (let i = 0; i < httpresponse.data.length; i++) {
                const element = httpresponse.data[i];
                this.towerList.push(element);
              }
              this.selectTowerData = true;
              // console.log(this.towerList);
            }
          });
      }
    }
  }

  public selectTower(event: any) {
    this.productForm.controls.tower.patchValue(JSON.stringify(event.value));
    if (this.retrieveIndividuals) {
      if (event.value.length > 0) {
        this.blockUI.start('Loading Individuals/Flat Data. . .'); // Start blocking
        const self = this;
        this.selectIndividualData = false;
        this.individualList = [];
        self.sectorService
          .getIndividualTowerWise(event.value)
          .pipe(
            finalize(() => {
              setTimeout(() => {
                self.blockUI.stop(); // Stop blocking
              }, 1000);
            })
          )
          .subscribe(httpresponse => {
            if (httpresponse.status === 200) {
              // console.log(httpresponse.data);
              // console.log(httpresponse.data.length);
              // tslint:disable-next-line: prefer-for-of
              for (let i = 0; i < httpresponse.data.length; i++) {
                const element = httpresponse.data[i];
                this.individualList.push(element);
              }
              this.selectIndividualData = true;
              // console.log(this.individualList);
            }
          });
      }
    }
  }

  public getTowerListFromArr() {
    return this.towerList;
  }

  onSubmit(): void {
 
    const locationCo = {
      latitude: this.latitude ? this.latitude : 18.51670171432715,
      longitude: this.longitude ? this.longitude : 73.94193872596429,
    };
    this.productForm.controls.location.patchValue(JSON.stringify(locationCo));
    // console.log(this.productForm.value);
    this.blockUI.start('Saving...'); // Start blocking
    if (this.validateForm()) {
      const self = this;
      self.eventService
        .updateEvent(this.productForm.value)
        .pipe(
          finalize(() => {
            setTimeout(() => {
              self.blockUI.stop(); // Stop blocking
            }, 1000);
          })
        )
        .subscribe(httpresponse => {
          if (httpresponse.status === 200) {
            this.router.navigate(['/events/list']);
            this.toastr.success('Event Updated Successfully!');
          } else {
            this.toastr.warning(httpresponse.message);
          }
        });
    }
  }

  public validateForm() {
    return true;
  }

  public imagesReceived(data: any) {
    this.productForm.controls.attachment.patchValue(data.imagesData);
  }

  public getFolderName() {
    // console.log(this.folderNameTimestamp);
    return this.folderNameTimestamp;
  }

  public getFileType() {
    return 'Images';
  }

  public onItemChange(item: any) {
    switch (item.value) {
      case '1':
        this.showSector = false;
        this.showTower = false;
        this.showIndividual = false;
        this.retrieveTowers = false;
        this.retrieveIndividuals = false;
        break;

      case '2':
        this.getSectorList();
        this.showSector = true;
        this.showTower = false;
        this.showIndividual = false;
        this.retrieveTowers = false;
        this.retrieveIndividuals = false;
        break;

      case '3':
        this.getSectorList();
        this.showSector = true;
        this.showTower = true;
        this.retrieveTowers = true;
        this.showIndividual = false;
        this.retrieveIndividuals = false;
        break;

      case '4':
        this.getSectorList();
        this.showSector = true;
        this.showTower = true;
        this.showIndividual = true;
        this.retrieveTowers = true;
        this.retrieveIndividuals = true;
        break;

      default:
        break;
    }
  }

}
