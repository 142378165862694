import { Injectable } from '@angular/core';

@Injectable()
export class WebStorage {

  private locStorage: any;
  private sesStorage: any;

  constructor() {
    this.locStorage  = window.localStorage;
    this.sesStorage  = window.sessionStorage;
   }

  get(key: any) {
    const sessionData = this.sesStorage.getItem(key);
    const sData = (this.notEmpty(sessionData)) ? JSON.parse(sessionData).value : '';
    const localData = this.locStorage.getItem(key);
    const lData = (this.notEmpty(localData)) ? JSON.parse(localData).value : '';
    return sData || lData;
  }

  localStore(key: any, value: any) {
    this.clear(key);
    const data = { value };
    const val = JSON.stringify(data);
    this.locStorage.setItem(key, val);
  }

  sessionStore(key: any, value: any) {
    this.clear(key);
    const data = { value };
    const val = JSON.stringify(data);
    this.sesStorage.setItem(key, val);
  }

  clear(key: any) {
    this.locStorage.removeItem(key);
    this.sesStorage.removeItem(key);
  }

  exists(key: any) {
    if (this.get(key) != null) {
      return true;
    } else {
      return false;
    }
  }

  private notEmpty(data: any) {
    let res = true;
    const dataType = typeof data;
    switch (dataType) {
      case 'object':
        if (data == null || data.length < 1) {
          res = false;
        }
        break;

      case 'undefined':
        res = false;
        break;

      case 'number':
        if (data == '') {
          res = false;
        }
        break;
      case 'string':
        if (data.trim() == '') {
          res = false;
        }
        break;
    }

    return res;
  }
}
