import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import 'hammerjs';
import 'mousetrap';
import { BlockUIModule } from 'ng-block-ui';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { CKEditorModule } from 'ngx-ckeditor';
import { DropzoneConfigInterface, DropzoneModule, DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { ImageUploadModule } from 'src/app/shared/imageupload/imageupload.module';
import { UserListComponent } from './components/user-list/userlist.component';
// import { NgModule } from '@angular/core';
import { UsersRoutingModule } from './user-routing.module';
import { DataTablesModule } from 'angular-datatables';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  maxFilesize: 50,
  url: 'https://httpbin.org/post',
};

@NgModule({
  declarations: [UserListComponent],
  imports: [
    CommonModule,
    SelectDropDownModule,
    BlockUIModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
    UsersRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA3CS7S2HJgMtBSiEzgR1ZKmtvz6T3KuGs',
      libraries: ['places']
    }),
    Ng2SmartTableModule,
    NgbModule,
    DropzoneModule,
    GalleryModule.forRoot(),
    ImageUploadModule
  ],
  providers: [
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    },
    NgbActiveModal
  ]
})
export class AppUserModule { }
