import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MastersService } from 'src/app/services/masters.service';
@Component({
  selector: 'app-wings-master',
  templateUrl: './wings-master.component.html',
  styleUrls: ['./wings-master.component.scss']
})
export class WingsMasterComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;

  alphaNumericOnly(e: any) {
    // Accept only alphanumeric characters, no spaces
    const regex = new RegExp('^[a-zA-Z0-9]+$');
    const str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  
    // Check if the input is empty and the character is a space, prevent it
    if (e.target.value.trim() === '' && str === ' ') {
      e.preventDefault();
      return false;
    }
  
    if (regex.test(str)) {
      return true;
    }
  
    e.preventDefault();
    return false;
  }
  

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  wingsForm: any;
  // categories: any;
  constructor(
    // private notificationsService: NotificationsService,
    private modalService: NgbModal,
    // private loginService: LoginService,
    private formBuilder: FormBuilder,
    // private sectorService: SectorService
    private masterservice: MastersService,
    private toastr: ToastrService  
  ) {
      // this.categories = categoryDB.category;
      this.wingsForm = this.formBuilder.group({
        id:'',
        project_name : ['', [Validators.required]],
        wings_name : ['', [Validators.required]],
      }
  );
  }
  public closeResult: string;
  public sectorData: any;
  matchUserDetails: any;
  userData: any;
  data: any;
  singleEvent: any;
  deleteId: any;
  dataPresent = false;
  datatableDataPresent = true;
  isUpdate:boolean=false;  
  proList:any;
  isSubmitting: boolean = false;

  ngOnInit() {
    this.dtOptions = {
      paging: true,
      retrieve: true,
      searching: true,
      responsive: true,
      pageLength: 5,
      lengthMenu: [5, 10, 25, 50, 75, 100],
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search in table'
      },
      order: [[
        0, 'asc'
      ]]
    };
    this.getWingsList();
    this.getAllProjects();
  }
  getAllProjects(){
    const postobj={
      module:"project_masters"
    }
    this.masterservice.getMasterData(postobj).subscribe((response:any)=>{
      if(!response){
        console.log("No Project Found");
      }else{
        // console.log("Project List");
        this.proList = response.data;
        // console.log(this.proList);
      }
    })
  }
  getWingsList(){
    this.blockUI.start('Retrieving Wings List...'); // Start blocking
    const postObj= 
    {
        "module": "wings_masters",
        "relation": [
            {
                "module": "project_masters"
            }
        ],
        "condition": {
            "isActive": 1
        }
    }
    this.masterservice.getMasterData(postObj)
      .pipe(finalize(() => { this.blockUI.stop(); }))
      .subscribe((response:any) => {
        if (response) {
          this.data = response.data;
          // this.toastr.success('Wings Data Loaded.');
          if (this.dtElement && this.dtElement.dtInstance) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.dtTrigger.next();
            });
          } else {
            this.dtTrigger.next();
          }
        } else {
          console.log("No response");
        }
      })
  }
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
    private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.resetForm();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.resetForm();
      return 'by clicking on a backdrop';
    } else {
      this.resetForm();
      return `with: ${reason}`;
    }
  }
  submitForm(){
    this.isSubmitting = true; // Add this line

    if (this.wingsForm.valid) {
      const postObj={
        "modelName": "wings_masters",
        "inputData": {
            "project_id": this.wingsForm.value.project_name,
            "wingsName": this.wingsForm.value.wings_name,
            "isActive": 1
        }
    }
    // console.log(postObj);
    // return;
    this.masterservice.postFormData(postObj).subscribe((response:any)=>{
      if(response.code == 0 || response.status == 500){
        console.log("No Response", response);
        this.toastr.error(response.message);
        this.isSubmitting = false; // Add this line
      }
      else{
        // console.log(response);
        console.log("Record Created Succesfully");
        this.toastr.success("Record Created Succesfully");
        this.modalService.dismissAll();
        this.getWingsList();
        this.resetForm();
      }
      this.isSubmitting = false; // Add this line

    })
      // console.log("Project Form Values", this.projectForm.value);    
    }else{
      this.wingsForm.markAllAsTouched();
      this.isSubmitting = false; // Add this line

    }
  }
  updateForm(){
    this.isSubmitting = true; // Add this line

    if(this.wingsForm.valid){
      const postObj={
        "modelName": "wings_masters",
        "id": this.wingsForm.value.id,   
        "inputData": {
            "project_id": this.wingsForm.value.project_name,
            "wingsName": this.wingsForm.value.wings_name,
            "isActive": 1,
        }
      }
      // console.log(postObj);
      // return;
      this.masterservice.postFormData(postObj).subscribe((response:any)=>{
        if(response.code == 0 || response.status == 500){
          console.log("No Response", response);
          this.toastr.error(response.message);
        }
        else{
          console.log("Record Updated Succesfully", response);
          this.toastr.success("Record Update Successfully");
          this.modalService.dismissAll();
          this.getWingsList();
          this.resetForm();
          this.isUpdate = false;
        }
        this.isSubmitting = false; // Add this line

      }); 
    }
    else{
      this.wingsForm.markAllAsTouched();
      this.isSubmitting = false; // Add this line

    }
  }
  viewUserDetails(data:any){
    // console.log(data);
    this.isUpdate = true;
    this.wingsForm.patchValue({
      id: data.id,
      project_name: data.project_masters.id,
      wings_name:data.wingsName
      });
      // Additional logic if needed
  } 

  resetForm(){
    this.wingsForm.reset();
    this.wingsForm.get('project_name').setValue('');
    this.modalService.dismissAll();
    this.isUpdate = false;
  }
}
