import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { humanizeBytes, UploaderOptions, UploadProgress, UploadFile, UploadInput, UploadOutput } from 'ngx-uploader';
import { Subject } from 'rxjs';
import { WebStorage } from 'src/app/core/web.storage';
import { ImageService } from 'src/app/services/image.service';



@Component({
  selector: "image-upload",
  templateUrl: './view/image.html',
  styleUrls: ['./css/image.css'],
  providers: [ImageService, WebStorage]
})
export class ImageComponent implements OnInit {
  carouselOptions = {
    margin: 25,
    nav: true,
    navText: [
      '<div class=\'nav-btn prev-slide\'></div>',
      '<div class=\'nav-btn next-slide\'></div>'
    ],
    responsiveClass: true,
    autoWidth: true,
    autoHeight: true,
    responsive: {
      0: {
        items: 2,
        nav: true
      },
      600: {
        items: 3,
        nav: true
      },
      1000: {
        items: 5,
        nav: true,
        loop: false
      },
      1500: {
        items: 7,
        nav: true,
        loop: false
      }
    }
  };

  public imageObject: Array<object>;

  public showWebcam = true;
  public isWebcamStarted = 0;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<
    boolean | string
  >();

  @BlockUI() blockUI: NgBlockUI;
  @Input() selectedImages: any = [];
  @Input() receivedImages: any = [];
  @Input() fcIndex: any = '';
  @Input() sliderdesign = false;
  @Input() showSliderData = false;
  @Input() label = 'Select Image';
  @Input() folderName: any = '';
  @Input() fileType: any = '';
  @Input() refType: any = 2;
  @Input() multiselectable = false;
  @Input() getDatabaseRef = false;
  @Input() maintainAspectRatio = true;
  @Input() canRemove = false;
  @Input() thumbnails = false;
  @Output() onSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() sendRefId: EventEmitter<any> = new EventEmitter<any>();
  public user: any;
  public uploadedProgressStatus: any  = 0;
  public fileUploadedStatus = 0;
  public uploadSpeed = '';
  public display = false;
  public dataLoaded = false;
  public fileUploaded = true;
  public subFolder = false;
  public imageData: any = [];
  public imageSliderData: any = [];
  public imagesSelected: any = [];
  public imageSliderEnabled = false;
  public croppingFileName: any = '';
  public cropImage: any;
  public croppedImg: any;
  public selectedImage: any = [];
  public folderLocation: any = 'Root';
  public acceptedFileFormat: any = '';
  public allImages: any = [];
  public subFolderImages: any = [];
  public breadcrumb: any = [{ name: 'Home', data: {} }];
  // in app.component.ts
  public files1: File[] = [];

  public options: UploaderOptions;
  public formData: FormData;
  public files: UploadFile[];
  public uploadInput: EventEmitter<UploadInput>;
  public humanizeBytes: Function;
  public dragOver: boolean;
  public query: any;
  UploadProgress
  constructor(
    private changeDetector: ChangeDetectorRef,
    private image: ImageService,
    private storage: WebStorage
  ) {
    this.imagesSelected = [];
    this.files = []; // local uploading files array
    this.imageSliderData = [];
    this.imageObject = [];
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
  }

  onUploadOutput(output: UploadOutput): void {
    // if (!this.blockUI.isActive) {
    //   this.blockUI.start('Uploading File...'); // Start blocking
    // }
    const tempFolderName = this.folderName;
    const tempFileType = this.fileType;
    const token = this.storage.get('webAuthToken');
    const event: UploadInput = {
      type: 'uploadAll',
      url: 'http://134.209.144.113:3000/api/save/image',
      method: 'POST',
      headers: {
        folderName: tempFolderName,
        fileType: tempFileType,
        Authorization: token ? 'bearer ' + token : 'bearer my-auth-token'
       }
    };
    if (output.type === 'allAddedToQueue') {
      this.uploadInput.emit(event);
    } else if (
      output.type === 'uploading' &&
      typeof output.file !== 'undefined'
    ) {
      this.uploadedProgressStatus = output ? (output.file ? (output.file.progress ? (output.file.progress.data ? output.file.progress.data.percentage : null) : null) : null) : '';
      this.uploadSpeed = output ? (output.file ? (output.file.progress ? (output.file.progress.data ? output.file.progress.data.speedHuman : null) : null) : null) : '';
      this.fileUploadedStatus = output ? output.file ? output.file.progress ? output.file.progress.status : 0 : 0 : 0;
      // console.log(event.file)
      console.log(output)
      // console.log(event)
      // console.log(output)
      // console.log(output.type)
      // console.log(UploadProgress.)
      this.fileUploaded = false;
      // setTimeout(() => {
      //   this.blockUI.reset(); // Stop blocking
      // }, 10);
    } else if (output.type === 'done' && typeof output.file !== 'undefined') {
      this.fileUploaded = true;
      const rs = output.file.response;
      if (rs.status === 200) {
        this.imageData.push(rs.data);
      } else {
        alert(rs.message);
      }
      this.getAllImages(this.refType, rs.data);
      // setTimeout(() => {
      //   this.blockUI.reset(); // Stop blocking
      // }, 10);
    }
  }

  // public showPopUp() {
  //     this.selectedImage = [];
  //     // this.display = true;
  //     if (!this.sliderdesign) {
  //         this.openModal(this.imageUploadModal);
  //     }
  //     this.dataLoaded = false;
  //     this.image.getData(this.user, this.folderName).subscribe((result: any) => {
  //         this.dataLoaded = true;
  //         const rs = result.json();
  //         if (rs.status === 200) {
  //             this.imageData = rs.data;
  //             if (this.sliderdesign) {
  //                 this.imgSliderImages(this.imageData);
  //             }
  //         }
  //     });
  // }

  // public select(data: any) {
  //     if (data.type === 'Directory') {
  //         this.subFolder = true;
  //         this.allImages = this.imageData;
  //         this.imageData = [];
  //         this.imageData = data.items;
  //         this.folderLocation = data.name;
  //     } else {
  //         const found = this.selectedImage.find((e: any) => e == data.path);
  //         if (found) {
  //             this.selectedImage.map((e: any, index: any) => {
  //                 if (e == data.path) {
  //                     this.selectedImage.splice(index, 1);
  //                 }
  //             });
  //         } else {
  //             if (!this.multiselectable) {
  //                 this.selectedImage = [];
  //             }
  //             this.selectedImage.push(data.path);
  //         }
  //     }
  // }

  // public rootDir() {
  //     this.subFolder = false;
  //     this.imageData = [];
  //     this.imageData = this.allImages;
  // }

  // public chkImgSelected(img: any) {
  //     return this.selectedImage.find((e: any) => e == img.path);
  // }

  // public selected() {
  //     let images: any = [];
  //     if (this.multiselectable) {
  //         if (this.utills.notEmpty(this.selectedImages)) {
  //             this.selectedImages.map((e: any) => {
  //                 images.push(e);
  //             });
  //         }

  //         this.selectedImage.map((e: any) => {
  //             if (!images.find((x: any) => x == e)) {
  //                 images.push(e);
  //             }
  //         });
  //     } else {
  //         images = this.selectedImage;
  //     }
  //     this.selectedImages = Object.assign({}, this.selectedImage);
  //     const data = { path: this.selectedImage, index: this.fcIndex };
  //     this.onSelected.emit(data);
  //     this.modalRef.hide();
  // }

  // public removeFromSelectedList(id: any) {
  //     this.selectedImages.splice(id, 1);
  //     const data = { path: this.selectedImages, index: this.fcIndex };
  //     this.onSelected.emit(data);
  // }

  // public remove() {
  //     this.blockUI.start('Checking Image Usage...'); // Start blocking
  //     if (this.utills.notEmpty(this.selectedImage)) {
  //         this.product.checkImageUsage({'list': this.selectedImage}).subscribe((result1: any) => {
  //             const rs1 = result1.json();
  //             if (rs1.status == 200 || rs1.status === 200) {
  //                 this.blockUI.start('Removing Image...'); // Start blocking
  //                 this.user.folder_name = this.folderName;
  //                 this.image.remove(this.user, this.selectedImage.map((item: any) => {
  //                     const arr = item.split('/'); return arr[arr.length - 1]; })).subscribe((result: any) => {
  //                     const rs = result.json();
  //                     const removePath = this.selectedImage.path;
  //                     if (rs.status == 200) {
  //                         this.toaster.success(rs.message);
  //                         this.selectedImage.map((item: any) => {
  //                             const selectedImgIndex = this.selectedImages.indexOf(item);
  //                             this.imageData.map((itm: any, index: any) => {
  //                                 if (itm.path == item) {
  //                                     this.imageData.splice(index, 1);
  //                                 }
  //                             });
  //                             if (selectedImgIndex >= 0) {
  //                                 this.selectedImages.splice(selectedImgIndex, 1);
  //                             }
  //                         });
  //                         this.selectedImage = [];
  //                     }
  //                     setTimeout(() => {
  //                         this.blockUI.stop(); // Stop blocking
  //                     }, 10);
  //                 });
  //             }else{
  //                 this.toaster.warning(rs1.message);
  //                 setTimeout(() => {
  //                     this.blockUI.stop(); // Stop blocking
  //                 }, 10);
  //             }
  //         });
  //     }
  // }

  public ngOnInit(): void {
    // this.folderName = Math.floor(Date.now() / 1000);
    // console.log(this.receivedImages);
    
    if (this.fileType == 'Videos' || this.fileType === 'Videos') {
      this.acceptedFileFormat = '.mp4, .m4a, .m4v, .mov, .webm, .flv, .avi, .3gp';
    } else if (this.fileType == 'Images' || this.fileType === 'Images') {
      this.acceptedFileFormat = '.jpg, .jpeg, .png, .apng, .webp';
    } else {
      this.acceptedFileFormat = '*/*';
    }
    this.getAllImages(1);
  }

  // public getTempImageDir() {
  //     this.image.getTempImageFolder(this.user, this.folderName).subscribe((result: any) => {
  //         this.dataLoaded = true;
  //         if (result.status === 200) {
  //             this.folderName = result.data.abc;
  //         }
  //     });
  // }

  public getAllImages(type1: any, recData?: any) {
    // if (this.showSliderData) {
      this.blockUI.start('Loading Image...'); // Start blocking
      this.imageObject = [];
      this.image.getData(this.user, this.folderName).subscribe((result: any) => {
        this.dataLoaded = true;
        if (result.status === 200) {
          this.imageData = result.data;
          const data = [];
          this.imageData.sort(function(x, y) {
            return x.modified - y.modified;
          });
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < this.imageData.length; i++) {
            this.imageObject.push({
              path: this.imageData[i].path,
              extension: this.imageData[i].extension
            });
            data.push(this.imageData[i].path);
          }
          console.log(this.receivedImages)
          if (this.receivedImages.length > 0) {
            for (let i = 0; i < this.receivedImages.length; i++) {
              const element = this.receivedImages[i];
              let fileExtension = element.split('.');
              fileExtension = fileExtension[fileExtension.length - 1];
              this.imageObject.push({
                path: element,
                extension: fileExtension
              });
              data.push(element);
            }
          }
          console.log(this.imageObject)
          console.log(this.showSliderData)
          const tempObj = {
            type: type1,
            ref: recData,
            imagesData: data
          };
          this.onSelected.emit(tempObj);
          // this.sendRefId()
        }
        setTimeout(() => {
          this.blockUI.reset(); // Stop blocking
        }, 10);
      });
    // } else {
    //   this.onSelected.emit(type);
    // }
  }

  // public openModal(template: TemplateRef<any>) {
  //     this.modalRef = this.modalService.show(template);
  // }

  // public crop(imageUrl: any) {
  //     const imagePath = imageUrl ? imageUrl : this.selectedImage[0];
  //     if (imagePath) {
  //         this.cropImage =  imagePath;
  //         this.croppingFileName = imagePath;
  //         this.getImageToBase64(imagePath);
  //     } else {
  //         this.toaster.warning('No Image Selected');
  //     }
  // }

  // public getImageToBase64(imageUrl: any) {
  //     const self = this;
  //     self.blockUI.start('Loading Image for Cropping...'); // Start blocking
  //     if (!this.sliderdesign) {
  //         self.modalRef.hide();
  //     }
  //     self.image.getImageToBase64(imageUrl).subscribe(function (result) {
  //         const rs = result.json();
  //         if (rs.status === 200) {
  //             self.cropImage = rs.data;
  //             self.modalRef = self.modalService.show(self.imageCropModal);
  //             // self.onSuccess.emit(self.generatedIcons);
  //         } else {
  //             self.toaster.error(result.message);
  //         }
  //         setTimeout(() => {
  //             self.blockUI.stop(); // Stop blocking
  //         }, 10);
  //     });
  // }

  // imageCropped(event: ImageCroppedEvent) {
  //     this.croppedImg = event.base64;
  //     // this.toaster.success('Image Cropper!!!!!!');
  // }

  // public croppedImage() {
  //     const self = this;
  //     self.blockUI.start('Saving Cropped Image . . .'); // Start blocking
  //     const user = self.storage.get('businessadminUser');
  //     const data = {
  //         croppingFileName: self.croppingFileName,
  //         croppedImageBase64: self.croppedImg,
  //         folderName: self.folderName,
  //         croppedImage: false
  //     };
  //     self.image.saveCroppedImage(user.id, data).subscribe(function (result) {
  //         const rs = result.json();
  //         if (rs.status === 200) {
  //             self.toaster.success('Image Cropped Successfully');
  //             self.imageData = [];
  //             self.modalRef.hide();
  //             self.showPopUp();
  //         } else {
  //             self.toaster.error(result.message);
  //         }
  //         setTimeout(() => {
  //             self.blockUI.stop(); // Stop blocking
  //         }, 10);
  //     });
  // }

  // public cancelCrop() {
  //     const self = this;
  //     self.modalRef.hide();
  //     self.showPopUp();
  // }

  // // Image Crop Functions for testing
  // imageLoaded() {
  //     // show cropper
  //     // this.toaster.success('Image Loaded');
  // }

  // cropperReady() {
  //     // cropper ready
  //     // this.toaster.success('Image Cropper Ready');
  // }

  // loadImageFailed() {
  //     // show message
  //     // this.toaster.warning('Image Loading Failed');
  // }

  // // Capture images from Camera!
  // public saveWebcamImage() {
  //     const self = this;
  //     const user = self.storage.get('businessadminUser');
  //     const data = {
  //         croppingFileName: self.croppingFileName,
  //         croppedImageBase64: self.webcamImageDataURI,
  //         folderName: self.folderName,
  //         croppedImage: false
  //     };
  //     self.image.saveCroppedImage(user.id, data).subscribe(function (result) {
  //         const rs = result.json();
  //         if (rs.status === 200) {
  //             self.toaster.success('Image Captured Successfully');
  //             self.imageData = [];
  //             self.modalRef.hide();
  //             self.showPopUp();
  //         } else {
  //             self.toaster.error(result.message);
  //         }
  //         // setTimeout(() => {
  //         //     self.blockUI.stop(); // Stop blocking
  //         // }, 10);
  //     });
  // }

  // public triggerSnapshot(): void {
  //     this.trigger.next();
  //   }

  //   public toggleWebcam(): void {
  //     this.showWebcam = !this.showWebcam;
  //   }

  //   public handleInitError(error: WebcamInitError): void {
  //     this.errors.push(error);
  //   }

  //   public showNextWebcam(directionOrDeviceId: boolean|string): void {
  //     // true => move forward through devices
  //     // false => move backwards through devices
  //     // string => move to device with given deviceId
  //     this.nextWebcam.next(directionOrDeviceId);
  //   }

  //   public handleImage(webcamImage: WebcamImage): void {
  //     // console.warn('received webcam image', webcamImage);
  //     this.webcamImageDataURI = webcamImage.imageAsDataUrl;
  //     this.webcamImage = webcamImage;
  //   }

  //   public cameraWasSwitched(deviceId: string): void {
  //     // console.log('active device: ' + deviceId);
  //     this.deviceId = deviceId;
  //   }

  //   public get triggerObservable(): Observable<void> {
  //     return this.trigger.asObservable();
  //   }

  //   public get nextWebcamObservable(): Observable<boolean|string> {
  //     return this.nextWebcam.asObservable();
  //   }

  //   // Image Slider object Generation
  //   public imgSliderImages(data: any) {
  //     // console.log(data);
  //     this.imageSliderEnabled = false;
  //     this.imageObject = [];
  //     this.image.getData(this.user, this.folderName).subscribe((result: any) => {
  //         this.dataLoaded = true;
  //         const rs = result.json();
  //         if (rs.status === 200) {
  //             this.imageData = rs.data;
  //             for (let i = 0; i < this.imageData.length; i++) {
  //                 for (let k = 0; k < this.selectedImages.length; k++) {
  //                     if (this.imageData[i].path === this.selectedImages[k]) {
  //                         this.imagesSelected['image' + i] = true;
  //                         this.selectedImage.push(this.selectedImages[k]);
  //                     } else if (this.imagesSelected['image' + i] === true) {
  //                         break;
  //                     } else {
  //                         this.imagesSelected['image' + i] = false;
  //                     }
  //                 }
  //                 if (this.imageData[i].type === 'File') {
  //                     this.imageObject.push(this.imageData[i].path);
  //                 }
  //                 if (i === (this.imageData.length - 1)) {
  //                     setTimeout(() => {
  //                         this.imageSliderEnabled = true;
  //                         this.pushAllImages();
  //                     }, 10);
  //                 }
  //             }
  //             if (!(this.imageData.length > 0)) {
  //                 this.imageSliderEnabled = true;
  //                 this.pushAllImages();
  //             }
  //             // console.log(this.imagesSelected);
  //         }
  //     });
  //   }

  public removeImage(data: any) {
    this.blockUI.start('Removing Image . . .'); // Start blocking
    const tempArr = [];
    const tempDirName = this.folderName;
    const fileNameArray = data.split('/');
    tempArr.push(fileNameArray[fileNameArray.length - 1]);
    this.image.remove(tempDirName, tempArr).subscribe((result: any) => {
      if (result.status === 200) {
        this.getAllImages(1);
      }
      setTimeout(() => {
        this.blockUI.stop(); // Stop blocking
      }, 10);
    });
  }

  //   public imageClick(position: any) {
  //     if (this.imagesSelected['image' + position]) {
  //         let tempIndex = -1;
  //         for ( let i = 0; i < this.selectedImage.length; i++ ) {
  //             if (this.imageObject[position] === this.selectedImage[i]) {
  //                 tempIndex = i;
  //                 break;
  //             }
  //         }
  //         if (tempIndex !== -1) {
  //             this.selectedImage.splice(tempIndex, 1);
  //         }
  //     } else {
  //         let dataFound = false;
  //         for ( let i = 0; i < this.selectedImage.length; i++ ) {
  //             if (this.imageObject[position] === this.selectedImage[i]) { dataFound = true; }
  //             break;
  //         }
  //         if (!dataFound) { this.selectedImage.push(this.imageObject[position]); }
  //     }
  //     this.imagesSelected['image' + position] = this.imagesSelected['image' + position] ? false : true;
  //     const data = { path: this.selectedImage, index: this.fcIndex };
  //     this.onSelected.emit(data);
  //     // console.log(this.imagesSelected);
  //     // console.log(this.selectedImage);
  //     // console.log(position);
  // }

  // public pushImageToSlider(path: any) {
  // this.imageObject.push(path);
  // this.imagesSelected['image' + this.imageObject.length] = false;
  // this.pushAllImages();
  // }

  public pushAllImages() {
    const data = { path: this.imageObject, index: this.fcIndex };
    this.onSelected.emit(data);
    // console.log(data);
  }

  // public onSelect(event) {
  //     console.log(event);
  //     // this.files1.push(...event.addedFiles);
  // }

  // public onRemove(event) {
  //     console.log(event);
  //     // this.files1.splice(this.files1.indexOf(event), 1);
  // }

  public isImage(fileExtension: any) {
    console.log(fileExtension)
    // tslint:disable-next-line: max-line-length
    if (fileExtension === 'jpg' || fileExtension === 'jpeg' || fileExtension === 'png' || fileExtension === 'apng' || fileExtension === 'webp') {
      return true;
    } else {
      return false;
    }
  }
}
