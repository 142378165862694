import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpResponse } from "../core/http.model";
import { HttpClientGlobal } from "../core/httpclient";

@Injectable()
export class LoginService {
  constructor(private httpClient: HttpClientGlobal) {}

  // getUsers(): Observable<HttpResponse> {
  //   return this.httpClient.get('/api/getUsers', {});
  // }

  // saveUsers(data: any): Observable<HttpResponse> {
  //   return this.httpClient.post('/api/saveUsers', data);
  // }

  login(data: any): Observable<HttpResponse> {
    return this.httpClient.post("/api/admin/login", data);
  }

  saveLogin(data: any): Observable<HttpResponse> {
    return this.httpClient.post("/api/admin/logingenerate", data);
  }

  getAdminLists(): Observable<HttpResponse> {
    return this.httpClient.get("/api/admin/list", {});
  }

  saveAppAdmin(data: any): Observable<HttpResponse> {
    return this.httpClient.post("/api/admin/applogingenerate", data);
  }

  getAppAdminLists(): Observable<HttpResponse> {
    return this.httpClient.get("/api/admin/applist", {});
  }

  getAppUsers(): Observable<HttpResponse> {
    return this.httpClient.get("/api/app/userlist", {});
  }

  updateStatus(data: any): Observable<HttpResponse> {
    return this.httpClient.post("/api/admin/updatestatus", data);
  }

  updateAppUserStatus(data: any): Observable<HttpResponse> {
    return this.httpClient.post("/api/app/userloginupdatestatus", data);
  }

  updateAppAdminStatus(data: any): Observable<HttpResponse> {
    return this.httpClient.post("/api/admin/appadminupdatestatus", data);
  }

  searchUsername(data: any): Observable<HttpResponse> {
    return this.httpClient.post("/api/user/searchquery", data);
  }

  resetPass(data: any): Observable<HttpResponse> {
    return this.httpClient.post("/api/user/forgetpassrequest", data);
  }

  checkpassresetkey(data: any): Observable<HttpResponse> {
    return this.httpClient.post("/api/user/checkpassresetkey", data);
  }

  updatePassword(data: any): Observable<HttpResponse> {
    return this.httpClient.post("/api/user/updatePassword", data);
  }

  changePassword(data: any): Observable<HttpResponse> {
    return this.httpClient.post("/api/user/changepassword", data);
  }

  deleteStaff(data: any): Observable<HttpResponse> {
    return this.httpClient.post("/api/admin/delete", data);
  }

  deleteAdmin(data: any): Observable<HttpResponse> {
    return this.httpClient.post("/api/appadmin/delete", data);
  }

  deleteAppUser(id: any): Observable<HttpResponse> {
    return this.httpClient.post("/api/appadmin/deleteAppUser", { id });
  }
  // resendOtp(): Observable<HttpResponse> {
  //   return this.httpClient.post('/api/user/resendOtp', {});
  // }

  // otpVerify(data: any): Observable<HttpResponse> {
  //   return this.httpClient.post('/api/user/otpVerify', data);
  // }
}
