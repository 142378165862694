import { Component, OnInit } from '@angular/core';
import { categoryDB } from '../../../../shared/tables/category';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CategoryService } from '../../../../services/category.service';
import { finalize } from 'rxjs/operators';
// Import BlockUI decorator & optional NgBlockUI type
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventService } from 'src/app/services/event.service';
import { LocationService } from 'src/app/services/location.service';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { LocationCategoriesService } from 'src/app/services/location_categories.service';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss'],
  providers: [LocationService, LocationCategoriesService]
})
export class LocationsListComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  tempData: any;

  public categoryData = [];
  // @BlockUI() blockUI: NgBlockUI;
  public closeResult: string;
  public categories = [];
  public categoriesChecked = [];
  public tempArr = [];
  categoryForm: FormGroup;
  datatableDataPresent = false;
  data: any;
  singleEvent: any;
  dataPresent = false;
  broadcastIndex: any;
  showModal = false;
  public title = 'AGM project';
  public latitude: number;
  public longitude: number;
  public zoom: number;

  public imageId = '';
  public imageStatus = '';
  constructor(
    private modalService: NgbModal,
    private locationsService: LocationService,
    private locationCategoryService: LocationCategoriesService,
    private formBuilder: FormBuilder,
    private toastr: ToastrService
    ) {
      this.getLocationCat();
    }

    public getLocationCat() {
      this.blockUI.start('Retrieving Location Categories...'); // Start blocking
      const self = this;
      self.locationCategoryService
        .getLocCatList()
        .pipe(
          finalize(() => {
            setTimeout(() => {
              self.blockUI.stop(); // Stop blocking
            }, 1000);
          })
        )
        .subscribe(httpresponse => {
          if (httpresponse.status === 200) {
            self.tempData = httpresponse.data;
            self.categoryData = self.tempData;
          } else {
            // self.toastr.warning('Categories Not Found');
          }
        });
    }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  ngOnInit() {
    this.dtOptions = {
      paging: true,
      retrieve: true,
      searching: true,
      responsive: true,
      pageLength: 5,
      lengthMenu: [5, 10, 25, 50, 75, 100],
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search in table'
      },
      order: [[
        0, 'desc'
      ]]
    };
    this.getAll();
    // this.setCurrentLocation();
  }
   // Get Current Location Coordinates
   private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 15;
      });
    }
  }
  public getAll() {
    this.blockUI.start('Loading. . .'); // Start blocking
    const self = this;
    self.locationsService
      .getLocations()
      .pipe(
        finalize(() => {
          setTimeout(() => {
            self.blockUI.stop(); // Stop blocking
          }, 1000);
        })
      )
      .subscribe(httpresponse => {
        self.data = httpresponse.data;
        self.tempArr = self.data;
        // console.log(self.tempArr)
        // console.log(self.categoryData)
        // tslint:disable-next-line: prefer-for-of
        for (let i = 0; i < self.tempArr.length; i++) {
          const element = self.tempArr[i];
          // tslint:disable-next-line: prefer-for-of
          for (let j = 0; j < this.categoryData.length; j++) {
            const element1 = this.categoryData[j];
            if (parseInt(element.category, 0) === element1.id) {
              this.categoriesChecked['checked' + element.id] = element1.title;
            }
          }
        }
        // console.log(this.categoriesChecked)
        this.datatableDataPresent = true;
        setTimeout(() => {
          this.dtTrigger.next();
        }, 1000);
      });
  }

  public view(id: any, index: any) {
    this.singleEvent = this.data[index];
    this.broadcastIndex = index;
    this.showModal = true;
  }

  public updateStatus(eventid: any, index: any) {
    this.blockUI.start('Updating Status. . .'); // Start blocking
    const eventData = {
      id: eventid,
      status: this.data[index].is_approved === 1 ? 0 : 1
    };
    const self = this;
    self.locationsService
    .updateLocationStatus(eventData)
    .pipe(
      finalize(() => {
        setTimeout(() => {
          self.blockUI.stop(); // Stop blocking
        }, 1000);
      })
    )
    .subscribe(httpresponse => {
      if (httpresponse.status === 200) {
        if (httpresponse.status === 200) {
          this.data[index].is_approved = httpresponse.data;
          this.toastr.success('Location Status Updated Successfully!');
        } else {
          this.toastr.warning('Something Went Wrong');
        }
        // this.getAll();
      } else {
        console.log('Error');
      }
    });
  }


  public jsonparse(data: any) {
    // console.log(data);
    return JSON.parse(data);
  }

  checkCat(id: any) {
    for (let i = 0; i < this.categoryData.length; i++) {
      const element = this.categoryData[i];
      if (this.categoryData[i].value == id || this.categoryData[i].value === id) {
        return this.categoryData[i].name;
      }
    }
  }

  public deleteLocation(eventid: any, index: any) {
    this.blockUI.start('Deleting. . .'); // Start blocking
    const eventData = {
      id: eventid
    };
    const self = this;
    self.locationsService
    .deleteLocation(eventData)
    .pipe(
      finalize(() => {
        setTimeout(() => {
          self.blockUI.stop(); // Stop blocking
        }, 1000);
      })
    )
    .subscribe(httpresponse => {
      if (httpresponse.status === 200) {
        if (httpresponse.status === 200) {
          this.imageId = '';
          this.imageStatus = '';
          this.modalService.dismissAll();
          this.getAll();
          this.toastr.success('Location Deleted Successfully!');
        } else {
          this.toastr.warning('Something Went Wrong');
        }
        // this.getAll();
      } else {
        console.log('Error');
      }
    });
  }

  public tempDelete(id: any, status: any) {
    this.imageId = id;
    this.imageStatus = status;
  }

}
