import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { HttpResponse } from './http.model';
import { environment } from 'src/environments/environment';
import { WebStorage } from './web.storage';

@Injectable()
export class HttpClientGlobal {
  public httpResponse: HttpResponse[];
  constructor(
      private httpClient: HttpClient,
      private storage: WebStorage
    //   private credentialsService: CredentialsService
      ) {}

  get(path: any, data: any, fullUrl?: any) {
    fullUrl = fullUrl || false;
    const token = this.storage.get('webAuthToken');
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: token ? 'bearer ' + token : 'bearer my-auth-token'
      })
    };
    const reqUrl = (fullUrl) ? path : environment.apiUrl + '' + path;
    // const reqUrl = (fullUrl) ? path : 'http://134.209.144.113:3000' + path;
    // const reqUrl = (fullUrl) ? path : 'http://localhost:3000' + path;
    return this.httpClient
      .get(reqUrl, httpOptions)
      .pipe(
        map((body: any) => body),
        catchError(() => of('Error, could not load joke :-('))
      );
  }

  post(path: any, data: any, fullUrl?: any) {
    fullUrl = fullUrl || false;
    const token = this.storage.get('webAuthToken');
    const httpOptions = {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        Authorization: token ? 'bearer ' + token : 'bearer my-auth-token'
      })
    };
    const reqUrl = (fullUrl) ? path : environment.apiUrl + '' + path;
    // const reqUrl = (fullUrl) ? path : 'http://localhost:3000' + path;
    // const reqUrl = (fullUrl) ? path : 'http://134.209.144.113:3000' + path;
    return this.httpClient
      .post(reqUrl, data, httpOptions)
      .pipe(
        map((body: any) => (this.httpResponse = body)),
        catchError(() => of('Error, could not load joke :-('))
      );
  }
}
