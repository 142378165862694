import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MastersService } from 'src/app/services/masters.service';
// import { LoginService } from 'src/app/services/login.service';
// import { SectorService } from 'src/app/services/sector.service';
@Component({
  selector: 'app-ticket-category-master',
  templateUrl: './ticket-category-master.component.html',
  styleUrls: ['./ticket-category-master.component.scss']
})
export class TicketCategoryMasterComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
  onKeyPress(event: KeyboardEvent) {
    // Get the key code of the pressed key
    const input = event.target as HTMLInputElement;
    const inputValue = input.value;
    const keyCode = event.keyCode;

    // If the input is empty and the key pressed is a space, prevent it
    if (inputValue === '' && keyCode === 32) {
      event.preventDefault();
    }

    // Check if the key is a special symbol and prevent it
    if ((keyCode >= 33 && keyCode <= 47) || // ! to /
        (keyCode >= 58 && keyCode <= 64) || // : to @
        (keyCode >= 91 && keyCode <= 96) || // [ to `
        (keyCode >= 123 && keyCode <= 126)) { // { to ~
      event.preventDefault();
    }
  }
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  ticketCategoryForm: any;
  // categories: any;
  constructor(
    // private notificationsService: NotificationsService,
    private modalService: NgbModal,
    // private loginService: LoginService,
    private formBuilder: FormBuilder,
    // private sectorService: SectorService
    private toastr: ToastrService,
    private masterservice: MastersService,
    ) {
      // this.categories = categoryDB.category;
      this.ticketCategoryForm = this.formBuilder.group({
        id:'',
        ticket_type_name : ['', [Validators.required]],
    }
  );
  }
  public closeResult: string;
  public sectorData: any;
  matchUserDetails: any;
  userData: any;
  data: any;
  singleEvent: any;
  deleteId: any;
  dataPresent = false;
  datatableDataPresent = true;
  isUpdate:boolean = false;
  isSubmitting: boolean = false;

  ngOnInit() {
    this.dtOptions = {
      paging: true,
      retrieve: true,
      searching: true,
      responsive: true,
      pageLength: 5,
      lengthMenu: [5, 10, 25, 50, 75, 100],
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search in table'
      },
      order: [[
        0, 'asc'
      ]]
    };
    // this.getSectorsList();
    this.getTicketCategoryList();
  }

  getTicketCategoryList(){
    this.blockUI.start('Retrieving Ticket List...'); // Start blocking
    const postObj={
      "module": "ticket_category_masters"
    }
    this.masterservice.getMasterData(postObj)
      .pipe(finalize(() => { this.blockUI.stop(); }))
      .subscribe((response:any) => {
        if (response) {
          this.data = response.data;
          // this.toastr.success('Ticket Data Loaded.');
          if (this.dtElement && this.dtElement.dtInstance) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.dtTrigger.next();
            });
          } else {
            this.dtTrigger.next();
          }
        } else {
          console.log("No response");
        }
      })
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
    private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  submitForm(){
    // debugger;
    this.isSubmitting = true; // Add this line

    if (this.ticketCategoryForm.valid) {
      const postObj={
        "modelName": "ticket_category_masters",
        "inputData": {
            "category_name": this.ticketCategoryForm.value.ticket_type_name,
            "is_active": 1
        }
    }
    // console.log(postObj);
    this.masterservice.postFormData(postObj).subscribe((response:any)=>{
      if(response.code == 0 || response.status == 500){
        // console.log("No Response", response.message.errors[0].message);
        console.log("No Response", response);
        this.toastr.error(response.message);
        this.isSubmitting = false; // Add this line

      }else{
        console.log("Ticket Type Created");
        this.toastr.success("Ticket Type Created Successfully");
        this.modalService.dismissAll();
        this.getTicketCategoryList();
        this.resetForm();
        this.isSubmitting = false; // Add this line

      }
    })
    }else{
      this.ticketCategoryForm.markAllAsTouched();
      this.isSubmitting = false; // Add this line

    }
  }
  updateForm(){
    this.isSubmitting = true; // Add this line

    if (this.ticketCategoryForm.valid) {
    const postObj={
      "modelName": "ticket_category_masters",
      "id": this.ticketCategoryForm.value.id,   
      "inputData": {
          "category_name": this.ticketCategoryForm.value.ticket_type_name,
          "isActive": 1,
      }
    }
    // console.log(postObj);
    // return
    this.masterservice.postFormData(postObj).subscribe((response:any)=>{
      if(response.code == 0 || response.status == 500){
        console.log("No Response", response);
        this.toastr.error(response.message);
        this.isSubmitting = false; // Add this line
      }
      else{
        console.log("Ticket Category Updated Succesfully", response);
        this.toastr.success("Ticket Category Update Successfully");
        this.modalService.dismissAll();
        this.getTicketCategoryList();
        this.resetForm();
        this.isUpdate = false; 
        this.isSubmitting = false;
      }
    })
    }else{
      this.ticketCategoryForm.markAllAsTouched();
      this.isSubmitting = false; // Add this line

    }
  }
  resetForm(){
    this.ticketCategoryForm.reset();
    this.modalService.dismissAll();
    this.isUpdate = false;
  }
  viewUserDetails(data:any){
    // console.log(data);
    this.isUpdate = true;
    this.ticketCategoryForm.patchValue({
      id: data.id,
      ticket_type_name: data.category_name,
    });
  }
}
