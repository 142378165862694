import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { finalize } from 'rxjs/operators';
import { LocationService } from 'src/app/services/location.service';
import { ToastrService } from 'ngx-toastr';
import { LocationCategoriesService } from 'src/app/services/location_categories.service';
@Component({
  selector: 'app-locations',
  templateUrl: './add-locations.component.html',
  styleUrls: ['./add-locations.component.scss'],
  providers: [LocationService, LocationCategoriesService]
})
export class AddLocationsComponent implements OnInit {

  public title = 'AGM project';
  public latitude: number;
  public longitude: number;
  public zoom: number;

  @BlockUI() blockUI: NgBlockUI;
  public productForm: FormGroup;
  data: any;
  tempData: any;

  public categoryData = [];
  // public categoryData = [
  //   {
  //       name: 'Restaurants and Bar',
  //       value: '1'
  //   },
  //   {
  //       name: 'Gymnasium',
  //       value: '2'
  //   },
  //   {
  //       name: 'Salons and Beauty',
  //       value: '3'
  //   },
  //   {
  //       name: 'SPark / Kids Play',
  //       value: '4'
  //   },
  //   {
  //       name: 'Grocery Store / Super Market',
  //       value: '5'
  //   },
  //   {
  //       name: 'Civic Center / Fire Station',
  //       value: '6'
  //   },
  //   {
  //       name: 'Movies & Entertainment',
  //       value: '7'
  //   },
  //   {
  //       name: 'Hospitals',
  //       value: '8'
  //   },
  //   {
  //       name: 'Car / Bike Care',
  //       value: '9'
  //   }
  // ];

  public categoryConfig = {
    displayKey: 'title',
    search: true,
    height: 'auto',
    placeholder: 'Select Category',
    noResultsFound: 'No results found!',
    searchPlaceholder: 'Enter Category Name',
    searchOnKey: 'title'
  };

  constructor(
    private fb: FormBuilder,
    private locationService: LocationService,
    private locationCategoryService: LocationCategoriesService,
    private router: Router,
    private toastr: ToastrService
  ) {
    this.productForm = this.fb.group({
      category: ['', [Validators.required]],
      location: ['', [Validators.required]],
      description: ['', [Validators.required]],
      title: ['', [Validators.required]]
    });
    this.getLocationCat();
  }

  public getLocationCat() {
    this.blockUI.start('Retrieving Location Categories...'); // Start blocking
    const self = this;
    self.locationCategoryService
      .getAppLocCatList()
      .pipe(
        finalize(() => {
          setTimeout(() => {
            self.blockUI.stop(); // Stop blocking
          }, 1000);
        })
      )
      .subscribe(httpresponse => {
        if (httpresponse.status === 200) {
          self.tempData = httpresponse.data;
          self.categoryData = self.tempData;
        } else {
          self.toastr.warning('Please try again later');
          self.router.navigate(['/locations/list']);
        }
      });
  }

  ngOnInit() {
    this.setCurrentLocation();
  }
   // Get Current Location Coordinates
   private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = 18.51670171432715;
        this.longitude = 73.94193872596429;
        this.zoom = 15;
      });
    }
  }

  markerDragEnd(event: any) {
    console.log(event);
    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;
  }

  public selectCategory(event: any) {
    if (event.value !== undefined || event.value !== '') {
      this.productForm.controls.category.patchValue(event.value.id);
    }
  }

  onSubmit(): void {
    const locationCo = {
      latitude: this.latitude ? this.latitude : 18.51670171432715,
      longitude: this.longitude ? this.longitude : 73.94193872596429,
    };
    this.productForm.controls.location.patchValue(JSON.stringify(locationCo));
    // console.log(this.productForm.value);
    this.blockUI.start('Saving...'); // Start blocking
    if (this.validateForm()) {
      const self = this;
      self.locationService
        .saveLocation(this.productForm.value)
        .pipe(
          finalize(() => {
            setTimeout(() => {
              self.blockUI.stop(); // Stop blocking
            }, 1000);
          })
        )
        .subscribe(httpresponse => {
          if (httpresponse.status === 200) {
            this.router.navigate(['/locations/list']);
            this.toastr.success('Location Added Successfully!');
          } else {
            this.toastr.warning('Something Went Wrong');
          }
        });
    }
  }

  public validateForm() {
    return true;
  }

}
