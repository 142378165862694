import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";
import { BlockUI, NgBlockUI } from "ng-block-ui";
import { finalize } from "rxjs/operators";
import { SectorService } from "src/app/services/sector.service";
import { EventService } from "src/app/services/event.service";
import { TicketService } from "src/app/services/ticket.service";
import { ToastrService } from "ngx-toastr";
import { MastersService } from "src/app/services/masters.service";
import { environment } from 'src/environments/environment'; // Import the environment variable
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: "app-view-ticket",
  templateUrl: "./view-ticket.component.html",
  styleUrls: ["./view-ticket.component.scss"],
  providers: [SectorService, EventService, TicketService],
})
export class ViewTicketComponent implements OnInit {
  public title: string = "AGM project";
  public latitude: number;
  public longitude: number;
  public zoom: number;
  statusForStaff: boolean = true;
  @BlockUI() blockUI: NgBlockUI;
  public productForm: FormGroup;
  public counter = 1;
  public categories = [];
  public products = [];
  public towerList = [];
  public individualList = [];
  public showSector = false;
  public showTower = false;
  public showIndividual = false;
  public retrieveTowers = false;
  public retrieveIndividuals = false;
  public selectTowerData = false;
  public selectIndividualData = false;
  private folderNameTimestamp: any;
  type: any;
  public ckeConfig = {
    height: 400,
    language: "en",
    allowedContent: true,
    removePlugins: "elementspath",
    resize_enabled: false,
  };
  staffList: any;
  FilteredStaffList: any;
  public showEditor = false;
  public attachmentArr: any;
  public imagesReady = false;
  public id = "";
  retrievedTags: any;
  data: any;
  public eventTypeSelection = true;
  userPropertyData: any;
  userid: any;
  allstatusArr: any[] = [];
  logDetails: any;
  userType: any;
  attachements:any;
  selectedImage:any;
  public closeResult: string;
  priorityList:any;
  public url = [
    {
      img: "assets/images/user.png",
    },
    {
      img: "assets/images/user.png",
    },
    {
      img: "assets/images/user.png",
    },
    {
      img: "assets/images/user.png",
    },
    {
      img: "assets/images/user.png",
    },
  ];

  public radioButtonData = [
    {
      name: "Entire Mangalam",
      value: "1",
    },
    {
      name: "Sector",
      value: "2",
    },
    {
      name: "Tower",
      value: "3",
    },
  ];
  public broadcastType = 1;
  public broadcastName = "";

  public broadcastTo = "";
  public tempData: any;
  public sectorConfig = {
    displayKey: "DESCRIPTION",
    search: true,
    height: "auto",
    placeholder: "Select Sector",
    noResultsFound: "No results found!",
    searchPlaceholder: "Enter Sector Name",
    searchOnKey: "DESCRIPTION",
  };

  public towerConfig = {
    displayKey: "SectorTower",
    search: true,
    height: "auto",
    placeholder: "Select Tower",
    noResultsFound: "No results found!",
    searchPlaceholder: "Enter Tower Name",
    searchOnKey: "SectorTower",
  };

  public individualConfig = {
    displayKey: "individualTower",
    search: true,
    height: "auto",
    placeholder: "Select Individual",
    noResultsFound: "No results found!",
    searchPlaceholder: "Enter Individual Name",
    searchOnKey: "individualTower",
  };

  public ticketViewForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private sectorService: SectorService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private ticketService: TicketService,
    private masterservice: MastersService,
    private modalService: NgbModal,

  ) {
    this.attachmentArr = [];
    this.ticketViewForm = this.fb.group({
      id: [""],
      fname: [],
      lname: [],
      userid: [],
      Subject: [""],
      Description: [""],
      CreatedDate: [],
      LASTUpdateDate: [],
      attachements:[''],
      status: ["", Validators.required],
      ticket_priority_id: ["", Validators.required],
      staff_assign: ["", Validators.required],
      comment: ["", Validators.required],
      comment_type: ["", Validators.required],
    });

    const date = new Date(); // 2009-11-10
    const month = date.toLocaleString("default", { month: "short" });
    console.log("webUserData--", localStorage.getItem("webUserData"));
    var webUserData = localStorage.getItem("webUserData");
    // console.log("we");

    var userData = JSON.parse(webUserData);
    this.userid = userData.value.id;
    console.log("User ID:", this.userid);

    const dateNo = date.getDate();
    const RandNo = Math.floor(Date.now() / 1000);
    this.folderNameTimestamp = month + "-" + dateNo + "-" + RandNo;
    console.log(userData.value.admin_type);
    this.userType = userData.value.admin_type;

    this.statusList(userData.value.admin_type);
  }
  open(content,imageUrl: string) {
    this.selectedImage = imageUrl;

    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'sm' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
    private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  statusList(is_admin: number) {
    if (is_admin === 2) {
      this.allstatusArr = [
        { value: "WIP", text: "Work In Progress" },
        { value: "Closed", text: "Closed" },
        { value: "Completed", text: "Completed" },
        { value: "Hold", text: "Hold" },
        { value: "Reject", text: "Rejected" },
      ];
      this.ticketViewForm.get("ticket_priority_id").clearValidators();
      this.ticketViewForm.get("ticket_priority_id").updateValueAndValidity();
    } else {
      this.allstatusArr = [
        { value: "Pending", text: "Pending" },
        { value: "WIP", text: "Work In Progress" },
        { value: "Closed", text: "Closed" },
        { value: "Completed", text: "Completed" },
        { value: "Hold", text: "Hold" },
        { value: "Reject", text: "Rejected" },
      ];
    }
  }

  increment() {
    this.counter += 1;
  }

  decrement() {
    this.counter -= 1;
  }

  // FileUpload
  readUrl(event: any, i) {
    if (event.target.files.length === 0) {
      return;
    }
    // Image upload validation
    const mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (_event) => {
      this.url[i].img = reader.result.toString();
    };
  }

  public checkBroadType(id) {
    switch (id) {
      case 1 || "1":
        return "Mangalam";
      case 2 || "2":
        this.eventTypeSelection = false;
        return "Sector";
      case 3 || "3":
        this.eventTypeSelection = false;
        return "Tower";
      case 4 || "4":
        this.eventTypeSelection = false;
        return "Individual";
      default:
        break;
    }
  }

  public checkToName(id: any, data: any) {
    let tempArr = "";
    switch (id) {
      case 1 || "1":
        return "All Mangalam";
      case 2 || "2":
        this.tempData = JSON.parse(data.sectors);
        for (let i = 0; i < this.tempData.length; i++) {
          const element = this.tempData[i];
          tempArr += element.DESCRIPTION;
          if (i + 1 !== this.tempData.length) {
            tempArr += ", ";
          }
        }
        return tempArr;
      case 3 || "3":
        this.tempData = JSON.parse(data.towers);
        for (let i = 0; i < this.tempData.length; i++) {
          const element = this.tempData[i];
          tempArr += element.DESCRIPTION;
          if (i + 1 !== this.tempData.length) {
            tempArr += ", ";
          }
        }
        return tempArr;
      case 4 || "4":
        this.tempData = JSON.parse(data.individuals);
        for (let i = 0; i < this.tempData.length; i++) {
          const element = this.tempData[i];
          tempArr += element.LOGINID;
          if (i + 1 !== this.tempData.length) {
            tempArr += ", ";
          }
        }
        return tempArr;
      default:
        break;
    }
  }
  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get("id");
    this.type = this.route.snapshot.paramMap.get("type");
    console.log("type-->", this.type);
    this.blockUI.start("Loading. . ."); // Start blocking
    const self = this;
    self.ticketService
      .editData(this.id)
      .pipe(
        finalize(() => {
          setTimeout(() => {
            self.blockUI.stop(); // Stop blocking
          }, 1000);
        })
      )
      .subscribe((httpresponse: any) => {
        console.log("httpresponse", httpresponse);
        if (httpresponse.status === 200) {
          this.data = httpresponse.data[0];
          this.userPropertyData = httpresponse.user_details[0];
          this.logDetails = httpresponse.log;
          this.attachements = httpresponse.attachment.map(att => ({
            ...att,
            file_name: `${environment.apiUrl}${att.file_name}` // Prepend base URL
          }));
          // console.log(this.attachements);
          // console.log(this.logDetails);

          // // this.userid = this.data && this.data.userid;
          // console.log("this.data-->", this.data);
          // this.getTicketDetails(this.data.userid)
          // this.ticketViewForm.patchValue(this.data);
          var status = "";
          if (this.data.TSTATUSID != "Reopen") {
            status = this.data.TSTATUSID;
          }
          this.ticketViewForm.patchValue({
            id: this.data.id,
            fname: this.data.fname,
            lname: this.data.lname,
            userid: this.data.userid,
            Subject: this.data.Subject,
            Description: this.data.Description,
            CreatedDate: this.data.CreatedDate,
            LASTUpdateDate: this.data.LASTUpdateDate,
            attachments: this.attachements,
            status: status,
            ticket_priority_id: this.data.ticket_priority_id,
            category_name: this.data.category_name,
            staff_assign: this.data.staff_id ? this.data.staff_id : "",
          });

          if (
            this.data.TSTATUSID == "Reject" ||
            this.data.TSTATUSID == "Completed" ||
            this.data.TSTATUSID == "Closed"
          ) {
            this.statusForStaff = true;
            this.ticketViewForm.get("ticket_priority_id").clearValidators();
            this.ticketViewForm.get("staff_assign").clearValidators();
            this.ticketViewForm.get("staff_assign").updateValueAndValidity();
            this.ticketViewForm.get("ticket_priority_id").updateValueAndValidity();
          } else {
            this.statusForStaff = false;
          }
          if (this.data.TSTATUSID == "Pending") {
            this.ticketViewForm.get("staff_assign").clearValidators();
            this.ticketViewForm.get("staff_assign").updateValueAndValidity();
          }
        }
      });
    this.getStaffList();
    this.getPriorityList();
  }
  public getSectorList() {
    this.blockUI.start("Loading. . ."); // Start blocking
    const self = this;
    self.sectorService
      .getSectors()
      .pipe(
        finalize(() => {
          setTimeout(() => {
            self.blockUI.stop(); // Stop blocking
          }, 1000);
        })
      )
      .subscribe((httpresponse) => {
        if (httpresponse.status === 200) {
          this.categories = [];
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < httpresponse.data.length; i++) {
            this.data = httpresponse.data[i];
            this.categories.push({
              SectorID: this.data.SectorID,
              CODE: this.data.CODE,
              DESCRIPTION: this.data.DESCRIPTION,
            });
          }
        }
      });
  }

  markerDragEnd(event: any) {
    console.log(event);
    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;
  }

  public selectSector(event: any) {
    this.productForm.controls.sectors.patchValue(JSON.stringify(event.value));
    if (this.retrieveTowers) {
      if (event.value.length > 0) {
        this.blockUI.start("Loading Towers. . ."); // Start blocking
        const self = this;
        this.selectTowerData = false;
        this.towerList = [];
        self.sectorService
          .getTowersSectorWise(event.value)
          .pipe(
            finalize(() => {
              setTimeout(() => {
                self.blockUI.stop(); // Stop blocking
              }, 1000);
            })
          )
          .subscribe((httpresponse) => {
            if (httpresponse.status === 200) {
              // tslint:disable-next-line: prefer-for-of
              // console.log(httpresponse.data);
              // console.log(httpresponse.data.length);
              // tslint:disable-next-line: prefer-for-of
              for (let i = 0; i < httpresponse.data.length; i++) {
                const element = httpresponse.data[i];
                this.towerList.push(element);
              }
              this.selectTowerData = true;
              // console.log(this.towerList);
            }
          });
      }
    }
  }

  public selectTower(event: any) {
    this.productForm.controls.tower.patchValue(JSON.stringify(event.value));
    if (this.retrieveIndividuals) {
      if (event.value.length > 0) {
        this.blockUI.start("Loading Individuals/Flat Data. . ."); // Start blocking
        const self = this;
        this.selectIndividualData = false;
        this.individualList = [];
        self.sectorService
          .getIndividualTowerWise(event.value)
          .pipe(
            finalize(() => {
              setTimeout(() => {
                self.blockUI.stop(); // Stop blocking
              }, 1000);
            })
          )
          .subscribe((httpresponse) => {
            if (httpresponse.status === 200) {
              // console.log(httpresponse.data);
              // console.log(httpresponse.data.length);
              // tslint:disable-next-line: prefer-for-of
              for (let i = 0; i < httpresponse.data.length; i++) {
                const element = httpresponse.data[i];
                this.individualList.push(element);
              }
              this.selectIndividualData = true;
              // console.log(this.individualList);
            }
          });
      }
    }
  }

  public getTowerListFromArr() {
    return this.towerList;
  }

  onSubmit(): void {
    // debugger;
    console.log(this.ticketViewForm.value);
    // this.blockUI.start('Saving...'); // Start blocking

    if (this.ticketViewForm.valid) {
      let payload = {
        modelName: "tickets",
        id: this.id,
        inputData: {
          TSTATUSID: this.ticketViewForm.get("status").value
            ? this.ticketViewForm.get("status").value
            : "",
          staff_id: this.ticketViewForm.get("staff_assign").value
            ? this.ticketViewForm.get("staff_assign").value
            : null,
            ticket_priority_id: this.ticketViewForm.get("ticket_priority_id").value
            ? this.ticketViewForm.get("ticket_priority_id").value
            : "",
        },
        relation: [
          {
            subModelName: "tickets_logs",
            subModelForeignKey: "ticket_id",
            subModelInputData: [
              {
                comment: this.ticketViewForm.get("comment").value
                  ? this.ticketViewForm.get("comment").value
                  : "",
                type: 2, // 2 for admin  & staff, 1  for citizen
                common_id: this.userid, // app users id for type = 1 else users id means staffs or admins id
               
                status: this.ticketViewForm.get("status").value
                  ? this.ticketViewForm.get("status").value
                  : "",
                is_private: this.ticketViewForm.get("comment_type").value
                  ? this.ticketViewForm.get("comment_type").value
                  : "",
                ticket_id: this.id,
              },
            ],
          },
        ],
      };
      // console.log(JSON.stringify(payload, null, 2));
      // return;
      this.masterservice.postFormData(payload).subscribe((response: any) => {
        if (response.code == 0 || response.status == 500) {
          console.log("error");
          this.toastr.error(response.message);
        } else {
          console.log("Ticket Updated Successfully");
          this.toastr.success("Ticket Updated Successfully");
          if (this.userType === 1) {
            this.router.navigate(["/tickets/list"]);
          } else {
            if(this.type){
              this.router.navigate(["/dashboard/staffWise", this.type])
            }else{
              this.router.navigate(["/tickets/list"]);
            }
          }
        }
      });
      // const self = this;
      // self.ticketService
      //   .updateEvent(payload)
      //   .pipe(
      //     finalize(() => {
      //       setTimeout(() => {
      //         self.blockUI.stop(); // Stop blocking
      //       }, 1000);
      //     })
      //   )
      //   .subscribe((httpresponse) => {
      //     if (httpresponse.status === 200) {
      //       this.router.navigate(["/tickets/list"]);
      //       this.toastr.success("Ticket Updated Successfully!");
      //     } else {
      //       this.toastr.warning(httpresponse.message);
      //     }
      //   });
    } else {
      this.ticketViewForm.markAllAsTouched();
    }
  }

  public validateForm() {
    return true;
  }

  public imagesReceived(data: any) {
    this.productForm.controls.attachment.patchValue(data.imagesData);
  }

  public getFolderName() {
    // console.log(this.folderNameTimestamp);
    return this.folderNameTimestamp;
  }

  public getFileType() {
    return "Images";
  }

  public onItemChange(item: any) {
    switch (item.value) {
      case "1":
        this.showSector = false;
        this.showTower = false;
        this.showIndividual = false;
        this.retrieveTowers = false;
        this.retrieveIndividuals = false;
        break;

      case "2":
        this.getSectorList();
        this.showSector = true;
        this.showTower = false;
        this.showIndividual = false;
        this.retrieveTowers = false;
        this.retrieveIndividuals = false;
        break;

      case "3":
        this.getSectorList();
        this.showSector = true;
        this.showTower = true;
        this.retrieveTowers = true;
        this.showIndividual = false;
        this.retrieveIndividuals = false;
        break;

      case "4":
        this.getSectorList();
        this.showSector = true;
        this.showTower = true;
        this.showIndividual = true;
        this.retrieveTowers = true;
        this.retrieveIndividuals = true;
        break;

      default:
        break;
    }
  }
  getstatus(id: any) {
    return "New";
    switch (id) {
      case 1:
        return "New";
      case 2:
        return "In Progress";
      case 3:
        return "Closed";
      case 4:
        return "Resolved";
      case 78:
        return "In Progress";
      case 1005:
        return "In Progress";
      case 1006:
        return "Pending with Customer";
      case 1007:
        return "In Progress";
      case 1008:
        return "Reopen";
      case 1009:
        return "Rejected";

      case 1010:
        return "In Progress";

      case 1011:
        return "Pending for Painting";

      default:
        return "-";
    }
  }

  formatDate(dateString: string): Date {
    // Assuming dateString is in the format 'YYYY-MM-DD HH:mm:ss'
    // You might need to adjust this parsing logic based on the actual format
    const [datePart, timePart] = dateString.split(" ");
    const [year, month, day] = datePart.split("-");
    const [hour, minute, second] = timePart.split(":");
    return new Date(
      Number(year),
      Number(month) - 1,
      Number(day),
      Number(hour),
      Number(minute),
      Number(second)
    );
  }

  getStaffList() {
    const postObj = {
      ticket_id: this.id,
    };
    this.masterservice.getStaffList(postObj).subscribe((response: any) => {
      if (!response) {
        console.log("Error");
      } else {
        // console.log(response.data);
        // this.staffList = response.data.filter((staff: any) => staff.admin_type !== 1 && staff.admin_type !== null);
        this.staffList = response.data;
      }
    });
  }
  isCommentTypeDisabled(): boolean {
    const statusControl = this.ticketViewForm.get("status");
    const commentTypeControl = this.ticketViewForm.get("comment_type");

    if (statusControl && commentTypeControl) {
      const status = statusControl.value;
      if (
        status === "Closed" ||
        status === "Completed" ||
        status === "Reject"
      ) {
        // Set comment type to 'Public' and disable it
        commentTypeControl.setValue("0");
        return true;
      } else {
        // commentTypeControl.setValue('');
        return;
      }
    }
    return false;
  }
  // getTicketDetails(id:any){
  //   const postObj={
  //     "modelName": "tickets",
  //     "inputData": {
  //       "app_user_id": id,
  //     }
  //   }
  //   this.masterservice.getMasterData(postObj).subscribe((response:any)=>{
  //     if(!response){
  //       console.log("Error");
  //     }else{
  //       console.log("response",response)
  //     }
  //   })
  // }

  onStatusChange(event: any) {
    // debugger;
    // You can set your flags based on the selected value or any condition you want
    if (event.target.value == "Pending") {
      this.ticketViewForm.get("staff_assign").clearValidators();
      this.ticketViewForm.get("staff_assign").updateValueAndValidity();
    } else {
      this.ticketViewForm
        .get("staff_assign")
        .setValidators([Validators.required]);
      this.ticketViewForm.get("staff_assign").updateValueAndValidity();
    }
    if (
      event.target.value == "Reject" ||
      event.target.value == "Completed" ||
      event.target.value == "Closed"
    ) {
      this.ticketViewForm.get("staff_assign").clearValidators();
      this.ticketViewForm.get("staff_assign").updateValueAndValidity();
      this.ticketViewForm.get("ticket_priority_id").clearValidators();
      this.ticketViewForm.get("ticket_priority_id").updateValueAndValidity();
      this.statusForStaff = true;
    } else {
      this.ticketViewForm.get("staff_assign").setValidators([Validators.required]);
      this.ticketViewForm.get("staff_assign").updateValueAndValidity();
      this.ticketViewForm.get("ticket_priority_id").setValidators([Validators.required]);
      this.ticketViewForm.get("ticket_priority_id").updateValueAndValidity();
      this.statusForStaff = false;
    }
    console.log(event.target.value);
    console.log(this.statusForStaff);
    
  }

  onCancel() {
    if (this.type == null) {
      this.router.navigate(["/tickets/list"]);
    } else {
      this.router.navigate(["/dashboard/staffWise", this.type]);
    }
  }
  getPriorityList(){
    const postObj={
      "module": "ticket_priorities",
      "condition": {
          "is_active": 1
      }
    }
    this.masterservice.getMasterData(postObj).subscribe((response:any)=>{
      if(response.code == 0 || response.status == 500){
        console.log("Error", response.message);
      }
      else{
        this.priorityList=response.data;
      }
    })
  }
  getPriorityName(priorityId: number | null): string {
    if (priorityId === null) {
      return '-';
    }
    if (!this.priorityList || this.priorityList.length === 0) {
      return 'Loading...'; // Placeholder text while the priority list is being fetched
    }
    const priority = this.priorityList.find(p => p.id === priorityId);
    return priority ? priority.name : '-';
  }
}
