import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MastersService } from 'src/app/services/masters.service';
@Component({
  selector: 'app-flat-master',
  templateUrl: './flat-master.component.html',
  styleUrls: ['./flat-master.component.scss']
})
export class FlatMasterComponent implements OnInit {
  @BlockUI() blockUI: NgBlockUI;
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;

  numberOnly(e: any) {
    // Accept only numbers
    const regex = new RegExp('^[0-9]+$');
    const str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
  
    // Check if the input is empty and the character is a space, prevent it
    if (e.target.value.trim() === '' && str === ' ') {
      e.preventDefault();
      return false;
    }
  
    if (regex.test(str)) {
      return true;
    }
  
    e.preventDefault();
    return false;
  }

  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  flatForm: any;
  proList: any;
  wingList: any;
  floorList:any;
  isUpdate: boolean = false;
  // categories: any;
  constructor(
    // private notificationsService: NotificationsService,
    private modalService: NgbModal,
    // private loginService: LoginService,
    private formBuilder: FormBuilder,
    private masterservice: MastersService,
    private toastr: ToastrService
    ) {
      // this.categories = categoryDB.category;
      this.flatForm = this.formBuilder.group({
        id:'',
        project_name : ['', [Validators.required]],
        wings_name : ['', [Validators.required]],
        floors_name : ['', [Validators.required]],
        flat_name : ['', [Validators.required]],
      }
  );
  }
  public closeResult: string;
  public sectorData: any;
  matchUserDetails: any;
  userData: any;
  data: any;
  singleEvent: any;
  deleteId: any;
  dataPresent = false;
  datatableDataPresent = true;
  isSubmitting: boolean = false;

  ngOnInit() {
    this.dtOptions = {
      paging: true,
      retrieve: true,
      searching: true,
      responsive: true,
      pageLength: 5,
      lengthMenu: [5, 10, 25, 50, 75, 100],
      language: {
        search: '_INPUT_',
        searchPlaceholder: 'Search in table'
      },
      order: [[
        0, 'asc'
      ]]
    };
    // this.getSectorsList();
    this.getAllProjects();
    this.getFlatList();
  }

  getFlatList(){
    this.blockUI.start('Retrieving Flat List...'); // Start blocking
    const postObj= 
    {
        "module": "flat_masters",
        "relation": [
            {
                "module": "floor_masters"
            },
            {
                "module": "wings_masters"
            },
            {
                "module": "project_masters"
            }
        ]
    }
    this.masterservice.getMasterData(postObj)
      .pipe(finalize(() => { this.blockUI.stop(); }))
      .subscribe((response:any) => {
        if (response) {
          this.data = response.data;
          // this.toastr.success('Flats Data Loaded.');
          if (this.dtElement && this.dtElement.dtInstance) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
              dtInstance.destroy();
              this.dtTrigger.next();
            });
          } else {
            this.dtTrigger.next();
          }
        } else {
          console.log("No response");
        }
      })
  }

  getAllProjects(){
    const postobj={
      module:"project_masters"
    }
    this.masterservice.getMasterData(postobj).subscribe((response:any)=>{
      if(!response){
        console.log("No Project Found");
      }else{
        // console.log("Project List");
        this.proList = response.data;
        // console.log(this.proList);
      }
    })
  }
  onProjectSelect(selectedProjectId: any) {
    // this.selectedProjectId = selectedProjectId;
    this.getAllWingsForPro(selectedProjectId);
    this.flatForm.patchValue({ // Reset specific fields
      wings_name: '', 
      floors_name: ''
    });
  }
  getAllWingsForPro(proId:any){
    const postObj={
      "module": "wings_masters",
      "condition": {
          "project_id": proId
      }
    }
    this.masterservice.getMasterData(postObj).subscribe((response:any)=>{
      if(!response){
        console.log("No Data Found");
      }else{
        this.wingList = response.data;
        // console.log(this.wingList);
      }
    })
  }
  onWingsSelect(selectedWingId: any) {
    // this.selectedProjectId = selectedProjectId;
    this.getAllFloorList(selectedWingId);
  }
  getAllFloorList(wingId:any){
    const postObj={
      "module": "floor_masters",
      "condition": {
          "wing_id": wingId
      }
    }
    this.masterservice.getMasterData(postObj).subscribe((response:any)=>{
      if(!response){
        console.log("No data found");
      }else{
        this.floorList = response.data;
        // console.log(this.floorList);
      }
    })
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'xl' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
    private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.resetForm();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.resetForm();
      return 'by clicking on a backdrop';
    } else {
      this.resetForm();
      return `with: ${reason}`;
    }
  }
  submitForm(){
    this.isSubmitting = true; // Add this line

    if(this.flatForm.valid){
      const postObj={
        "modelName": "flat_masters",
        "inputData": {
            "project_id": this.flatForm.value.project_name,
            "wing_id": this.flatForm.value.wings_name,
            "floor_id": this.flatForm.value.floors_name,
            "name": this.flatForm.value.flat_name,
            "is_active": 1
        }
    }
    // console.log(postObj);
    // return;
    this.masterservice.postFormData(postObj).subscribe((response:any)=>{
      if(response.code == 0 || response.status == 500){
        // console.log("No Response", response.message.errors[0].message);
        this.toastr.error(response.message);
        this.isSubmitting = false; // Add this line

      }else{
        console.log("Record Created Succesfully");
        this.toastr.success("Record Created Succesfully");
        this.modalService.dismissAll();
        this.getFlatList();
        this.resetForm();
        this.isSubmitting = false; // Add this line

      }
    })
    }else{
      this.flatForm.markAllAsTouched();
      this.isSubmitting = false; // Add this line

    }
  }
  updateForm(){
    if(this.flatForm.valid){
      const postObj={
        "modelName": "flat_masters",
        "id": this.flatForm.value.id,
        "inputData": {
            "project_id": this.flatForm.value.project_name,
            "wing_id": this.flatForm.value.wings_name,
            "floor_id": this.flatForm.value.floors_name,
            "name": this.flatForm.value.flat_name,
            "is_active": 1
        }
    }
    // console.log(postObj);
    // return;
    this.masterservice.postFormData(postObj).subscribe((response:any)=>{
      if(response.code == 0 || response.status == 500){
        // console.log("No Response", response.message.errors[0].message);
        this.toastr.error(response.message);
        this.isSubmitting = false; // Add this line

      }else{
        console.log("Record Updated Succesfully");
        this.toastr.success("Record Updated Succesfully");
        this.modalService.dismissAll();
        this.getFlatList();
        this.resetForm();
        this.isUpdate = false;
        this.isSubmitting = false; // Add this line

      }
    })
    }else{
      this.flatForm.markAllAsTouched();
      this.isSubmitting = false; // Add this line

    }
  }

  viewUserDetails(data:any){
    // console.log(data);
    this.isUpdate = true;
    this.getAllWingsForPro(data.project_masters.id);
    this.getAllFloorList(data.wings_masters.id);
    this.flatForm.patchValue({
      id: data.id,
      project_name: data.project_masters.id,
      wings_name:data.wings_masters.id,
      floors_name: data.floor_masters.id,
      flat_name: data.name
      });
      // Additional logic if needed
  } 

  resetForm(){
    this.flatForm.reset();
    this.flatForm.get('project_name').setValue('');
    this.flatForm.get('wings_name').setValue('');
    this.flatForm.get('floors_name').setValue('');
    this.modalService.dismissAll();
    this.isUpdate = false;
    this.wingList = '';
    this.floorList = '';
  }
}
