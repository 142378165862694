import { Routes } from '@angular/router';
import { AuthGuard } from 'src/app/core/auth.guard';

export const content: Routes = [
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/dashboard/dashboard.module').then(m => m.DashboardModule),
  },
  {
    path: 'masters',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/masters/masters.module').then(m => m.MastersModule),
    data: {
      breadcrumb: 'Masters'
    }
  },
  {
    path: 'events',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/events/events.module').then(m => m.EventsModule),
    data: {
      breadcrumb: 'Events'
    }
  },
  {
    path: 'smartpay',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/smartpay/smartpay.module').then(m => m.SmartPayModule),
    data: {
      breadcrumb: 'Smart Pay'
    }
  },
  {
    path: 'broadcast',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/broadcast/broadcast.module').then(m => m.BroadcastModule),
    data: {
      breadcrumb: 'Broadcast'
    }
  },
  {
    path: 'adminlogin',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/adminmngt/admin.module').then(m => m.AdminAppLoginModule),
    data: {
      breadcrumb: 'Admin Management'
    }
  },
  {
    path: 'password',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/changepass/pass.module').then(m => m.ChangePassModule),
    data: {
      breadcrumb: 'Password'
    }
  },
  {
    path: 'comments',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/comments/broadcast.module').then(m => m.CommentsModule),
    data: {
      breadcrumb: 'Comments'
    }
  },
  {
    path: 'enquiries',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/enquiries/enquiries.module').then(m => m.EnquiriesModule),
    data: {
      breadcrumb: 'Enquiries'
    }
  },
  {
    path: 'bannerimages',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/bannerimages/bannerimages.module').then(m => m.BannerImagesModule),
    data: {
      breadcrumb: 'Banner Images'
    }
  },
  {
    path: 'notifications',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/notifications/notifications.module').then(m => m.NotificationsModule),
    data: {
      breadcrumb: 'Notifications'
    }
  },
  {
    path: 'locations',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/locations/locations.module').then(m => m.LocationsModule),
    data: {
      breadcrumb: 'Locations'
    }
  },
  // {
  //   path: 'fern',
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import('../../components/amanorafern/fern.module').then(m => m.FernModule),
  //   data: {
  //     breadcrumb: 'Mangalam Fern'
  //   }
  // },
  {
    path: 'appusers',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/app_users/user.module').then(m => m.AppUserModule),
    data: {
      breadcrumb: 'App Users'
    }
  },
  // {
  //   path: 'yessfoundation',
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import('../../components/yessfoundation/yessevents.module').then(m => m.YessEventsModule),
  //   data: {
  //     breadcrumb: 'Yess Foundation'
  //   }
  // },
  {
    path: 'videos',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/videos/videos.module').then(m => m.VideosModule),
    data: {
      breadcrumb: 'Videos'
    }
  },
  {
    path: 'images',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/images/images.module').then(m => m.ImagesModule),
    data: {
      breadcrumb: 'Images'
    }
  },
  // {
  //   path: 'sales',
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import('../../components/sales/sales.module').then(m => m.SalesModule),
  //   data: {
  //     breadcrumb: 'Sales'
  //   }
  // },
  // {
  //   path: 'coupons',
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import('../../components/coupons/coupons.module').then(m => m.CouponsModule),
  //   data: {
  //     breadcrumb: 'Coupons'
  //   }
  // },
  // {
  //   path: 'pages',
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import('../../components/pages/pages.module').then(m => m.PagesModule),
  //   data: {
  //     breadcrumb: 'Pages'
  //   }
  // },
  // {
  //   path: 'media',
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import('../../components/media/media.module').then(m => m.MediaModule),
  // },
  // {
  //   path: 'menus',
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import('../../components/menus/menus.module').then(m => m.MenusModule),
  //   data: {
  //     breadcrumb: 'Menus'
  //   }
  // },
  {
    path: 'user',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/user/user.module').then(m => m.UserModule),
    data: {
      breadcrumb: 'Staff'
    }
  },
  {
    path: 'users',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/users/users.module').then(m => m.UsersModule),
    data: {
      breadcrumb: 'Users'
    }
  },
  {
    path: 'config',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/config/dashboard.module').then(m => m.ConfigModule),
    data: {
      breadcrumb: 'Config'
    }
  },
  {
    path: 'tickets',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/tickets/tickets.module').then(m => m.TicketsModule),
    data: {
      breadcrumb: 'Events'
    }
  },
  {
    path: 'd-reports',
    canActivate: [AuthGuard],
    loadChildren: () => import('../../components/d-reports/d-reports.module').then(m => m.DReportsModule),
    data: {
      breadcrumb: 'Events'
    }
  },
  // {
  //   path: 'vendors',
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import('../../components/vendors/vendors.module').then(m => m.VendorsModule),
  //   data: {
  //     breadcrumb: 'Vendors'
  //   }
  // },
  // {
  //   path: 'localization',
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import('../../components/localization/localization.module').then(m => m.LocalizationModule),
  //   data: {
  //     breadcrumb: 'Localization'
  //   }
  // },
  // {
  //   path: 'reports',
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import('../../components/reports/reports.module').then(m => m.ReportsModule),
  // },
  // {
  //   path: 'settings',
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import('../../components/setting/setting.module').then(m => m.SettingModule),
  //   data: {
  //     breadcrumb: 'Settings'
  //   }
  // },
  // {
  //   path: 'invoice',
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import('../../components/invoice/invoice.module').then(m => m.InvoiceModule),
  //   data: {
  //     breadcrumb: 'Invoice'
  //   }
  // },
  // {
  //   path: 'maintenance',
  //   canActivate: [AuthGuard],
  //   loadChildren: () => import('../../components/maintenance/maintenance.module').then(m => m.MaintenanceModule),
  //   data: {
  //     breadcrumb: 'Invoice'
  //   }
  // },
];
