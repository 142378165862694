import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { finalize } from 'rxjs/operators';
import { EventService } from 'src/app/services/event.service';
import { SectorService } from 'src/app/services/sector.service';
@Component({
  selector: 'app-add-banner-image',
  templateUrl: './add-banner-image.component.html',
  styleUrls: ['./add-banner-image.component.scss'],
  providers: [SectorService, EventService]
})
export class AddBannerImageComponent implements OnInit {

  public title = 'AGM project';
  public latitude: number;
  public longitude: number;
  public zoom: number;

  @BlockUI() blockUI: NgBlockUI;
  public productForm: FormGroup;
  public counter = 1;
  public categories = [];
  public products = [];
  private tagsPresent = false;
  private prodData: any;
  private tempDataForLoading: any;
  private selectedTagArray: any;
  retrievedTags: any;
  private tagList: any;
  data: any;
  public url = [
    {
      img: 'assets/images/user.png'
    },
    {
      img: 'assets/images/user.png'
    },
    {
      img: 'assets/images/user.png'
    },
    {
      img: 'assets/images/user.png'
    },
    {
      img: 'assets/images/user.png'
    }
  ];

  public tagConfig = {
    displayKey: 'tag',
    search: true,
    height: 'auto',
    placeholder: 'Select Tags',
    noResultsFound: 'No results found!',
    searchPlaceholder: 'Enter Tag Name',
    searchOnKey: 'tag'
  };


  constructor(
    private fb: FormBuilder,
    private eventService: EventService,
    private sectorService: SectorService,
    private router: Router
  ) {
    this.productForm = this.fb.group({
      sector_id: ['', [Validators.required]],
      event_name: ['', [Validators.required]],
      event_description: ['', [Validators.required]],
      attachment_type: ['0', Validators.required],
      attachment: ['0', Validators.required],
      location: ['', Validators.required],
      date: ['', Validators.required],
      time: ['', Validators.required]
    });
  }

  increment() {
    this.counter += 1;
  }

  decrement() {
    this.counter -= 1;
  }

  // FileUpload
  readUrl(event: any, i) {
    if (event.target.files.length === 0) {
      return;
    }
    // Image upload validation
    const mimeType = event.target.files[0].type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    // Image upload
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = _event => {
      this.url[i].img = reader.result.toString();
    };
  }

  ngOnInit() {
    // this.getAll();
    this.getSectorList();
    this.setCurrentLocation();
  }
   // Get Current Location Coordinates
   private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 15;
      });
    }
  }
  public getSectorList() {
    this.blockUI.start('Loading. . .'); // Start blocking
    const self = this;
    self.sectorService
      .getSectors()
      .pipe(
        finalize(() => {
          setTimeout(() => {
            self.blockUI.stop(); // Stop blocking
          }, 1000);
        })
      )
      .subscribe(httpresponse => {
        if (httpresponse.status === 200) {
          this.categories = [];
          // tslint:disable-next-line: prefer-for-of
          for (let i = 0; i < httpresponse.data.length; i++) {
            this.data = httpresponse.data[i];
            this.categories.push({
              SectorID: this.data.SectorID,
              CODE: this.data.CODE,
              DESCRIPTION: this.data.DESCRIPTION
            });
          }
        }
      });
  }

  onSubmit(): void {
    // console.log(this.productForm.value);
    this.blockUI.start('Saving...'); // Start blocking
    if (this.validateForm()) {
      const self = this;
      self.eventService
        .saveEvent(this.productForm.value)
        .pipe(
          finalize(() => {
            setTimeout(() => {
              self.blockUI.stop(); // Stop blocking
            }, 1000);
          })
        )
        .subscribe(httpresponse => {
          if (httpresponse.status === 200) {
            console.log('Event Added Successfully!');
            alert('Event Added Successfully!');
            this.router.navigate(['/products/physical/product-list']);
          } else {
            alert('Error : ' + httpresponse.message);
            console.log('Error');
          }
        });
    }
  }

  public validateForm() {
    return true;
  }

  public imagesReceived(data: any) {
    // this.productForm.controls.pictures.patchValue(data);
  }

  public getImageFolderName() {
    return 'videos';
  }

  public getImageFileType() {
    return 'Videos';
  }


}
