import { Injectable } from '@angular/core';
import { HttpClientGlobal } from '../core/httpclient';
import { Observable } from 'rxjs';
import { HttpResponse } from '../core/http.model';

@Injectable()
export class NotificationsService {
  constructor(private httpClient: HttpClientGlobal) {}

  getNotifications(): Observable<HttpResponse> {
    return this.httpClient.get('/api/notificationlist/admin', {});
  }

  fetchDetailedNotification(id: any): Observable<HttpResponse> {
    return this.httpClient.post('/api/notificationlist/sentdetails', {id});
  }

  fetchUserDetails(id: any): Observable<HttpResponse> {
    return this.httpClient.post('/api/notification/userdetails', {id});
  }

  fetchUserDetailsUSERID(id: any): Observable<HttpResponse> {
    return this.httpClient.post('/api/notification/userdetailsuserid', {id});
  }


}
