import { AgmCoreModule } from '@agm/core';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GalleryModule } from '@ks89/angular-modal-gallery';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { UiSwitchModule } from 'ngx-toggle-switch';
import 'hammerjs';
import 'mousetrap';
import { BlockUIModule } from 'ng-block-ui';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { CKEditorModule } from 'ngx-ckeditor';
import { DropzoneConfigInterface, DropzoneModule, DROPZONE_CONFIG } from 'ngx-dropzone-wrapper';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { ImageUploadModule } from 'src/app/shared/imageupload/imageupload.module';
import { OwlNativeDateTimeModule, OwlDateTimeModule } from 'ng-pick-datetime';
import { MomentModule } from 'angular2-moment';
import { DataTablesModule } from 'angular-datatables';
import { TicketsRoutingModule } from './tickets-routing.module';
import { TicketlistComponent } from './physical/ticket-list/ticketlist.component';
import { ViewTicketComponent } from './physical/view-ticket/view-ticket.component';
// import { StaffWiseTicketComponent } from './physical/staff-wise-ticket/staff-wise-ticket.component';

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  maxFilesize: 50,
  url: 'https://httpbin.org/post',
};

@NgModule({
  declarations: [TicketlistComponent, ViewTicketComponent],
  imports: [
    CommonModule,
    TicketsRoutingModule,
    SelectDropDownModule,
    OwlNativeDateTimeModule,
    OwlDateTimeModule,
    UiSwitchModule,
    MomentModule,
    BlockUIModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule,
    CKEditorModule,
    DataTablesModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyDItmJWhhZqoach-yecRWPW-8iAgZDO1OI',
      libraries: ['places']
    }),
    Ng2SmartTableModule,
    NgbModule,
    DropzoneModule,
    GalleryModule.forRoot(),
    ImageUploadModule
  ],
  providers: [
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG
    },
    NgbActiveModal
  ]
})
export class TicketsModule { }
