import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ReportsComponent } from './physical/reports/reports.component';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'report_master',
        component: ReportsComponent,
        data: {
          title: 'Ticket Report Master',
          breadcrumb: 'Ticket Report Master'
        }
      },
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DReportsRoutingModule { }
